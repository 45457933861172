import { type RISERAPI } from '~/data/api'
import { LikeObjectType } from '~/types/like'

type InteractionStore = ReturnType<typeof useInteractionStore>

export class LikeService {
  API: RISERAPI
  interactionStore: InteractionStore

  constructor(API: RISERAPI, interactionStore: InteractionStore) {
    this.API = API
    this.interactionStore = interactionStore
  }

  async getLikes(modelType: LikeObjectType, objectId: number, limit?: number, after?: string) {
    const response = await this.API.getLikes(modelType, objectId, limit, after)

    return response.data
  }

  async likeTrip(tripId: number) {
    const response = await this.API.createLike(`/trips/${tripId}/likes`)

    if (response) {
      this.interactionStore.likeItem('trips', tripId)
    }
  }

  async unlikeTrip(tripId: number) {
    const deleteResponse = await this.API.deleteLike(`trips/${tripId}/likes`)

    if (!deleteResponse) return

    this.interactionStore.unlikeItem('trips', tripId)
  }

  async likeTripPhoto(photoId: number) {
    const response = await this.API.createLike(`photos/${photoId}/likes`)

    if (response) {
      this.interactionStore.likeItem('tripPhotos', photoId)
    }
  }

  async unlikeTripPhoto(photoId: number) {
    const deleteResponse = await this.API.deleteLike(`photos/${photoId}/likes`)

    if (!deleteResponse) return

    this.interactionStore.unlikeItem('tripPhotos', photoId)
  }

  async likeSection(sectionId: number) {
    const response = await this.API.createLike(`/sections/${sectionId}/likes`)

    if (response) {
      this.interactionStore.likeItem('sections', sectionId)
    }
  }

  async unlikeSection(sectionId: number) {
    const deleteResponse = await this.API.deleteLike(`sections/${sectionId}/likes`)

    if (!deleteResponse) return

    this.interactionStore.unlikeItem('sections', sectionId)
  }

  async likePosting(postingId: number) {
    const response = await this.API.createLike(`/postings/${postingId}/likes`)

    if (response) {
      this.interactionStore.likeItem('postings', postingId)
    }
  }

  async unlikePosting(postingId: number) {
    const deleteResponse = await this.API.deleteLike(`postings/${postingId}/likes`)

    if (!deleteResponse) return

    this.interactionStore.unlikeItem('postings', postingId)
  }

  async likeGroupPosting(groupId: number, postingId: number) {
    const response = await this.API.likeGroupPosting(groupId, postingId)

    if (response) {
      this.interactionStore.likeItem('groupPostings', postingId)
    }

    return response.data
  }

  async unlikeGroupPosting(groupId: number, postingId: number) {
    const deleteResponse = await this.API.unlikeGroupPosting(groupId, postingId)

    if (!deleteResponse) return

    this.interactionStore.unlikeItem('groupPostings', postingId)
  }

  async likeBike(bikeId: number) {
    const response = await this.API.likeBike(bikeId)

    if (response) {
      this.interactionStore.likeItem('bikes', bikeId)
    }
  }

  async unlikeBike(bikeId: number) {
    const deleteResponse = await this.API.unlikeBike(bikeId)

    if (!deleteResponse) return

    this.interactionStore.unlikeItem('bikes', bikeId)
  }

  async likeHelmet(helmetId: number) {
    const response = await this.API.likeHelmet(helmetId)

    if (response) {
      this.interactionStore.likeItem('helmets', helmetId)
    }
  }

  async unlikeHelmet(helmetId: number) {
    const deleteResponse = await this.API.unlikeHelmet(helmetId)

    if (!deleteResponse) return

    this.interactionStore.unlikeItem('helmets', helmetId)
  }

  async likeIntercom(intercomId: number) {
    const response = await this.API.likeIntercom(intercomId)

    if (response) {
      this.interactionStore.likeItem('intercoms', intercomId)
    }
  }

  async unlikeIntercom(intercomId: number) {
    const deleteResponse = await this.API.unlikeIntercom(intercomId)

    if (!deleteResponse) return

    this.interactionStore.unlikeItem('intercoms', intercomId)
  }
}
