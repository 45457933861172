<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const authStore = useAuthStore()

const { t } = useI18n()

const state = reactive({
  items: [
    {
      label: t('Create posting'),
      icon: 'fa-newspaper',
      to: '/feed/postings/new'
    },
    {
      label: t('Add Bike'),
      icon: 'fa-motorcycle',
      to: `/user/${authStore.currentUser?.id}/garage/bikes/new`
    },
    {
      label: t('Add helmet'),
      icon: 'fa-helmet-safety',
      to: `/user/${authStore.currentUser?.id}/garage/helmets/new`
    },
    {
      label: t('Add intercom'),
      icon: 'fa-headset',
      to: `/user/${authStore.currentUser?.id}/garage/intercoms/new`
    },
    {
      label: t('Create-group'),
      icon: 'fa-users',
      to: `/groups/new`
    },
    {
      label: t('Create-getaway'),
      icon: 'fa-calendar fa-regular',
      to: `/user/${authStore.currentUser?.id}/getaways/new`
    }
  ]
})
</script>

<template>
  <NavigationTopBarItemBase mouse-over class="tw-group">
    <template #button>
      <div
        class="tw-relative tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-rounded-full tw-border-[1.5px] tw-border-gray-700 tw-text-sm tw-text-gray-700 group-hover:tw-bg-gray-700 group-hover:tw-text-white"
      >
        <FontAwesomeIcon icon="plus" />
      </div>
    </template>

    <template #default="{ triggerClose }">
      <div class="tw-w-full tw-overflow-y-auto md:tw-w-max">
        <NuxtLink
          v-for="item of state.items"
          :key="item.label"
          :to="item.to"
          class="tw-flex tw-items-center tw-border-b tw-border-black/10 tw-bg-white tw-px-6 tw-py-3 tw-text-sm tw-transition-all tw-duration-200 last:tw-border-b-0 hover:tw-bg-gray-100"
          @click="triggerClose"
        >
          <FontAwesomeIcon :icon="item.icon" class="tw-h-4 tw-w-4 tw-text-gray-800" />

          <div class="tw-ml-3 tw-text-sm">{{ item.label }}</div>
        </NuxtLink>
      </div>
    </template>
  </NavigationTopBarItemBase>
</template>
