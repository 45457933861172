<script setup lang="ts">
defineOptions({
  inheritAttrs: false
})

const props = defineProps<{
  loading?: boolean
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
}>()

const attrs = useAttrs()

const emits = defineEmits(['click'])

const clickHandler = () => {
  if (props.loading || props.disabled) return

  emits('click')
}
</script>

<template>
  <button
    class="normalize-style tw-relative"
    :type="props.type ?? 'button'"
    :disabled="props.disabled"
    :class="[
      !props.loading && !props.disabled ? 'tw-cursor-pointer' : 'tw-cursor-default',
      props.loading ? 'tw-opacity-50' : '',
      props.disabled ? 'tw-opacity-70' : ''
    ]"
    v-bind="attrs"
    @click="clickHandler"
  >
    <div
      v-if="props.loading"
      class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center"
    >
      <LoadingIndicator />
    </div>
    <slot></slot>
  </button>
</template>
