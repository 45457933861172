import { default as editYzWyXU6SilMeta } from "/opt/build/repo/pages/bikes/[id]/index/edit.vue?macro=true";
import { default as serviceAE4Lrc8FZiMeta } from "/opt/build/repo/pages/bikes/[id]/index/service.vue?macro=true";
import { default as index7QKqjqlsf4Meta } from "/opt/build/repo/pages/bikes/[id]/index.vue?macro=true";
import { default as _104RwOyAblrRzMeta } from "/opt/build/repo/pages/challenge/104.vue?macro=true";
import { default as _91id_93cSFNOxQIzZMeta } from "/opt/build/repo/pages/challenge/[id].vue?macro=true";
import { default as indexndEAgIqly3Meta } from "/opt/build/repo/pages/challenge/world-adventure-week-22/index.vue?macro=true";
import { default as rankingswcpYVD363JMeta } from "/opt/build/repo/pages/challenge/world-adventure-week-22/rankings.vue?macro=true";
import { default as indexbRqbiCdtQhMeta } from "/opt/build/repo/pages/challenge/world-adventure-week/index.vue?macro=true";
import { default as rankingsuuonGIJr4uMeta } from "/opt/build/repo/pages/challenge/world-adventure-week/rankings.vue?macro=true";
import { default as xajo_45distance_45challenge_4524g8wRXw7T8wMeta } from "/opt/build/repo/pages/challenge/xajo-distance-challenge-24.vue?macro=true";
import { default as currentuD99nMN2rFMeta } from "/opt/build/repo/pages/challenges/index/current.vue?macro=true";
import { default as pastS6nf9wqxdNMeta } from "/opt/build/repo/pages/challenges/index/past.vue?macro=true";
import { default as upcomingUMrG2UF66vMeta } from "/opt/build/repo/pages/challenges/index/upcoming.vue?macro=true";
import { default as index0iFCVE046QMeta } from "/opt/build/repo/pages/challenges/index.vue?macro=true";
import { default as agbrc6nzkNCG2Meta } from "/opt/build/repo/pages/de/agb.vue?macro=true";
import { default as indexuxnABaB9UFMeta } from "/opt/build/repo/pages/de/index.vue?macro=true";
import { default as ueberS6qXw1RCLwMeta } from "/opt/build/repo/pages/de/ueber.vue?macro=true";
import { default as newT1DRWlVx4UMeta } from "/opt/build/repo/pages/feed/index/postings/new.vue?macro=true";
import { default as indexzzR6L0pfELMeta } from "/opt/build/repo/pages/feed/index.vue?macro=true";
import { default as getStartedxLX80H3YIMMeta } from "/opt/build/repo/pages/getStarted.vue?macro=true";
import { default as comments7iESIYH5ZdMeta } from "/opt/build/repo/pages/getaways/[id]/comments.vue?macro=true";
import { default as editZiQ4oBK84xMeta } from "/opt/build/repo/pages/getaways/[id]/details/edit.vue?macro=true";
import { default as inviteKQHmbkx95iMeta } from "/opt/build/repo/pages/getaways/[id]/details/invite.vue?macro=true";
import { default as detailsWIdgQJuIkRMeta } from "/opt/build/repo/pages/getaways/[id]/details.vue?macro=true";
import { default as indexW4BwTPAbbsMeta } from "/opt/build/repo/pages/getaways/[id]/index.vue?macro=true";
import { default as members1ekJcKwq22Meta } from "/opt/build/repo/pages/getaways/[id]/members.vue?macro=true";
import { default as _91id_93ITUCY9ImyxMeta } from "/opt/build/repo/pages/getaways/[id].vue?macro=true";
import { default as adminQCAuHiecYmMeta } from "/opt/build/repo/pages/groups/[id]/admin.vue?macro=true";
import { default as indexmhvKfa04tAMeta } from "/opt/build/repo/pages/groups/[id]/index.vue?macro=true";
import { default as members8eWFUbI5MSMeta } from "/opt/build/repo/pages/groups/[id]/members.vue?macro=true";
import { default as postsDTbYkt4JU1Meta } from "/opt/build/repo/pages/groups/[id]/posts.vue?macro=true";
import { default as statshnWJ83SVODMeta } from "/opt/build/repo/pages/groups/[id]/stats.vue?macro=true";
import { default as tripsCaSznesO5zMeta } from "/opt/build/repo/pages/groups/[id]/trips.vue?macro=true";
import { default as _91id_93ssrF3Uadu5Meta } from "/opt/build/repo/pages/groups/[id].vue?macro=true";
import { default as discoverBGtgFKEfkaMeta } from "/opt/build/repo/pages/groups/discover.vue?macro=true";
import { default as newHGY0UXdoyrMeta } from "/opt/build/repo/pages/groups/index/new.vue?macro=true";
import { default as indexFCdY35y968Meta } from "/opt/build/repo/pages/groups/index.vue?macro=true";
import { default as editzlomzIqXm2Meta } from "/opt/build/repo/pages/helmets/[id]/index/edit.vue?macro=true";
import { default as indexbyLLsDLm8lMeta } from "/opt/build/repo/pages/helmets/[id]/index.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as editjuCviGZOwWMeta } from "/opt/build/repo/pages/intercoms/[id]/index/edit.vue?macro=true";
import { default as index6p5LCxGCIFMeta } from "/opt/build/repo/pages/intercoms/[id]/index.vue?macro=true";
import { default as logoutlLSqGnk5PzMeta } from "/opt/build/repo/pages/logout.vue?macro=true";
import { default as reportfhAHC85XrDMeta } from "/opt/build/repo/pages/postings/[id]/comments/[comment_id]/report.vue?macro=true";
import { default as report1324Bb4s3cMeta } from "/opt/build/repo/pages/postings/[id]/report.vue?macro=true";
import { default as _91id_93ezXKbiJvkOMeta } from "/opt/build/repo/pages/postings/[id].vue?macro=true";
import { default as _91code_93aDt0nX3N1fMeta } from "/opt/build/repo/pages/pro/[code].vue?macro=true";
import { default as request_password_resetJn7HC5Plx8Meta } from "/opt/build/repo/pages/request_password_reset.vue?macro=true";
import { default as indexsLuGxV2gvJMeta } from "/opt/build/repo/pages/routePlanner/index.vue?macro=true";
import { default as previewrerlEDFyiBMeta } from "/opt/build/repo/pages/routePlanner/preview.vue?macro=true";
import { default as searchi1lVFWwv2QMeta } from "/opt/build/repo/pages/search.vue?macro=true";
import { default as _91id_93cmO0QSOEE8Meta } from "/opt/build/repo/pages/sections/[id].vue?macro=true";
import { default as _91token_93GV4KEEo09kMeta } from "/opt/build/repo/pages/set_password/[token].vue?macro=true";
import { default as settings6ZNFu1CSghMeta } from "/opt/build/repo/pages/settings.vue?macro=true";
import { default as alltimeYQtso45YD7Meta } from "/opt/build/repo/pages/statistics/index/alltime.vue?macro=true";
import { default as month8ol6If5GoaMeta } from "/opt/build/repo/pages/statistics/index/month.vue?macro=true";
import { default as weekQIW4nD5iDCMeta } from "/opt/build/repo/pages/statistics/index/week.vue?macro=true";
import { default as yearNdCkA3apl6Meta } from "/opt/build/repo/pages/statistics/index/year.vue?macro=true";
import { default as indexp3j8RewfHOMeta } from "/opt/build/repo/pages/statistics/index.vue?macro=true";
import { default as index8Zsv18cbZTMeta } from "/opt/build/repo/pages/t/[id]/[...name]/index.vue?macro=true";
import { default as rewindm2QEgnV5mRMeta } from "/opt/build/repo/pages/t/[id]/rewind.vue?macro=true";
import { default as _91id_93svlVKfhEY0Meta } from "/opt/build/repo/pages/t/[id].vue?macro=true";
import { default as terms9FLT7o4CI4Meta } from "/opt/build/repo/pages/terms.vue?macro=true";
import { default as editvoEP4zFoAKMeta } from "/opt/build/repo/pages/trips/[id]/edit.vue?macro=true";
import { default as participantsZrptBkT9MdMeta } from "/opt/build/repo/pages/trips/[id]/participants.vue?macro=true";
import { default as photosJs7fjXZoDyMeta } from "/opt/build/repo/pages/trips/[id]/photos.vue?macro=true";
import { default as shareyH0UiV0xUgMeta } from "/opt/build/repo/pages/trips/[id]/share.vue?macro=true";
import { default as _91id_93pagxHSaKFbMeta } from "/opt/build/repo/pages/trips/[id].vue?macro=true";
import { default as indexDroJuczQCeMeta } from "/opt/build/repo/pages/upgrade/index.vue?macro=true";
import { default as success7jeoXXAMFZMeta } from "/opt/build/repo/pages/upgrade/success.vue?macro=true";
import { default as _91achievementID_935WvCGxxqsNMeta } from "/opt/build/repo/pages/user/[id]/achievements/index/[achievementID].vue?macro=true";
import { default as indexG5wcSIyaFVMeta } from "/opt/build/repo/pages/user/[id]/achievements/index.vue?macro=true";
import { default as blockedVP0DbnYUGAMeta } from "/opt/build/repo/pages/user/[id]/blocked.vue?macro=true";
import { default as follow_45requestsRSed7MayShMeta } from "/opt/build/repo/pages/user/[id]/follow-requests.vue?macro=true";
import { default as followers5KiJkmPMABMeta } from "/opt/build/repo/pages/user/[id]/followers.vue?macro=true";
import { default as following44kGiE8REpMeta } from "/opt/build/repo/pages/user/[id]/following.vue?macro=true";
import { default as newIXh6AsdSsNMeta } from "/opt/build/repo/pages/user/[id]/garage/bikes/index/new.vue?macro=true";
import { default as index95pFcgrhUIMeta } from "/opt/build/repo/pages/user/[id]/garage/bikes/index.vue?macro=true";
import { default as new5rCnFDSVyDMeta } from "/opt/build/repo/pages/user/[id]/garage/helmets/index/new.vue?macro=true";
import { default as indexfc4FNd12UFMeta } from "/opt/build/repo/pages/user/[id]/garage/helmets/index.vue?macro=true";
import { default as indexC0icOB0YZfMeta } from "/opt/build/repo/pages/user/[id]/garage/index.vue?macro=true";
import { default as newxuGCbClrwxMeta } from "/opt/build/repo/pages/user/[id]/garage/intercoms/index/new.vue?macro=true";
import { default as indexzbH6lCOVixMeta } from "/opt/build/repo/pages/user/[id]/garage/intercoms/index.vue?macro=true";
import { default as newftPIYKmvCNMeta } from "/opt/build/repo/pages/user/[id]/getaways/index/new.vue?macro=true";
import { default as index0aGglwb0PxMeta } from "/opt/build/repo/pages/user/[id]/getaways/index.vue?macro=true";
import { default as newrgsDWspsQrMeta } from "/opt/build/repo/pages/user/[id]/groups/index/new.vue?macro=true";
import { default as indexLOHD2UeSzKMeta } from "/opt/build/repo/pages/user/[id]/groups/index.vue?macro=true";
import { default as heatmapnmwnL3ZrdaMeta } from "/opt/build/repo/pages/user/[id]/heatmap.vue?macro=true";
import { default as indexwaLU4zPJr5Meta } from "/opt/build/repo/pages/user/[id]/index.vue?macro=true";
import { default as photosyAC2Jrz0DvMeta } from "/opt/build/repo/pages/user/[id]/photos.vue?macro=true";
import { default as indexindlaMMysqMeta } from "/opt/build/repo/pages/user/[id]/roadbook/index/index.vue?macro=true";
import { default as planneds6wLUNFhWUMeta } from "/opt/build/repo/pages/user/[id]/roadbook/index/planned.vue?macro=true";
import { default as sectionsF7d0SI8NcPMeta } from "/opt/build/repo/pages/user/[id]/roadbook/index/sections.vue?macro=true";
import { default as tripsw3setHonhsMeta } from "/opt/build/repo/pages/user/[id]/roadbook/index/trips.vue?macro=true";
import { default as indexs1M0TjFUnaMeta } from "/opt/build/repo/pages/user/[id]/roadbook/index.vue?macro=true";
import { default as edit5Jx5Nk4nMIMeta } from "/opt/build/repo/pages/user/[id]/timeline/index/edit.vue?macro=true";
import { default as indexri39BdMBDUMeta } from "/opt/build/repo/pages/user/[id]/timeline/index.vue?macro=true";
import { default as _91id_93nZHoHmZP6bMeta } from "/opt/build/repo/pages/user/[id].vue?macro=true";
import { default as indexuu6yzSj8DAMeta } from "/opt/build/repo/pages/user/index.vue?macro=true";
import { default as _91token_937gbqtu2VpJMeta } from "/opt/build/repo/pages/verify_email/[userid]/[token].vue?macro=true";
import { default as _91token_93im7TnYXFa8Meta } from "/opt/build/repo/pages/verify_newsletter/[userid]/[token].vue?macro=true";
import { default as _91code_93XW5ApyZ4YVMeta } from "/opt/build/repo/pages/voucher/[code].vue?macro=true";
import { default as _91id_937AodZTT4jjMeta } from "/opt/build/repo/pages/webviews/groups/[id].vue?macro=true";
import { default as indexJkwXHTyWaIMeta } from "/opt/build/repo/pages/webviews/groups/index.vue?macro=true";
import { default as search9SWcE4NPqUMeta } from "/opt/build/repo/pages/webviews/groups/search.vue?macro=true";
import { default as webviewsG5MhfBRPq6Meta } from "/opt/build/repo/pages/webviews.vue?macro=true";
export default [
  {
    name: index7QKqjqlsf4Meta?.name ?? "bikes-id",
    path: index7QKqjqlsf4Meta?.path ?? "/bikes/:id()",
    children: [
  {
    name: editYzWyXU6SilMeta?.name ?? "bikes-id-index-edit",
    path: editYzWyXU6SilMeta?.path ?? "edit",
    meta: editYzWyXU6SilMeta || {},
    alias: editYzWyXU6SilMeta?.alias || [],
    redirect: editYzWyXU6SilMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/bikes/[id]/index/edit.vue").then(m => m.default || m)
  },
  {
    name: serviceAE4Lrc8FZiMeta?.name ?? "bikes-id-index-service",
    path: serviceAE4Lrc8FZiMeta?.path ?? "service",
    meta: serviceAE4Lrc8FZiMeta || {},
    alias: serviceAE4Lrc8FZiMeta?.alias || [],
    redirect: serviceAE4Lrc8FZiMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/bikes/[id]/index/service.vue").then(m => m.default || m)
  }
],
    meta: index7QKqjqlsf4Meta || {},
    alias: index7QKqjqlsf4Meta?.alias || [],
    redirect: index7QKqjqlsf4Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/bikes/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: _104RwOyAblrRzMeta?.name ?? "challenge-104",
    path: _104RwOyAblrRzMeta?.path ?? "/challenge/104",
    meta: _104RwOyAblrRzMeta || {},
    alias: _104RwOyAblrRzMeta?.alias || [],
    redirect: _104RwOyAblrRzMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/challenge/104.vue").then(m => m.default || m)
  },
  {
    name: _91id_93cSFNOxQIzZMeta?.name ?? "challenge-id",
    path: _91id_93cSFNOxQIzZMeta?.path ?? "/challenge/:id()",
    meta: _91id_93cSFNOxQIzZMeta || {},
    alias: _91id_93cSFNOxQIzZMeta?.alias || [],
    redirect: _91id_93cSFNOxQIzZMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/challenge/[id].vue").then(m => m.default || m)
  },
  {
    name: indexndEAgIqly3Meta?.name ?? "challenge-world-adventure-week-22",
    path: indexndEAgIqly3Meta?.path ?? "/challenge/world-adventure-week-22",
    meta: indexndEAgIqly3Meta || {},
    alias: indexndEAgIqly3Meta?.alias || [],
    redirect: indexndEAgIqly3Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/challenge/world-adventure-week-22/index.vue").then(m => m.default || m)
  },
  {
    name: rankingswcpYVD363JMeta?.name ?? "challenge-world-adventure-week-22-rankings",
    path: rankingswcpYVD363JMeta?.path ?? "/challenge/world-adventure-week-22/rankings",
    meta: rankingswcpYVD363JMeta || {},
    alias: rankingswcpYVD363JMeta?.alias || [],
    redirect: rankingswcpYVD363JMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/challenge/world-adventure-week-22/rankings.vue").then(m => m.default || m)
  },
  {
    name: indexbRqbiCdtQhMeta?.name ?? "challenge-world-adventure-week",
    path: indexbRqbiCdtQhMeta?.path ?? "/challenge/world-adventure-week",
    meta: indexbRqbiCdtQhMeta || {},
    alias: indexbRqbiCdtQhMeta?.alias || [],
    redirect: indexbRqbiCdtQhMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/challenge/world-adventure-week/index.vue").then(m => m.default || m)
  },
  {
    name: rankingsuuonGIJr4uMeta?.name ?? "challenge-world-adventure-week-rankings",
    path: rankingsuuonGIJr4uMeta?.path ?? "/challenge/world-adventure-week/rankings",
    meta: rankingsuuonGIJr4uMeta || {},
    alias: rankingsuuonGIJr4uMeta?.alias || [],
    redirect: rankingsuuonGIJr4uMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/challenge/world-adventure-week/rankings.vue").then(m => m.default || m)
  },
  {
    name: xajo_45distance_45challenge_4524g8wRXw7T8wMeta?.name ?? "challenge-xajo-distance-challenge-24",
    path: xajo_45distance_45challenge_4524g8wRXw7T8wMeta?.path ?? "/challenge/xajo-distance-challenge-24",
    meta: xajo_45distance_45challenge_4524g8wRXw7T8wMeta || {},
    alias: xajo_45distance_45challenge_4524g8wRXw7T8wMeta?.alias || [],
    redirect: xajo_45distance_45challenge_4524g8wRXw7T8wMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/challenge/xajo-distance-challenge-24.vue").then(m => m.default || m)
  },
  {
    name: index0iFCVE046QMeta?.name ?? "challenges",
    path: index0iFCVE046QMeta?.path ?? "/challenges",
    children: [
  {
    name: currentuD99nMN2rFMeta?.name ?? "challenges-index-current",
    path: currentuD99nMN2rFMeta?.path ?? "current",
    meta: currentuD99nMN2rFMeta || {},
    alias: currentuD99nMN2rFMeta?.alias || [],
    redirect: currentuD99nMN2rFMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/challenges/index/current.vue").then(m => m.default || m)
  },
  {
    name: pastS6nf9wqxdNMeta?.name ?? "challenges-index-past",
    path: pastS6nf9wqxdNMeta?.path ?? "past",
    meta: pastS6nf9wqxdNMeta || {},
    alias: pastS6nf9wqxdNMeta?.alias || [],
    redirect: pastS6nf9wqxdNMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/challenges/index/past.vue").then(m => m.default || m)
  },
  {
    name: upcomingUMrG2UF66vMeta?.name ?? "challenges-index-upcoming",
    path: upcomingUMrG2UF66vMeta?.path ?? "upcoming",
    meta: upcomingUMrG2UF66vMeta || {},
    alias: upcomingUMrG2UF66vMeta?.alias || [],
    redirect: upcomingUMrG2UF66vMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/challenges/index/upcoming.vue").then(m => m.default || m)
  }
],
    meta: index0iFCVE046QMeta || {},
    alias: index0iFCVE046QMeta?.alias || [],
    redirect: index0iFCVE046QMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/challenges/index.vue").then(m => m.default || m)
  },
  {
    name: agbrc6nzkNCG2Meta?.name ?? "de-agb",
    path: agbrc6nzkNCG2Meta?.path ?? "/de/agb",
    meta: agbrc6nzkNCG2Meta || {},
    alias: agbrc6nzkNCG2Meta?.alias || [],
    redirect: agbrc6nzkNCG2Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/de/agb.vue").then(m => m.default || m)
  },
  {
    name: indexuxnABaB9UFMeta?.name ?? "de",
    path: indexuxnABaB9UFMeta?.path ?? "/de",
    meta: indexuxnABaB9UFMeta || {},
    alias: indexuxnABaB9UFMeta?.alias || [],
    redirect: indexuxnABaB9UFMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/de/index.vue").then(m => m.default || m)
  },
  {
    name: ueberS6qXw1RCLwMeta?.name ?? "de-ueber",
    path: ueberS6qXw1RCLwMeta?.path ?? "/de/ueber",
    meta: ueberS6qXw1RCLwMeta || {},
    alias: ueberS6qXw1RCLwMeta?.alias || [],
    redirect: ueberS6qXw1RCLwMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/de/ueber.vue").then(m => m.default || m)
  },
  {
    name: indexzzR6L0pfELMeta?.name ?? "feed",
    path: indexzzR6L0pfELMeta?.path ?? "/feed",
    children: [
  {
    name: newT1DRWlVx4UMeta?.name ?? "feed-index-postings-new",
    path: newT1DRWlVx4UMeta?.path ?? "postings/new",
    meta: newT1DRWlVx4UMeta || {},
    alias: newT1DRWlVx4UMeta?.alias || [],
    redirect: newT1DRWlVx4UMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/feed/index/postings/new.vue").then(m => m.default || m)
  }
],
    meta: indexzzR6L0pfELMeta || {},
    alias: indexzzR6L0pfELMeta?.alias || [],
    redirect: indexzzR6L0pfELMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/feed/index.vue").then(m => m.default || m)
  },
  {
    name: getStartedxLX80H3YIMMeta?.name ?? "getStarted",
    path: getStartedxLX80H3YIMMeta?.path ?? "/getStarted",
    meta: getStartedxLX80H3YIMMeta || {},
    alias: getStartedxLX80H3YIMMeta?.alias || [],
    redirect: getStartedxLX80H3YIMMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/getStarted.vue").then(m => m.default || m)
  },
  {
    path: _91id_93ITUCY9ImyxMeta?.path ?? "/getaways/:id()",
    children: [
  {
    name: comments7iESIYH5ZdMeta?.name ?? "getaways-id-comments",
    path: comments7iESIYH5ZdMeta?.path ?? "comments",
    meta: comments7iESIYH5ZdMeta || {},
    alias: comments7iESIYH5ZdMeta?.alias || [],
    redirect: comments7iESIYH5ZdMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/getaways/[id]/comments.vue").then(m => m.default || m)
  },
  {
    name: detailsWIdgQJuIkRMeta?.name ?? "getaways-id-details",
    path: detailsWIdgQJuIkRMeta?.path ?? "details",
    children: [
  {
    name: editZiQ4oBK84xMeta?.name ?? "getaways-id-details-edit",
    path: editZiQ4oBK84xMeta?.path ?? "edit",
    meta: editZiQ4oBK84xMeta || {},
    alias: editZiQ4oBK84xMeta?.alias || [],
    redirect: editZiQ4oBK84xMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/getaways/[id]/details/edit.vue").then(m => m.default || m)
  },
  {
    name: inviteKQHmbkx95iMeta?.name ?? "getaways-id-details-invite",
    path: inviteKQHmbkx95iMeta?.path ?? "invite",
    meta: inviteKQHmbkx95iMeta || {},
    alias: inviteKQHmbkx95iMeta?.alias || [],
    redirect: inviteKQHmbkx95iMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/getaways/[id]/details/invite.vue").then(m => m.default || m)
  }
],
    meta: detailsWIdgQJuIkRMeta || {},
    alias: detailsWIdgQJuIkRMeta?.alias || [],
    redirect: detailsWIdgQJuIkRMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/getaways/[id]/details.vue").then(m => m.default || m)
  },
  {
    name: indexW4BwTPAbbsMeta?.name ?? "getaways-id",
    path: indexW4BwTPAbbsMeta?.path ?? "",
    meta: indexW4BwTPAbbsMeta || {},
    alias: indexW4BwTPAbbsMeta?.alias || [],
    redirect: indexW4BwTPAbbsMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/getaways/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: members1ekJcKwq22Meta?.name ?? "getaways-id-members",
    path: members1ekJcKwq22Meta?.path ?? "members",
    meta: members1ekJcKwq22Meta || {},
    alias: members1ekJcKwq22Meta?.alias || [],
    redirect: members1ekJcKwq22Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/getaways/[id]/members.vue").then(m => m.default || m)
  }
],
    name: _91id_93ITUCY9ImyxMeta?.name ?? undefined,
    meta: _91id_93ITUCY9ImyxMeta || {},
    alias: _91id_93ITUCY9ImyxMeta?.alias || [],
    redirect: _91id_93ITUCY9ImyxMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/getaways/[id].vue").then(m => m.default || m)
  },
  {
    path: _91id_93ssrF3Uadu5Meta?.path ?? "/groups/:id()",
    children: [
  {
    name: adminQCAuHiecYmMeta?.name ?? "groups-id-admin",
    path: adminQCAuHiecYmMeta?.path ?? "admin",
    meta: adminQCAuHiecYmMeta || {},
    alias: adminQCAuHiecYmMeta?.alias || [],
    redirect: adminQCAuHiecYmMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/groups/[id]/admin.vue").then(m => m.default || m)
  },
  {
    name: indexmhvKfa04tAMeta?.name ?? "groups-id",
    path: indexmhvKfa04tAMeta?.path ?? "",
    meta: indexmhvKfa04tAMeta || {},
    alias: indexmhvKfa04tAMeta?.alias || [],
    redirect: indexmhvKfa04tAMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/groups/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: members8eWFUbI5MSMeta?.name ?? "groups-id-members",
    path: members8eWFUbI5MSMeta?.path ?? "members",
    meta: members8eWFUbI5MSMeta || {},
    alias: members8eWFUbI5MSMeta?.alias || [],
    redirect: members8eWFUbI5MSMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/groups/[id]/members.vue").then(m => m.default || m)
  },
  {
    name: postsDTbYkt4JU1Meta?.name ?? "groups-id-posts",
    path: postsDTbYkt4JU1Meta?.path ?? "posts",
    meta: postsDTbYkt4JU1Meta || {},
    alias: postsDTbYkt4JU1Meta?.alias || [],
    redirect: postsDTbYkt4JU1Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/groups/[id]/posts.vue").then(m => m.default || m)
  },
  {
    name: statshnWJ83SVODMeta?.name ?? "groups-id-stats",
    path: statshnWJ83SVODMeta?.path ?? "stats",
    meta: statshnWJ83SVODMeta || {},
    alias: statshnWJ83SVODMeta?.alias || [],
    redirect: statshnWJ83SVODMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/groups/[id]/stats.vue").then(m => m.default || m)
  },
  {
    name: tripsCaSznesO5zMeta?.name ?? "groups-id-trips",
    path: tripsCaSznesO5zMeta?.path ?? "trips",
    meta: tripsCaSznesO5zMeta || {},
    alias: tripsCaSznesO5zMeta?.alias || [],
    redirect: tripsCaSznesO5zMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/groups/[id]/trips.vue").then(m => m.default || m)
  }
],
    name: _91id_93ssrF3Uadu5Meta?.name ?? undefined,
    meta: _91id_93ssrF3Uadu5Meta || {},
    alias: _91id_93ssrF3Uadu5Meta?.alias || [],
    redirect: _91id_93ssrF3Uadu5Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/groups/[id].vue").then(m => m.default || m)
  },
  {
    name: discoverBGtgFKEfkaMeta?.name ?? "groups-discover",
    path: discoverBGtgFKEfkaMeta?.path ?? "/groups/discover",
    meta: discoverBGtgFKEfkaMeta || {},
    alias: discoverBGtgFKEfkaMeta?.alias || [],
    redirect: discoverBGtgFKEfkaMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/groups/discover.vue").then(m => m.default || m)
  },
  {
    name: indexFCdY35y968Meta?.name ?? "groups",
    path: indexFCdY35y968Meta?.path ?? "/groups",
    children: [
  {
    name: newHGY0UXdoyrMeta?.name ?? "groups-index-new",
    path: newHGY0UXdoyrMeta?.path ?? "new",
    meta: newHGY0UXdoyrMeta || {},
    alias: newHGY0UXdoyrMeta?.alias || [],
    redirect: newHGY0UXdoyrMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/groups/index/new.vue").then(m => m.default || m)
  }
],
    meta: indexFCdY35y968Meta || {},
    alias: indexFCdY35y968Meta?.alias || [],
    redirect: indexFCdY35y968Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/groups/index.vue").then(m => m.default || m)
  },
  {
    name: indexbyLLsDLm8lMeta?.name ?? "helmets-id",
    path: indexbyLLsDLm8lMeta?.path ?? "/helmets/:id()",
    children: [
  {
    name: editzlomzIqXm2Meta?.name ?? "helmets-id-index-edit",
    path: editzlomzIqXm2Meta?.path ?? "edit",
    meta: editzlomzIqXm2Meta || {},
    alias: editzlomzIqXm2Meta?.alias || [],
    redirect: editzlomzIqXm2Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/helmets/[id]/index/edit.vue").then(m => m.default || m)
  }
],
    meta: indexbyLLsDLm8lMeta || {},
    alias: indexbyLLsDLm8lMeta?.alias || [],
    redirect: indexbyLLsDLm8lMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/helmets/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index6p5LCxGCIFMeta?.name ?? "intercoms-id",
    path: index6p5LCxGCIFMeta?.path ?? "/intercoms/:id()",
    children: [
  {
    name: editjuCviGZOwWMeta?.name ?? "intercoms-id-index-edit",
    path: editjuCviGZOwWMeta?.path ?? "edit",
    meta: editjuCviGZOwWMeta || {},
    alias: editjuCviGZOwWMeta?.alias || [],
    redirect: editjuCviGZOwWMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/intercoms/[id]/index/edit.vue").then(m => m.default || m)
  }
],
    meta: index6p5LCxGCIFMeta || {},
    alias: index6p5LCxGCIFMeta?.alias || [],
    redirect: index6p5LCxGCIFMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/intercoms/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: logoutlLSqGnk5PzMeta?.name ?? "logout",
    path: logoutlLSqGnk5PzMeta?.path ?? "/logout",
    meta: logoutlLSqGnk5PzMeta || {},
    alias: logoutlLSqGnk5PzMeta?.alias || [],
    redirect: logoutlLSqGnk5PzMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ezXKbiJvkOMeta?.name ?? "postings-id",
    path: _91id_93ezXKbiJvkOMeta?.path ?? "/postings/:id()",
    children: [
  {
    name: reportfhAHC85XrDMeta?.name ?? "postings-id-comments-comment_id-report",
    path: reportfhAHC85XrDMeta?.path ?? "comments/:comment_id()/report",
    meta: reportfhAHC85XrDMeta || {},
    alias: reportfhAHC85XrDMeta?.alias || [],
    redirect: reportfhAHC85XrDMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/postings/[id]/comments/[comment_id]/report.vue").then(m => m.default || m)
  },
  {
    name: report1324Bb4s3cMeta?.name ?? "postings-id-report",
    path: report1324Bb4s3cMeta?.path ?? "report",
    meta: report1324Bb4s3cMeta || {},
    alias: report1324Bb4s3cMeta?.alias || [],
    redirect: report1324Bb4s3cMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/postings/[id]/report.vue").then(m => m.default || m)
  }
],
    meta: _91id_93ezXKbiJvkOMeta || {},
    alias: _91id_93ezXKbiJvkOMeta?.alias || [],
    redirect: _91id_93ezXKbiJvkOMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/postings/[id].vue").then(m => m.default || m)
  },
  {
    name: _91code_93aDt0nX3N1fMeta?.name ?? "pro-code",
    path: _91code_93aDt0nX3N1fMeta?.path ?? "/pro/:code()",
    meta: _91code_93aDt0nX3N1fMeta || {},
    alias: _91code_93aDt0nX3N1fMeta?.alias || [],
    redirect: _91code_93aDt0nX3N1fMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/pro/[code].vue").then(m => m.default || m)
  },
  {
    name: request_password_resetJn7HC5Plx8Meta?.name ?? "request_password_reset",
    path: request_password_resetJn7HC5Plx8Meta?.path ?? "/request_password_reset",
    meta: request_password_resetJn7HC5Plx8Meta || {},
    alias: request_password_resetJn7HC5Plx8Meta?.alias || [],
    redirect: request_password_resetJn7HC5Plx8Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/request_password_reset.vue").then(m => m.default || m)
  },
  {
    name: indexsLuGxV2gvJMeta?.name ?? "routePlanner",
    path: indexsLuGxV2gvJMeta?.path ?? "/routePlanner",
    meta: indexsLuGxV2gvJMeta || {},
    alias: indexsLuGxV2gvJMeta?.alias || [],
    redirect: indexsLuGxV2gvJMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/routePlanner/index.vue").then(m => m.default || m)
  },
  {
    name: previewrerlEDFyiBMeta?.name ?? "routePlanner-preview",
    path: previewrerlEDFyiBMeta?.path ?? "/routePlanner/preview",
    meta: previewrerlEDFyiBMeta || {},
    alias: previewrerlEDFyiBMeta?.alias || [],
    redirect: previewrerlEDFyiBMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/routePlanner/preview.vue").then(m => m.default || m)
  },
  {
    name: searchi1lVFWwv2QMeta?.name ?? "search",
    path: searchi1lVFWwv2QMeta?.path ?? "/search",
    meta: searchi1lVFWwv2QMeta || {},
    alias: searchi1lVFWwv2QMeta?.alias || [],
    redirect: searchi1lVFWwv2QMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/search.vue").then(m => m.default || m)
  },
  {
    name: _91id_93cmO0QSOEE8Meta?.name ?? "sections-id",
    path: _91id_93cmO0QSOEE8Meta?.path ?? "/sections/:id()",
    meta: _91id_93cmO0QSOEE8Meta || {},
    alias: _91id_93cmO0QSOEE8Meta?.alias || [],
    redirect: _91id_93cmO0QSOEE8Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/sections/[id].vue").then(m => m.default || m)
  },
  {
    name: _91token_93GV4KEEo09kMeta?.name ?? "set_password-token",
    path: _91token_93GV4KEEo09kMeta?.path ?? "/set_password/:token()",
    meta: _91token_93GV4KEEo09kMeta || {},
    alias: _91token_93GV4KEEo09kMeta?.alias || [],
    redirect: _91token_93GV4KEEo09kMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/set_password/[token].vue").then(m => m.default || m)
  },
  {
    name: settings6ZNFu1CSghMeta?.name ?? "settings",
    path: settings6ZNFu1CSghMeta?.path ?? "/settings",
    meta: settings6ZNFu1CSghMeta || {},
    alias: settings6ZNFu1CSghMeta?.alias || [],
    redirect: settings6ZNFu1CSghMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: indexp3j8RewfHOMeta?.name ?? "statistics",
    path: indexp3j8RewfHOMeta?.path ?? "/statistics",
    children: [
  {
    name: alltimeYQtso45YD7Meta?.name ?? "statistics-index-alltime",
    path: alltimeYQtso45YD7Meta?.path ?? "alltime",
    meta: alltimeYQtso45YD7Meta || {},
    alias: alltimeYQtso45YD7Meta?.alias || [],
    redirect: alltimeYQtso45YD7Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/statistics/index/alltime.vue").then(m => m.default || m)
  },
  {
    name: month8ol6If5GoaMeta?.name ?? "statistics-index-month",
    path: month8ol6If5GoaMeta?.path ?? "month",
    meta: month8ol6If5GoaMeta || {},
    alias: month8ol6If5GoaMeta?.alias || [],
    redirect: month8ol6If5GoaMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/statistics/index/month.vue").then(m => m.default || m)
  },
  {
    name: weekQIW4nD5iDCMeta?.name ?? "statistics-index-week",
    path: weekQIW4nD5iDCMeta?.path ?? "week",
    meta: weekQIW4nD5iDCMeta || {},
    alias: weekQIW4nD5iDCMeta?.alias || [],
    redirect: weekQIW4nD5iDCMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/statistics/index/week.vue").then(m => m.default || m)
  },
  {
    name: yearNdCkA3apl6Meta?.name ?? "statistics-index-year",
    path: yearNdCkA3apl6Meta?.path ?? "year",
    meta: yearNdCkA3apl6Meta || {},
    alias: yearNdCkA3apl6Meta?.alias || [],
    redirect: yearNdCkA3apl6Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/statistics/index/year.vue").then(m => m.default || m)
  }
],
    meta: indexp3j8RewfHOMeta || {},
    alias: indexp3j8RewfHOMeta?.alias || [],
    redirect: indexp3j8RewfHOMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/statistics/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93svlVKfhEY0Meta?.name ?? "t-id",
    path: _91id_93svlVKfhEY0Meta?.path ?? "/t/:id()",
    children: [
  {
    name: index8Zsv18cbZTMeta?.name ?? "t-id-name",
    path: index8Zsv18cbZTMeta?.path ?? ":name(.*)*",
    meta: index8Zsv18cbZTMeta || {},
    alias: index8Zsv18cbZTMeta?.alias || [],
    redirect: index8Zsv18cbZTMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/t/[id]/[...name]/index.vue").then(m => m.default || m)
  },
  {
    name: rewindm2QEgnV5mRMeta?.name ?? "t-id-rewind",
    path: rewindm2QEgnV5mRMeta?.path ?? "rewind",
    meta: rewindm2QEgnV5mRMeta || {},
    alias: rewindm2QEgnV5mRMeta?.alias || [],
    redirect: rewindm2QEgnV5mRMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/t/[id]/rewind.vue").then(m => m.default || m)
  }
],
    meta: _91id_93svlVKfhEY0Meta || {},
    alias: _91id_93svlVKfhEY0Meta?.alias || [],
    redirect: _91id_93svlVKfhEY0Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/t/[id].vue").then(m => m.default || m)
  },
  {
    name: terms9FLT7o4CI4Meta?.name ?? "terms",
    path: terms9FLT7o4CI4Meta?.path ?? "/terms",
    meta: terms9FLT7o4CI4Meta || {},
    alias: terms9FLT7o4CI4Meta?.alias || [],
    redirect: terms9FLT7o4CI4Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: _91id_93pagxHSaKFbMeta?.name ?? "trips-id",
    path: _91id_93pagxHSaKFbMeta?.path ?? "/trips/:id()",
    children: [
  {
    name: editvoEP4zFoAKMeta?.name ?? "trips-id-edit",
    path: editvoEP4zFoAKMeta?.path ?? "edit",
    meta: editvoEP4zFoAKMeta || {},
    alias: editvoEP4zFoAKMeta?.alias || [],
    redirect: editvoEP4zFoAKMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/trips/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: participantsZrptBkT9MdMeta?.name ?? "trips-id-participants",
    path: participantsZrptBkT9MdMeta?.path ?? "participants",
    meta: participantsZrptBkT9MdMeta || {},
    alias: participantsZrptBkT9MdMeta?.alias || [],
    redirect: participantsZrptBkT9MdMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/trips/[id]/participants.vue").then(m => m.default || m)
  },
  {
    name: photosJs7fjXZoDyMeta?.name ?? "trips-id-photos",
    path: photosJs7fjXZoDyMeta?.path ?? "photos",
    meta: photosJs7fjXZoDyMeta || {},
    alias: photosJs7fjXZoDyMeta?.alias || [],
    redirect: photosJs7fjXZoDyMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/trips/[id]/photos.vue").then(m => m.default || m)
  },
  {
    name: shareyH0UiV0xUgMeta?.name ?? "trips-id-share",
    path: shareyH0UiV0xUgMeta?.path ?? "share",
    meta: shareyH0UiV0xUgMeta || {},
    alias: shareyH0UiV0xUgMeta?.alias || [],
    redirect: shareyH0UiV0xUgMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/trips/[id]/share.vue").then(m => m.default || m)
  }
],
    meta: _91id_93pagxHSaKFbMeta || {},
    alias: _91id_93pagxHSaKFbMeta?.alias || [],
    redirect: _91id_93pagxHSaKFbMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/trips/[id].vue").then(m => m.default || m)
  },
  {
    name: indexDroJuczQCeMeta?.name ?? "upgrade",
    path: indexDroJuczQCeMeta?.path ?? "/upgrade",
    meta: indexDroJuczQCeMeta || {},
    alias: indexDroJuczQCeMeta?.alias || [],
    redirect: indexDroJuczQCeMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/upgrade/index.vue").then(m => m.default || m)
  },
  {
    name: success7jeoXXAMFZMeta?.name ?? "upgrade-success",
    path: success7jeoXXAMFZMeta?.path ?? "/upgrade/success",
    meta: success7jeoXXAMFZMeta || {},
    alias: success7jeoXXAMFZMeta?.alias || [],
    redirect: success7jeoXXAMFZMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/upgrade/success.vue").then(m => m.default || m)
  },
  {
    path: _91id_93nZHoHmZP6bMeta?.path ?? "/user/:id()",
    children: [
  {
    name: indexG5wcSIyaFVMeta?.name ?? "user-id-achievements",
    path: indexG5wcSIyaFVMeta?.path ?? "achievements",
    children: [
  {
    name: _91achievementID_935WvCGxxqsNMeta?.name ?? "user-id-achievements-index-achievementID",
    path: _91achievementID_935WvCGxxqsNMeta?.path ?? ":achievementID()",
    meta: _91achievementID_935WvCGxxqsNMeta || {},
    alias: _91achievementID_935WvCGxxqsNMeta?.alias || [],
    redirect: _91achievementID_935WvCGxxqsNMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/achievements/index/[achievementID].vue").then(m => m.default || m)
  }
],
    meta: indexG5wcSIyaFVMeta || {},
    alias: indexG5wcSIyaFVMeta?.alias || [],
    redirect: indexG5wcSIyaFVMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/achievements/index.vue").then(m => m.default || m)
  },
  {
    name: blockedVP0DbnYUGAMeta?.name ?? "user-id-blocked",
    path: blockedVP0DbnYUGAMeta?.path ?? "blocked",
    meta: blockedVP0DbnYUGAMeta || {},
    alias: blockedVP0DbnYUGAMeta?.alias || [],
    redirect: blockedVP0DbnYUGAMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/blocked.vue").then(m => m.default || m)
  },
  {
    name: follow_45requestsRSed7MayShMeta?.name ?? "user-id-follow-requests",
    path: follow_45requestsRSed7MayShMeta?.path ?? "follow-requests",
    meta: follow_45requestsRSed7MayShMeta || {},
    alias: follow_45requestsRSed7MayShMeta?.alias || [],
    redirect: follow_45requestsRSed7MayShMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/follow-requests.vue").then(m => m.default || m)
  },
  {
    name: followers5KiJkmPMABMeta?.name ?? "user-id-followers",
    path: followers5KiJkmPMABMeta?.path ?? "followers",
    meta: followers5KiJkmPMABMeta || {},
    alias: followers5KiJkmPMABMeta?.alias || [],
    redirect: followers5KiJkmPMABMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/followers.vue").then(m => m.default || m)
  },
  {
    name: following44kGiE8REpMeta?.name ?? "user-id-following",
    path: following44kGiE8REpMeta?.path ?? "following",
    meta: following44kGiE8REpMeta || {},
    alias: following44kGiE8REpMeta?.alias || [],
    redirect: following44kGiE8REpMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/following.vue").then(m => m.default || m)
  },
  {
    name: index95pFcgrhUIMeta?.name ?? "user-id-garage-bikes",
    path: index95pFcgrhUIMeta?.path ?? "garage/bikes",
    children: [
  {
    name: newIXh6AsdSsNMeta?.name ?? "user-id-garage-bikes-index-new",
    path: newIXh6AsdSsNMeta?.path ?? "new",
    meta: newIXh6AsdSsNMeta || {},
    alias: newIXh6AsdSsNMeta?.alias || [],
    redirect: newIXh6AsdSsNMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/garage/bikes/index/new.vue").then(m => m.default || m)
  }
],
    meta: index95pFcgrhUIMeta || {},
    alias: index95pFcgrhUIMeta?.alias || [],
    redirect: index95pFcgrhUIMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/garage/bikes/index.vue").then(m => m.default || m)
  },
  {
    name: indexfc4FNd12UFMeta?.name ?? "user-id-garage-helmets",
    path: indexfc4FNd12UFMeta?.path ?? "garage/helmets",
    children: [
  {
    name: new5rCnFDSVyDMeta?.name ?? "user-id-garage-helmets-index-new",
    path: new5rCnFDSVyDMeta?.path ?? "new",
    meta: new5rCnFDSVyDMeta || {},
    alias: new5rCnFDSVyDMeta?.alias || [],
    redirect: new5rCnFDSVyDMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/garage/helmets/index/new.vue").then(m => m.default || m)
  }
],
    meta: indexfc4FNd12UFMeta || {},
    alias: indexfc4FNd12UFMeta?.alias || [],
    redirect: indexfc4FNd12UFMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/garage/helmets/index.vue").then(m => m.default || m)
  },
  {
    name: indexC0icOB0YZfMeta?.name ?? "user-id-garage",
    path: indexC0icOB0YZfMeta?.path ?? "garage",
    meta: indexC0icOB0YZfMeta || {},
    alias: indexC0icOB0YZfMeta?.alias || [],
    redirect: indexC0icOB0YZfMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/garage/index.vue").then(m => m.default || m)
  },
  {
    name: indexzbH6lCOVixMeta?.name ?? "user-id-garage-intercoms",
    path: indexzbH6lCOVixMeta?.path ?? "garage/intercoms",
    children: [
  {
    name: newxuGCbClrwxMeta?.name ?? "user-id-garage-intercoms-index-new",
    path: newxuGCbClrwxMeta?.path ?? "new",
    meta: newxuGCbClrwxMeta || {},
    alias: newxuGCbClrwxMeta?.alias || [],
    redirect: newxuGCbClrwxMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/garage/intercoms/index/new.vue").then(m => m.default || m)
  }
],
    meta: indexzbH6lCOVixMeta || {},
    alias: indexzbH6lCOVixMeta?.alias || [],
    redirect: indexzbH6lCOVixMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/garage/intercoms/index.vue").then(m => m.default || m)
  },
  {
    name: index0aGglwb0PxMeta?.name ?? "user-id-getaways",
    path: index0aGglwb0PxMeta?.path ?? "getaways",
    children: [
  {
    name: newftPIYKmvCNMeta?.name ?? "user-id-getaways-index-new",
    path: newftPIYKmvCNMeta?.path ?? "new",
    meta: newftPIYKmvCNMeta || {},
    alias: newftPIYKmvCNMeta?.alias || [],
    redirect: newftPIYKmvCNMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/getaways/index/new.vue").then(m => m.default || m)
  }
],
    meta: index0aGglwb0PxMeta || {},
    alias: index0aGglwb0PxMeta?.alias || [],
    redirect: index0aGglwb0PxMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/getaways/index.vue").then(m => m.default || m)
  },
  {
    name: indexLOHD2UeSzKMeta?.name ?? "user-id-groups",
    path: indexLOHD2UeSzKMeta?.path ?? "groups",
    children: [
  {
    name: newrgsDWspsQrMeta?.name ?? "user-id-groups-index-new",
    path: newrgsDWspsQrMeta?.path ?? "new",
    meta: newrgsDWspsQrMeta || {},
    alias: newrgsDWspsQrMeta?.alias || [],
    redirect: newrgsDWspsQrMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/groups/index/new.vue").then(m => m.default || m)
  }
],
    meta: indexLOHD2UeSzKMeta || {},
    alias: indexLOHD2UeSzKMeta?.alias || [],
    redirect: indexLOHD2UeSzKMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/groups/index.vue").then(m => m.default || m)
  },
  {
    name: heatmapnmwnL3ZrdaMeta?.name ?? "user-id-heatmap",
    path: heatmapnmwnL3ZrdaMeta?.path ?? "heatmap",
    meta: heatmapnmwnL3ZrdaMeta || {},
    alias: heatmapnmwnL3ZrdaMeta?.alias || [],
    redirect: heatmapnmwnL3ZrdaMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/heatmap.vue").then(m => m.default || m)
  },
  {
    name: indexwaLU4zPJr5Meta?.name ?? "user-id",
    path: indexwaLU4zPJr5Meta?.path ?? "",
    meta: indexwaLU4zPJr5Meta || {},
    alias: indexwaLU4zPJr5Meta?.alias || [],
    redirect: indexwaLU4zPJr5Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: photosyAC2Jrz0DvMeta?.name ?? "user-id-photos",
    path: photosyAC2Jrz0DvMeta?.path ?? "photos",
    meta: photosyAC2Jrz0DvMeta || {},
    alias: photosyAC2Jrz0DvMeta?.alias || [],
    redirect: photosyAC2Jrz0DvMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/photos.vue").then(m => m.default || m)
  },
  {
    path: indexs1M0TjFUnaMeta?.path ?? "roadbook",
    children: [
  {
    name: indexindlaMMysqMeta?.name ?? "user-id-roadbook-index",
    path: indexindlaMMysqMeta?.path ?? "",
    meta: indexindlaMMysqMeta || {},
    alias: indexindlaMMysqMeta?.alias || [],
    redirect: indexindlaMMysqMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/roadbook/index/index.vue").then(m => m.default || m)
  },
  {
    name: planneds6wLUNFhWUMeta?.name ?? "user-id-roadbook-index-planned",
    path: planneds6wLUNFhWUMeta?.path ?? "planned",
    meta: planneds6wLUNFhWUMeta || {},
    alias: planneds6wLUNFhWUMeta?.alias || [],
    redirect: planneds6wLUNFhWUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/roadbook/index/planned.vue").then(m => m.default || m)
  },
  {
    name: sectionsF7d0SI8NcPMeta?.name ?? "user-id-roadbook-index-sections",
    path: sectionsF7d0SI8NcPMeta?.path ?? "sections",
    meta: sectionsF7d0SI8NcPMeta || {},
    alias: sectionsF7d0SI8NcPMeta?.alias || [],
    redirect: sectionsF7d0SI8NcPMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/roadbook/index/sections.vue").then(m => m.default || m)
  },
  {
    name: tripsw3setHonhsMeta?.name ?? "user-id-roadbook-index-trips",
    path: tripsw3setHonhsMeta?.path ?? "trips",
    meta: tripsw3setHonhsMeta || {},
    alias: tripsw3setHonhsMeta?.alias || [],
    redirect: tripsw3setHonhsMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/roadbook/index/trips.vue").then(m => m.default || m)
  }
],
    name: indexs1M0TjFUnaMeta?.name ?? undefined,
    meta: indexs1M0TjFUnaMeta || {},
    alias: indexs1M0TjFUnaMeta?.alias || [],
    redirect: indexs1M0TjFUnaMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/roadbook/index.vue").then(m => m.default || m)
  },
  {
    name: indexri39BdMBDUMeta?.name ?? "user-id-timeline",
    path: indexri39BdMBDUMeta?.path ?? "timeline",
    children: [
  {
    name: edit5Jx5Nk4nMIMeta?.name ?? "user-id-timeline-index-edit",
    path: edit5Jx5Nk4nMIMeta?.path ?? "edit",
    meta: edit5Jx5Nk4nMIMeta || {},
    alias: edit5Jx5Nk4nMIMeta?.alias || [],
    redirect: edit5Jx5Nk4nMIMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/timeline/index/edit.vue").then(m => m.default || m)
  }
],
    meta: indexri39BdMBDUMeta || {},
    alias: indexri39BdMBDUMeta?.alias || [],
    redirect: indexri39BdMBDUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id]/timeline/index.vue").then(m => m.default || m)
  }
],
    name: _91id_93nZHoHmZP6bMeta?.name ?? undefined,
    meta: _91id_93nZHoHmZP6bMeta || {},
    alias: _91id_93nZHoHmZP6bMeta?.alias || [],
    redirect: _91id_93nZHoHmZP6bMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/[id].vue").then(m => m.default || m)
  },
  {
    name: indexuu6yzSj8DAMeta?.name ?? "user",
    path: indexuu6yzSj8DAMeta?.path ?? "/user",
    meta: indexuu6yzSj8DAMeta || {},
    alias: indexuu6yzSj8DAMeta?.alias || [],
    redirect: indexuu6yzSj8DAMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_937gbqtu2VpJMeta?.name ?? "verify_email-userid-token",
    path: _91token_937gbqtu2VpJMeta?.path ?? "/verify_email/:userid()/:token()",
    meta: _91token_937gbqtu2VpJMeta || {},
    alias: _91token_937gbqtu2VpJMeta?.alias || [],
    redirect: _91token_937gbqtu2VpJMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/verify_email/[userid]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93im7TnYXFa8Meta?.name ?? "verify_newsletter-userid-token",
    path: _91token_93im7TnYXFa8Meta?.path ?? "/verify_newsletter/:userid()/:token()",
    meta: _91token_93im7TnYXFa8Meta || {},
    alias: _91token_93im7TnYXFa8Meta?.alias || [],
    redirect: _91token_93im7TnYXFa8Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/verify_newsletter/[userid]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91code_93XW5ApyZ4YVMeta?.name ?? "voucher-code",
    path: _91code_93XW5ApyZ4YVMeta?.path ?? "/voucher/:code()",
    meta: _91code_93XW5ApyZ4YVMeta || {},
    alias: _91code_93XW5ApyZ4YVMeta?.alias || [],
    redirect: _91code_93XW5ApyZ4YVMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/voucher/[code].vue").then(m => m.default || m)
  },
  {
    name: webviewsG5MhfBRPq6Meta?.name ?? "webviews",
    path: webviewsG5MhfBRPq6Meta?.path ?? "/webviews",
    children: [
  {
    name: _91id_937AodZTT4jjMeta?.name ?? "webviews-groups-id",
    path: _91id_937AodZTT4jjMeta?.path ?? "groups/:id()",
    meta: _91id_937AodZTT4jjMeta || {},
    alias: _91id_937AodZTT4jjMeta?.alias || [],
    redirect: _91id_937AodZTT4jjMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/webviews/groups/[id].vue").then(m => m.default || m)
  },
  {
    name: indexJkwXHTyWaIMeta?.name ?? "webviews-groups",
    path: indexJkwXHTyWaIMeta?.path ?? "groups",
    meta: indexJkwXHTyWaIMeta || {},
    alias: indexJkwXHTyWaIMeta?.alias || [],
    redirect: indexJkwXHTyWaIMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/webviews/groups/index.vue").then(m => m.default || m)
  },
  {
    name: search9SWcE4NPqUMeta?.name ?? "webviews-groups-search",
    path: search9SWcE4NPqUMeta?.path ?? "groups/search",
    meta: search9SWcE4NPqUMeta || {},
    alias: search9SWcE4NPqUMeta?.alias || [],
    redirect: search9SWcE4NPqUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/webviews/groups/search.vue").then(m => m.default || m)
  }
],
    meta: webviewsG5MhfBRPq6Meta || {},
    alias: webviewsG5MhfBRPq6Meta?.alias || [],
    redirect: webviewsG5MhfBRPq6Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/webviews.vue").then(m => m.default || m)
  }
]