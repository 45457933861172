{
  "%@ finished a trip": "{0} hat einen Trip beendet! 🏍️ Schau ihn dir an und gib ihm ein Like!🔥",
  "Starting-Point": "Startpunkt",
  "Fastest": "Direkt",
  "duration": "Dauer",
  "Long": "lang",
  "Short": "kurz",
  "Medium": "mittel",
  "Export-as-gpx": "Als GPX exportieren",
  "Export-and-save": "Exportieren und speichern",
  "destination": "Ziel",
  "Hours": "Stunden",
  "Hold on a second. We are calculating your route": "Wir berechnen deine Route, bitte um einen Augenblick Geduld …",
  "Please-type-in-%@-to-confirm": "Bitte bestätige, indem du {0} eingibst.",
  "My Account": "Mein Konto",
  "Go to Settings": "Gehe zu Einstellungen",
  "End-Date": "Enddatum",
  "Start-Date": "Startdatum",
  "Invite-Users": "Lade Nutzer ein",
  "HAVE-A-PRO-X-MAS": "HAVE A PRO X-MAS!",
  "Our-way-to-say-thank-you-get-percentage-first-year": "Danke, dass du Teil der RISER Community bist. \n\nWir schenken dir {0} Rabatt auf dein erstes Jahr RISER PRO.",
  "First-year": "1. Jahr",
  "No-commitment-cancel-at-any-time": "Keine Verpflichtungen, jederzeit kündbar.",
  "then": "danach {0}",
  "%@ added a new bike": "{0} hat ein neues Bike hinzugefügt",
  "%@ and you are now friends on RISER": "{0} und du sind jetzt Freunde auf RISER",
  "%@ sent you a friend request": "{0} hat dir eine Freundschaftsanfrage gesendet",
  "%@ liked your section": "{0} gefällt dein Segment",
  "%@ commented on your section": "{0} hat dein Segment kommentiert",
  "%@ liked your trip": "{0} gefällt dein Trip",
  "%@ commented on your trip": "{0} hat deinen Trip kommentiert",
  "%@ liked your bike": "{0} gefällt dein Bike",
  "%@ commented on your bike": "{0} hat dein Bike kommentiert",
  "%1@ and %2d others like this": "{0} und {1} anderen gefällt das",
  "%1d bikers drove here %2d times": "{0} Biker waren hier {1} mal unterwegs",
  "%@ likes this": "{0} gefällt das",
  "%d bhp": "{0} PS",
  "%d friends": "{0} Freunde",
  "%d hour(s) ago": "vor {0} Stunde(n)",
  "%d minute(s) ago": "vor {0} Minute(n)",
  "%d of %d": "{0} von {1}",
  "%d people like this": "{0} Leuten gefällt das",
  "%d second(s) ago": "vor {0} Sekunde(n)",
  "Accept": "Annehmen",
  "Account & Settings": "Account und Einstellungen",
  "Add Bike": "Bike hinzufügen",
  "Add comment...": "Kommentar hinzufügen...",
  "Add friend": "Freund hinzufügen",
  "Add to my Roadbook": "Zu meinem Fahrtenbuch hinzufügen",
  "An unknown error occured. We are sorry for the inconvenience.": "Ein unerwarteter Fehler ist aufgetreten. Wir entschuldigen uns für die Störung.",
  "and": "und",
  "Anyone": "Jeder",
  "Are you sure you want to delete this photo?": "Möchtest du das Foto sicher löschen?",
  "Are you sure you want to discard this trip?": "Möchtest du den Trip wirklich verwerfen?",
  "Are you sure you want to report this user?": "Möchtest du den User wirklich melden?",
  "Are you sure? This action can not be undone.": "Bist du sicher? Diese Aktion kann nicht rückgängig gemacht werden.",
  "avg speed": "Ø Geschw.",
  "Basic": "Basic",
  "Build year (optional)": "Baujahr (optional)",
  "Build year": "Baujahr",
  "By signing up you accept our": "Durch die Registrierung werden folgende Bedingungen akzeptiert",
  "Cancel": "Abbrechen",
  "Caution!": "Achtung!",
  "Change Password": "Passwort ändern",
  "Check out RISER at https:\/\/riserapp.com\/": "Guck dir RISER an unter https:\/\/riserapp.com\/",
  "choose bike": "Bike auswählen",
  "Choose Brand": "Marke auswählen",
  "Choose build year": "Baujahr auswählen",
  "Choose Model": "Modell auswählen",
  "City": "Stadt",
  "Climb": "Anstieg",
  "Climb \/ Fall": "Anstieg \/ Abstieg",
  "Coast": "Küste",
  "Complete Signup": "Konto erstellen",
  "Confirm new password": "Neues Passwort bestätigen",
  "Connect with Facebook": "Mit Facebook verbinden",
  "Continue with Facebook": "Mit Facebook verbinden",
  "Create Section": "Segment erstellen",
  "Create special sections and share them with the community": "Erstelle besonders schöne Segmente und teile sie mit der Community",
  "Curves": "Kurven",
  "Current Password": "Aktuelles Passwort",
  "Dark": "Dunkel",
  "Decline": "Ablehnen",
  "Delete Account": "Konto löschen",
  "Delete Bike": "Bike löschen",
  "Delete Section": "Segment löschen",
  "Delete Trip": "Trip löschen",
  "Description": "Beschreibung",
  "Desert": "Wüste",
  "Discover popular roads with live weather and forecast": "Entdecke neue Strecken mit Live-Wetter und Wettervorhersage",
  "Discover": "Entdecken",
  "Display": "Anzeige",
  "Distance": "Distanz",
  "edit friends": "Freunde editieren",
  "Edit Bike": "Bike ändern",
  "Edit Photo": "Foto ändern",
  "Edit Section": "Segment ändern",
  "Edit Trip": "Trip ändern",
  "Email": "E-Mail",
  "Engine Power (optional)": "Leistung (optional)",
  "Engine Power": "Leistung",
  "Enter current password": "Aktuelles Passwort",
  "Enter new password": "Neues Passwort",
  "Enter your name in order to make it possible for your friends to find you...": "Gib deinen Namen ein um von deinen Freunden gefunden werden zu können...",
  "Error": "Fehler",
  "Facebook": "Facebook",
  "Feed": "Feed",
  "Finish": "Beenden",
  "First Name": "Vorname",
  "Forgot password?": "Passwort vergessen?",
  "Friday Ride": "Freitagsausfahrt",
  "Friends": "Freunde",
  "Garage": "Garage",
  "Go!": "Los!",
  "Heads up!": "Aufgepasst!",
  "How did it go?": "Wie war es?",
  "How was the weather?": "Wie war das Wetter?",
  "Imperial": "Imperial",
  "Info": "Info",
  "Invalid email \/ password combination": "Ungültige E-Mail\/Passwort Kombination",
  "Invite friends": "Freunde einladen",
  "Invite your friends to join RISER": "Lade deine Freunde zu RISER ein",
  "Join us at RISER": "Komm zu RISER",
  "Just now": "Gerade eben",
  "kmh": "km\/h",
  "Last Name": "Nachname",
  "Link to trip": "Link zum Trip",
  "Loading weather...": "Lade Wetter...",
  "Log out": "Ausloggen",
  "Logging you in...": "Einloggen...",
  "Login": "Login",
  "Looking for matching sections...": "Suche nach Segmenten...",
  "Manufacturer": "Hersteller",
  "Map Type": "Kartentyp",
  "Matching sections": "Gefahrene Segmente",
  "Max Speed": "Max Geschw.",
  "Membership": "Mitgliedschaft",
  "Message": "Nachricht",
  "Messenger": "Messenger",
  "Metric": "Metrisch",
  "Miscellaneous": "Verschiedenes",
  "Model": "Modell",
  "Monday Ride": "Montagsausfahrt",
  "More": "Mehr",
  "Mountain": "Berg",
  "mph": "mi\/h",
  "My Profile": "Mein Profil",
  "New Password": "Neues Passwort",
  "Next": "Weiter",
  "Nickname (optional)": "Nickname (optional)",
  "Nickname": "Nickname",
  "No Internet Connection": "Keine Internetverbindung",
  "No matching sections found.": "Keine Segmente gefunden.",
  "No note added.": "Keine Notiz hinzugefügt.",
  "Notifications": "Benachrichtigungen",
  "Offroad": "Offroad",
  "Ok": "Ok",
  "Only me": "Nur ich",
  "Oops!": "Uups!",
  "Open Settings": "Einstellungen öffnen",
  "or": "oder",
  "Passwords have to consist of min 8 and max 32 characters.": "Passwörter müssen aus mindestens 8 und maximal 32 Zeichen bestehen.",
  "Password": "Passwort",
  "Password reset": "Passwort zurücksetzen",
  "Pause": "Pause",
  "Pending": "Ausstehend",
  "Photo Library": "Fotobibliothek",
  "Photo": "Foto",
  "Photos": "Fotos",
  "Photos along this section": "Fotos entlang dieses Segments",
  "Please choose at least a brand and a model to add a bike to your collection.": "Bitte gib zumindest den Hersteller und das Modell an um ein Bike hinzufügen zu können.",
  "Please enter a valid email address": "Bitte gib eine gültige E-Mail-Adresse an",
  "Please enter an email and a password": "Bitte gib eine E-Mail und ein Passwort ein",
  "Please provide the email address you registered with at RISER: ": "Bitte gib die E-Mail-Adresse von deinem RISER-Konto an:",
  "Privacy": "Privatsphäre",
  "Privacy Policy": "Datenschutzerklärung",
  "Pro": "PRO",
  "Rank": "Rang",
  "Remove from roadbook": "Vom Fahrtenbuch entfernen",
  "Report User": "User melden",
  "Requested": "Angefragt",
  "Resume": "Fortsetzen",
  "RISER Feedback": "RISER Feedback",
  "RISER TV": "RISER TV",
  "Roadbook": "Fahrtenbuch",
  "Saturday Ride": "Samstagsausfahrt",
  "Save": "Speichern",
  "Sea level (max\/min)": "Seehöhe (max\/min)",
  "Secret Link": "Geheimer Link",
  "Section Title": "Segmenttitel",
  "Successfully added to your roadbook": "Erfolgreich zum Fahrtenbuch hinzugefügt",
  "Section": "Segment",
  "Sections": "Segmente",
  "Send Feedback": "Feedback senden",
  "Settings": "Einstellungen",
  "Share Link": "Link teilen",
  "Show Photo": "Foto anzeigen",
  "Signing you up...": "Registrierung läuft...",
  "Sorry, we couldn't establish a connection to the server.": "Sorry, wir konnten keine Verbindung zum Server herstellen.",
  "Sponsored Post": "Gesponserter Post",
  "Start Trip": "Trip starten",
  "Step 1\/2": "Schritt 1\/2",
  "Step 2\/2": "Schritt 2\/2",
  "Streets": "Straße",
  "Success": "Erfolg",
  "Summary": "Zusammenfassung",
  "Sunday Ride": "Sonntagsausfahrt",
  "Tell us what you think about RISER. What do you like? What can we do better? What features are you missing?": "Sag uns was du von RISER hältst. Was gefällt dir? Was können wir besser machen? Welche Features fehlen dir?",
  "Terms of Service": "Nutzungsbedingungen",
  "Terrain": "Gelände",
  "The email address you entered already exists": "Die angegebene E-Mail-Adresse existiert bereits",
  "The password you entered doesn't match your current password.": "Dein eingegebenes Passwort stimmt nicht mit dem derzeitigen Passwort überein.",
  "The passwords you entered don't match.": "Die beiden Passwörter stimmen nicht überein",
  "Thursday Ride": "Donnerstagsausfahrt",
  "Time": "Zeit",
  "Top speed": "Höchstgeschw.",
  "Track your trips and organize them in your personal roadbook": "Zeichne deine Trips auf und speichere sie in deinem Fahrtenbuch",
  "Trips": "Trips",
  "Tuesday Ride": "Dienstagsausfahrt",
  "Turn on to generate share link": "Aktiviere um einen Link zu erstellen",
  "Unexpected Error": "Unerwarteter Fehler",
  "Unfriend": "Freund entfernen",
  "Units": "Einheiten",
  "Update": "Update",
  "Update failed": "Update fehlgeschlagen",
  "Updating bike...": "Update Bike...",
  "Updating password...": "Update Passwort...",
  "Updating section...": "Update Segment...",
  "Updating trip...": "Update Trip...",
  "Updating user credentials...": "Update Zugangsdaten...",
  "Uploading bike...": "Bike hochladen...",
  "Uploading section...": "Segment hochladen...",
  "Uploading trip...": "Trip hochladen...",
  "Version": "Version",
  "Scenery": "Landschaft",
  "Warning": "Warnung",
  "Water": "Wasser",
  "Wednesday Ride": "Mittwochsausfahrt",
  "Welcome back!": "Willkommen zurück!",
  "Welcome to the family!": "Willkommen in der Familie!",
  "Who can see your trip?": "Wer kann deinen Trip sehen?",
  "Who was with you on the road?": "Wer war mit dir unterwegs?",
  "Yes": "Ja",
  "You and %d others like this": "Dir und {0} anderen gefällt das",
  "You have to grant RISER access to Facebook in order to login.": "Du musst RISER Zugriff auf Facebook gewähren damit wir dich einloggen können.",
  "You like this": "Dir gefällt das",
  "Your password was changed successfully.": "Dein Passwort wurde erfolgreich geändert.",
  "hp": "PS",
  "Camera": "Kamera",
  "Gallery": "Galerie",
  "Nothing-to-show-in-your-feed-yet": "Keine Einträge in deinem Feed...",
  "Connect-with-your-friends-on-RISER-in-order-to-see": "Verbinde dich mit deinen Freunden auf RISER um zu sehen, wo sie unterwegs sind",
  "Open-one-of-your-trips-and-create-interesting-sect": "Öffne einen deiner Trips und lege ein interessantes Segment an um es für andere zum Entdecken zur Verfügung zu stellen.",
  "Send": "Senden",
  "Are-you-sure-you-want-to-delete-your-bike": "Möchtest du dein Bike löschen?",
  "Are-you-sure-you-want-to-delete-this-section": "Möchtest du das Segment löschen?",
  "Do-you-want-to-log-out": "Möchtest du dich ausloggen?",
  "Select": "Auswählen",
  "No-title": "Kein Titel",
  "%@-ride": "{0}sausfahrt",
  "Monday": "Montag",
  "Tuesday": "Dienstag",
  "Wednesday": "Mittwoch",
  "Thursday": "Donnerstag",
  "Friday": "Freitag",
  "Saturday": "Samstag",
  "Sunday": "Sonntag",
  "Share-using": "Teilen mit...",
  "Would-you-like-to-unfriend": "Freund entfernen?",
  "Accept-Friendship": "Freundschaftsanfrage akzeptieren?",
  "Offline": "Offline",
  "Sign-Up": "Registrieren",
  "You-can-add-photos-to-your-profile-and-your-trips": "Du kannst Fotos zu deinem Profil und deinen Trips hinzufügen.",
  "No-bikes-to-show": "Keine Bikes zum Anzeigen gefunden",
  "Add-your-bikes-and-they-will-appear-here-in-the-li": "Füge deine Bikes hinzu und sie werden hier in der Liste erscheinen",
  "No-friends-to-show": "Keine Freunde zum Anzeigen gefunden",
  "No-sections-to-show": "Keine Segmente zum Anzeigen gefunden",
  "Create-sections-from-within-your-trips-or-add-sect": "Erstelle Segmente aus deinen Trips oder füge Segmente von anderen Nutzern in dein Fahrtenbuch hinzu",
  "No-trips-to-show": "Keine Trips zum Anzeigen gefunden",
  "No-photos-to-show": "Keine Fotos zum Anzeigen gefunden",
  "When-you-go-for-a-ride-an-take-photos-along-the-wa": "Wenn du einen Trip machst und unterwegs Fotos aufnimmst, erscheinen sie hier in der Liste",
  "People-you-might-now": "Leute die du vielleicht kennst",
  "%d-trips": "{0} Trips",
  "Please-enter-a-Section-Title": "Bitte gib einen Titel für das Segment an",
  "This-special-part-of-your-trip": "Dieser spezielle Abschnitt von deinem Trip...",
  "Use-the-slider-to-define-the-part-of-your-trip-tha": "Benutze den Slider um die den Abschnitt deines Trips festzulegen, der dir diesen WOW-Effekt gegeben hat",
  "Enter-a-descriptive-name-for-your-section": "Gib einen sprechenden Namen für dein Segment an",
  "Section-Tags": "Segment Marker",
  "Assign-up-to-5-tags-to-your-section-which-helps-to": "Füge bis zu 5 Marker zu deinem Segment hinzu. So findest du später schneller wonach du suchst.",
  "Select-the-photos-you-want-to-share-alongside-this": "Wähle die Fotos aus, die du mit diesem Segment teilen möchtest",
  "If-you-liked-a-part-of-your-trip-in-particular-you": "Wenn dir ein Abschnitt deines Trips besonders gut gefallen hat, kannst du ein Segment daraus erstellen. Deine Segmente werden mit der Community geteilt und hilft anderen Bikern neue Strecken zu entdecken. Alle Segmente sind öffentlich sichtbar.",
  "Delete-friend-request": "Freundschaftsanfrage löschen?",
  "loading": "laden",
  "Section-Tags-min-1-max-5": "Section Marker (min 1, max 5)",
  "Please-assign-at-least-one-tag-to-your-new-section": "Bitte wähle zumindest einen Marker für dein neues Segment aus",
  "Go-for-a-ride-and-your-trips-will-appear-here": "Fahr eine Runde und zeichne die Tour mit RISER auf, dann wird sie hier aufscheinen",
  "A-part-of-your-trip-made-you-feel-%@": "Ein Teil deines Trips gab dir dieses \"{0}\"-Gefühl?",
  "WOW": "WOW",
  "Got-it": "Verstanden!",
  "Describe-your-section-a-little-bit": "Beschreibe dein Segment ein wenig...",
  "Subscribe-to-newsletter": "Zum Newsletter anmelden",
  "Delete": "Löschen",
  "Register-now": "Jetzt registrieren!",
  "Want-to-connect": "Du möchtest dich verbinden?",
  "Signing-up-is-free-easy-and-very-fast-Two-simple-s": "Die Registrierung ist kostenlos, einfach und geht schnell. Zwei einfache Schritte und du bekommst das komplette RISER-Erlebnis.",
  "Nice-bike": "Cooles Bike!",
  "Time-to-create-your-profile": "Zeit um dein Profil auszubauen!",
  "Add-your-profile-and-cover-photo-to-give-your-acco": "Füge ein Profil- und Coverfoto zu deinem Konto hinzu um einen persönlicheren Touch zu bekommen. Registriere dich, damit dich andere finden können!",
  "Awesome": "Spitze!",
  "Time-to-register": "Zeit dich zu registrieren...",
  "Share-your-trip-with-friends": "Teile deinen Trip mit Freunden!",
  "Sign-up-and-create-special-sections-organize-them": "Registriere dich und erstelle Segmente, die ganz speziellen Abschnitte deiner Trips. Organisiere sie in deinem Fahrtenbuch und teile sie mit anderen!",
  "Nice-track": "Tolle Section!",
  "Sign-up-in-order-to-load-sections-into-your-roadbo": "Registriere dich um Segmente in dein Fahrtenbuch zu laden und organisiere sie von dort aus!",
  "You-finished-a-trip": "Du hast einen Trip beendet.",
  "Start-testing": "Jetzt testen!",
  "Hide-top-speed": "Höchstgeschw. verbergen",
  "Hide-start-and-end": "Start und Ende verbergen",
  "Driving": "Fahren",
  "Auto-Pause": "Auto Pause",
  "RISER-uses-acceleration-data-to-provide-you-with-d": "RISER verwendet Beschleunigungs- und Sensordaten um dir detaillierte Statistiken deiner Trips zur Verfügung zu stellen.",
  "Create-getaway": "Getaway erstellen",
  "Journey": "Tour",
  "Cruising": "Entspannt",
  "Dynamic": "Dynamisch",
  "create": "erstellen",
  "Visibility": "Sichtbarkeit",
  "Details": "Details",
  "Conversation": "Konversation",
  "Title": "Titel",
  "Start-Time": "Startzeit",
  "End-Time": "Endzeit",
  "Meeting-Point": "Treffpunkt",
  "Getaway-Duration": "Getaway Dauer",
  "Driving-Style": "Fahrstil",
  "Short-Spin": "Spritztour",
  "Day-Trip": "Tagesausflug",
  "Sporty": "Sportlich",
  "Give-others-an-idea-of-how-long-your-getaway-shoul": "Leg eine Zeitspanne fest um anderen eine Idee zu geben wie lange der Ausflug ungefähr dauern wird.",
  "Select-a-riding-style-so-others-know-what-to-expec": "Wähle einen Fahrstil aus, um anderen eine Idee zu geben, was die Fahrweise des Getaways sein wird.",
  "Comfy-riding-speed-with-several-breaks": "Komfortabler Fahrstil mit mehreren Pausen",
  "More-swifty-driving-style-than-cruising-with-the-o": "Etwas zügigerer Fahrstil als entspannt mit gelegentlichen Pausen",
  "Sporty-driving-style-and-not-too-many-breaks": "Sportlicher Fahrstil mit nicht allzu vielen Pausen",
  "Duration": "Dauer",
  "see-all-%d-bikers": "Alle {0} Biker anzeigen",
  "Invited-%d": "Eingeladen ({0})",
  "Pick-a-date-optional": "Wähle ein Datum (optional)",
  "Enter-a-title-optional": "Gib einen Titel an (optional)",
  "Enter-a-detailed-description-optional": "Gib eine detaillierte Beschreibung an (optional)",
  "a-few-hours": "1-2 Stunden",
  "several-hours": "mehrere Stunden",
  "several-days": "mehrere Tage",
  "Search": "Suchen...",
  "Add": "Hinzufügen",
  "Close": "Schließen",
  "Search-address": "Adresse suchen...",
  "Getaway": "Getaway",
  "AnyoneGetawayExplanation": "Dein Getaway wird öffentlich für alle in der Entdecken-Ansicht und im Feed sichtbar sein. Jeder kann teilnehmen.",
  "OnlyMeGetawayExplanation": "Dein Getaway wird nur für dich und die Leute die du eingeladen hast sichtbar sein. Es wird nicht für deine Freunde in der Entdecken-Ansicht oder sonst wo angezeigt.",
  "FriendsGetawayExplanation": "Dein Getaway wird nur für Freunde in der Entdecken-Ansicht und in deren Feed aufscheinen. Nur Freunde können teilnehmen.",
  "AnyoneExplanation": "Jeder kann dieses Element sehen. Es ist für Leute sichtbar die dein Profil besuchen und kann in Suchresultaten aufscheinen.",
  "FriendsExplanation": "Nur deine Freunde auf RISER können dieses Element sehen. Es ist nur für deine Freunde sichtbar und wird in keinen Suchresultaten aufscheinen.",
  "OnlyMeExplanation": "Nur du kannst dieses Element sehen. Es wird weder in deinem Profil noch in Suchresultaten aufscheinen.",
  "Private": "Privat",
  "My-getaways": "Meine Getaways",
  "Find-getaways": "Getaways finden",
  "Getaways": "Getaways",
  "My-Roadbook": "Mein Fahrtenbuch",
  "Creating-getaway": "Getaway erstellen...",
  "Invitations-%d": "Einladungen ({0})",
  "Upcoming-%d": "Zukünftige ({0})",
  "Past-%d": "Vergangene ({0})",
  "TBA": "TBA",
  "Want-to-go-for-a-ride-with-others": "Willst du mit Freunden einen Ausflug machen?",
  "Discussion": "Unterhaltung",
  "Share": "Teilen",
  "Attending": "Du nimmst teil",
  "Edit": "Bearbeiten",
  "Participants": "Teilnehmer",
  "Remove": "Entfernen",
  "Remove-user": "User entfernen",
  "Do-you-want-to-remove-%@-from-the-getaway": "Willst du {0} von dem Getaway entfernen?",
  "Processing": "Verarbeite...",
  "Featured-%d": "Featured ({0})",
  "Browse": "Entdecken",
  "Getaways-nearby-%d": "Getaways in der Nähe ({0})",
  "Me": "Ich",
  "Participants-%d": "Teilnehmer ({0})",
  "Featured": "Featured",
  "Are-you-sure-you-want-to-delete-this-getaway": "Bist du sicher, dass du dieses Getaway löschen möchtest?",
  "Delete-Getaway": "Getaway löschen",
  "Edit-Getaway": "Getaway bearbeiten",
  "Organizer": "Organisator",
  "Participating": "Du nimmst teil",
  "You-have-an-upcoming-getaway-with-%@": "Du hast ein bevorstehendes Getaway mit {0}",
  "%@-created-a-getaway": "{0} hat ein Getaway erstellt",
  "No-messages-to-show-Be-the-first-to-start-this-con": "Keine Nachrichten zum Anzeigen. Schreib die erste Nachricht und starte die Konversation.",
  "Leave-Getaway": "Getaway verlassen",
  "Leave": "Verlassen",
  "You-created-a-getaway": "Du hast ein Getaway erstellt",
  "Want-to-invite-people-to-a-trip": "Willst du Leute zu einem Trip einladen?",
  "Create-a-getaway-set-a-date-and-meeting-point-and": "Erstelle ein Getaway, setze Startzeit und Treffpunkt und lade deine Freunde ein. Einen Gruppenausflug zu organisieren war nie einfacher!",
  "Participate": "Teilnehmen",
  "%@-invited-you-to-a-getaway": "{0} hat dich zu einem Getaway eingeladen",
  "You-are-the-organizer": "Du bist der Organisator",
  "%1@-changed-the-details-of-getaway-%2@": "{0} hat die Details von Getaway {1} geändert",
  "%1@-commented-on-getaway-%2@": "{0} hat auf Getaway {1} kommentiert",
  "%1@-changed-the-meeting-point-and-start-time-of-ge": "{0} hat Treffpunkt und Startzeit von Getaway {1} geändert",
  "%1@-will-attend-your-getaway-%2@": "{0} wird an deinem Getaway {1} teilnehmen",
  "%1@-changed-the-meeting-point-of-getaway-%2@": "{0} hat den Treffpunkt von Getaway {1} geändert",
  "%1@-changed-the-start-time-of-getaway-%2@": "{0} hat die Startzeit von Getaway {1} geändert",
  "You-are-not-participating-in-any-getaways-yet-Crea": "Noch nimmst du an keinen Getaways teil. Erstelle dein eigenes und lade Freude zu einem Motorradausflug ein!",
  "There-are-no-getaways-around-your-current-location": "Keine Getaways in deiner Nähe gefunden. Erstelle ein öffentliches Getaway und es wird für Biker in deiner Nähe angezeigt.",
  "No-getaways-to-display": "Keine Getaways zum Anzeigen gefunden",
  "Silent-here-so-far": "Noch ruhig hier, bis jetzt",
  "You-have-to-accept-our-Terms-of-Service-in-order-t": "Du musst unsere Nutzungsbedingungen akzeptieren, um die App weiter verwenden zu können.",
  "SMS-or-Email": "SMS oder E-Mail",
  "Groups": "Gruppen",
  "My-Groups": "Meine Gruppen",
  "Getaways-enable-you-to-organize-joint-trips-with-y": "Mit Getaways kannst du gemeinsame Ausfahrten mit deinen Freunden organisieren. Der Fahrstil gibt anderen Bikern eine Idee davon, was sie erwartet. Setze Startzeitpunkt und -ort fest und los gehts!",
  "Plan-joint-trips-with-your-friends": "Plane gemeinsame Ausfahrten mit deinen Freunden",
  "Worldwide": "Weltweit",
  "Closed": "Geschlossen",
  "Secret": "Geheim",
  "Public": "Öffentlich",
  "Request-pending": "Anfrage ausstehend",
  "Respond-to-invitation": "Auf Einladung antworten",
  "Group-Privacy": "Privatsphäre der Gruppe",
  "Tap-to-set-home-base": "Hier tippen um die Homebase festzulegen",
  "Request-access": "Beitrittsanfrage stellen",
  "You-are-a-member": "Du bist Mitglied",
  "Group-home-base": "Homebase der Gruppe",
  "Enter-a-short-description-for-your-group": "Gib eine kurze Beschreibung für deine Gruppe ein",
  "Group-name": "Gruppenname",
  "Create-group": "Gruppe erstellen",
  "Group-members-%d": "Gruppenmitglieder ({0})",
  "You-are-the-owner": "Du bist der Ersteller",
  "%d-members": "{0} Mitglieder",
  "Join-group": "Der Gruppe beitreten",
  "Admin": "Admin",
  "Enter-group-name": "Gruppenname eingeben",
  "%1@-accepted-the-invitation-to-the-group-%2@": "{0} hat die Einladung in die Gruppe {1} angenommen",
  "%1@-wants-to-join-the-group-%2@": "{0} möchte der Gruppe {1} beitreten",
  "%1@-invited-you-to-join-the-group-%2@": "{0} hat dich eingeladen, der Gruppe {1} beizutreten",
  "%1@-modified-the-group-%2@": "{0} hat die Gruppe {1} geändert",
  "Do-you-want-to-remove-%@-from-the-group": "Möchtest du {0} aus der Gruppe entfernen?",
  "PublicGroupExplanation": "Jeder registrierte RISER-Nutzer kann eine öffentliche Gruppe finden und ihr beitreten. Es muss keine Anfrage an den Admin gesendet werden, um einer öffentlichen Gruppe beizutreten.",
  "ClosedGroupExplanation": "Jeder RISER-Nutzer kann diese Gruppe finden. Eine Zugangsanfrage und -genehmigung durch den Admin einer geschlossenen Gruppe sind erforderlich, um beitreten zu können.",
  "SecretGroupExplanation": "Ausschließlich Mitglieder und eingeladene Nutzer können die geheime Gruppe und ihre Details finden oder einsehen. Nutzer können nur dann beitreten, wenn sie vom Admin eingeladen worden sind. Die Gruppe ist für Nicht-Gruppenmitglieder nicht sichtbar.",
  "Member-%d": "Mitglied ({0})",
  "Owner-%d": "Eigentümer ({0})",
  "Edit-group": "Gruppe bearbeiten",
  "Delete-group": "Gruppe löschen",
  "No-groups-to-display": "Keine Gruppen zur Anzeige gefunden",
  "Are-you-sure-you-want-to-delete-this-group?": "Bist du sicher, dass du diese Gruppe löschen möchtest?",
  "Are-you-sure-you-want-to-leave-this-group?": "Bist du sicher, dass du die Gruppe verlassen möchtest?",
  "Manage-requests": "Anfragen verwalten",
  "Hey-%@!": "Hallo {0}!",
  "Do-you-have-a-bike-in-your-garage?-You-can-add-it-here-to-get-detailed-statistics-on-how-much-distance-and-time-you-spend-on-it.-Other-bikers-will-be-able-to-check-it-out-when-they-visit-your-profile.": "Hast du ein Motorrad in deiner Garage? Du kannst es hier hinzufügen, um detaillierte Statistiken darüber zu erhalten, welche Entfernungen du damit zurücklegst und wie viel Zeit du darauf verbringst. Andere Biker können es sich in deinem Profil ansehen.",
  "Thank-you!": "Vielen Dank!",
  "Oh-no!": "Oh nein!",
  "Please-tell-us-what-we-can-do-better-or-what-you-are-still-missing.-Your-feedback-helps-us-to-improve-the-app-constantly.": "Bitte verrate uns, was wir besser machen können oder was dir noch fehlt. Deine Rückmeldung hilft uns dabei, die App ständig zu verbessern.",
  "Pending-Invitations": "Ausstehende Einladungen",
  "Membership-Requests": "Mitglieder-Anfragen",
  "Admin-area": "Admin-Bereich",
  "Invite": "Einladen",
  "Accept-all": "Alle annehmen",
  "Manage-members": "Mitglieder verwalten",
  "You-want-to-be-part-of-a-group?": "Möchtest du Mitglied dieser Gruppe werden?",
  "Offline-maps": "Offline-Karten",
  "Please-enter-a-group-name": "Bitte gib einen Gruppennamen an",
  "Creating-group": "Gruppe wird erstellt...",
  "This-group-name-is-already-taken,-please-choose-another-one": "Der Gruppenname wird bereits verwendet, bitte wähle einen anderen",
  "Requests": "Anfragen",
  "Members": "Mitglieder",
  "Groups-nearby-(%d)": "Gruppen in der Nähe ({0})",
  "%1@-joined-the-group-%2@": "{0} ist jetzt Mitglied der Gruppe {1}",
  "You-joined-the-group-%@": "Du bist der Gruppe {0} beigetreten",
  "Request-pending-%d": "Anfrage ausstehend ({0})",
  "Cancel-request": "Anfrage zurückziehen",
  "No": "Nein",
  "Share-trip": "Trip teilen",
  "Brand": "Hersteller",
  "Enter-missing-brand-name": "Gib den fehlenden Herstellernamen ein",
  "Enter-missing-model-name": "Gib den fehlenden Modellnamen ein",
  "Can't-find-your-brand?": "Du kannst deinen Hersteller nicht finden?",
  "Can't-find-your-model?": "Du kannst dein Modell nicht finden?",
  "Current-Location": "Aktueller Standort",
  "Route-%d": "Route {0}",
  "Enable-live-tracking-and-send-a-link": "Aktiviere LiveTracking und sende einen Link and deine Familie und Freunde damit sie dir auf der Karte folgen können.",
  "reWind": "reWind",
  "Adventure-Routing": "Adventure Routing",
  "Find-the-curviest-roads-with-the-most-beautiful-scenery": "Finde die kurvigsten Strecken mit den schönsten Landschaften und verwandle deinen Ausflug in ein Abenteuer.",
  "We-get-you-from-A-to-B": "Wir bringen dich von A nach B. Nicht so schnell, sondern so spannend wie möglich.",
  "Tracking": "Zeichne auf",
  "Navigating": "Navigiere",
  "Adventure-Navigator": "Adventure Navigator",
  "Curvy": "Kurvig",
  "Supercurvy": "Superkurvig",
  "Max-Duration": "Max. Dauer",
  "Dont-have-time?": "Keine Zeit?",
  "Live-Tracking": "LiveTracking",
  "Fastest-way": "Schnellster Weg",
  "Apply": "Übernehmen",
  "Share-rewind-link": "reWind Link teilen",
  "Download": "Herunterladen",
  "Show-rewind": "reWind zeigen",
  "Caution-when-activating-fastest-way": "Aufgepasst! Wenn du Schnellster-Weg-Navigation aktivierst, wird das Adventure Routing ausgeschaltet und es kann gut sein, dass du dann auf den langweiligsten Strecken unterwegs bist.",
  "Where-do-you-want-to-go": "Wohin möchtest du {0}?",
  "Group-names-must-be-between": "Gruppennamen müssen zwischen 2 und 500 Zeichen haben",
  "Invalid-file-type-only-jpg-png-allowed": "Ungültiges Format. Nur JPG und PNG erlaubt",
  "Select-area": "Bereich auswählen",
  "Roundtrip": "Rundfahrt",
  "We-are-sorry-we-couldnt-find-a-route-with-the-specified-settings": "Es tut uns leid, wir konnten keine Route mit den aktuellen Einstellungen finden.",
  "Route-options": "Routing Optionen",
  "Avoid-motorways": "Autobahnen meiden",
  "Try-14-days": "Teste 14 Tage",
  "then-%@-per-year": "dann {0}  \/ Jahr",
  "Yearly": "Jährlich",
  "%@-per-month": "{0} \/ Monat",
  "Monthly": "Monatlich",
  "%@-per-year": "{0} \/ Jahr",
  "save-money": "spare",
  "Get-riser": "Hol dir RISER",
  "go-back-in-time": "In der Zeit zurückgehen? Bei deinem Abenteuer mit RISER kannst du das! Schau dir dein reWind auf einer interaktiven 3D-Karte an und teile es mit deinen Freunden.",
  "No-connection-no-problem": "Keine Verbindung - kein Problem! Auf deinem Abenteuer mit RISER verlierst du mit Offline-Karten auch in den abgelegensten Gebieten nicht die Übersicht.",
  "curves-are-all-you-need": "Kurven sind alles was du brauchst? Entdecke deine perfekte Route mit RISER Adventure Routing and verwende exklusive Modi um sie zu erstellen!",
  "Adventure-navigator-pro": "Adventure Navigator PRO",
  "stay-connected-with-live-tracking": "Bleib mit Familie und Freunden verknüpft und halte sie über dein Abenteuer am Laufenden. Schicke ihnen deinen Standort mit LiveTracking!",
  "sometimes-its-not-about-where-you-go": "Manchmal geht's nicht darum, wo du hinfährst, sondern darum, wie du dort hinkommst. Verbessere dein persönliches Abenteuer mit den intelligenten RISER PRO-Funktionen.",
  "Subscription-terms": "Mitgliedschaftsbedingungen",
  "Something-went-wrong-when-verifying-your-purchase": "Bei der Verifizierung des Einkaufs ist ein Fehler aufgetreten. Bitte kontaktiere den Kundendienst.",
  "Before-you-become-a-pro": "Bevor du ein PRO wirst...",
  "Contact": "Kontaktieren",
  "Feedback": "Feedback",
  "Agree-and-sign-up": "Zustimmen und registrieren",
  "Would-you-like-to-receive-updates-via-email": "Möchtest du Updates von RISER per E-Mail erhalten?",
  "Reason": "Grund",
  "Ambassador": "Ambassador",
  "%1@-also-commented-on-trip-%2@": "{0} hat auch auf Trip {1} kommentiert",
  "%1@-also-commented-on-bike-%2@": "{0} hat auch auf Bike {1} kommentiert",
  "%1@-also-commented-on-section-%2@": "{0} hat auch auf Segment {1} kommentiert",
  "average per ride": "durchschnittlich pro Fahrt",
  "since last ride": "seit der letzten Ausfahrt",
  "Broken-URL": "Ein kaputter Link ist wie ein leerer Akku: Nervig und es dauert lang, bis er wieder funktioniert.<br><br>Aber unsere besten Developer sind schon dabei, ihn zu reparieren!",
  "and-become-part-of-the-community": "und Teil der Community werden",
  "Already-have-an-account": "Hast du schon ein Konto?",
  "Sorry-for-the-inconvenience": "Tut uns leid! Leider konnte die Zahlung nicht durchgeführt werden.",
  "Your-purchase-has-been-successfully-applied": "Dein Kauf wurde erfolgreich durchgeführt.<br>Du kannst jetzt alle PRO Funktionen nutzen.<br>Falls du Fragen haben solltest, melde dich jederzeit! Wir wünschen dir eine sichere Fahrt.<br>Dein RISER Team",
  "thank-you-name": "Danke {0} & willkommen bei RISER PRO!",
  "Please-contact-our-service-team-at": "Bitte kontaktiere unseren Kundendienst unter {0}",
  "Oops-seems-like-you-tried-to-become-more-than": "Hoppla - sieht so aus, als wolltest du noch mehr werden als nur PRO...",
  "Your-device-did-send-a-second-request": "Dein Gerät hat eine zweite Anfrage geschickt. Aber keine Sorge – wir haben die Rechnung nur ein Mal gestellt.",
  "Follow-Us": "Folge Uns",
  "Get-the-app-for-free": "Hol dir die App kostenlos",
  "Vote-for-your-preferred-route-until-february": "Mitvoten und gewinnen: Bis 18. Februar hast du die Möglichkeit, 1 von 5 RISER PRO Mitgliedschaften für ein Jahr zu gewinnen!",
  "Vote-now": "Jetzt Voten",
  "Show-all": "Alle anzeigen",
  "Live-weather": "Live Wetter",
  "days": "Tage",
  "Imprint": "Impressum",
  "Menu": "Menü",
  "Show-all-comments": "Alle Kommentare anzeigen",
  "Suggestions": "Empfehlungen",
  "Sign-up-date": "Anmeldedatum",
  "Temperature": "Temperatur",
  "Account": "Konto",
  "Celsius": "Celsius",
  "Fahrenheit": "Fahrenheit",
  "%1@-also-commented-on-getaway-%2@": "{0} hat auch auf Getaway {1} kommentiert",
  "%@-friends": "{0}s Freunde",
  "Discover-even-curvier-routes-with-the": "Entdecke noch kurvigere Routen mit dem Adventure Navigator PRO. Verliere mit Offline Maps nie dein Ziel aus den Augen. Zeichne deine Trips auf und lass' sie auf einer 3D-Karte revue passieren. Halte mit LiveTracking deine Liebsten auf dem Laufenden darüber, wo du gerade unterwegs bist.",
  "Extended-route-planning": "Erweiterte Routenplanung",
  "Watch-your-trip-once-again-on-an-interactive-3D-map-and-share-it-with-your-friends": "Lass' deine Ausfahrt nochmals auf einer interaktiven 3D-Karte revue passieren und teile sie mit deinen Freunden.",
  "Never-lose-your-way-when-theres-no-connection-even-in-the-most-remote-areas": "Verliere nie mehr die Orientierung, auch wenn du in abgelegenen Gegenden keinen Empfang hast.",
  "The-exciting-thing-about-motorcycling-is-not": "Das Spannende am Motorradfahren sind nicht (nur) die kurvigen Straßen. Eigentlich ist es der Nervenkitzel, das Unbekannte, das Abenteuer, das Freisein und das Erleben von Unerwarteten Dingen, die eine Ausfahrt besonders machen. <br>Als Motorradfahrerinnen und Motorradfahrer machen wir Touren genau deshalb gerne gemeinsam - weil einzigartige Momente erst richtig wertvoll werden, wenn wir sie mit Anderen teilen können. <br>Die Community ist das, was für uns alle zählt! <br><br>Deshalb kam uns die Idee zum RISER ADVENTURE AWARD: <br>Zum ersten Mal vergeben wir den nun jährlich stattfindenden Award an ein Abenteuer eines Users aus der RISER Community, für das aufregendste, schönste, spannendste Motorradtour 2018.<br><br>IHR BESTIMMT, WER GEWINNT! <br>Durch eure Bewertungen hat einer der Nominees die Möglichkeit, den RISER ADVENTURE AWARD 2018 zu gewinnen*. <br><br>Das Voting für den ADVENTURE AWARD läuft ab sofort bis zum 18.02.2019 um 24:00 UHR MEZ: Loggt euch ein und gebt ein Like für den Trip ab, der euch am Besten gefällt – mit ein bisschen Glück gewinnt auch ihr! <br>Unter allen RISER Usern, die am Voting teilnehmen, verlosen wir 5 x 1 Jahr RISER PRO! <br>",
  "Conditions-of-participation": "*Teilnahmebedingungen: Jeder RISER User hat so viele Likes zur Verfügung, wie er\/sie vergeben möchte. Es steht dem User frei alle nominierten Trips zu liken, oder auch nur einen. Die Vergabe der 5 x 1 Jahr RISER PRO als Gewinn für alle am Voting teilnehmenden User erfolgt durch Verlosung. Der\/die GewinnerInnen werden nach Ende des Awards und nach Veröffentlichung des Award-Gewinners per E-mail von uns benachrichtigt. Doppelter Gewinn ist ausgeschlossen, keine Barablöse möglich. Bei Fragen wendet euch bitte an support{'@'}riserapp.com",
  "What-bike-were-you-riding": "Welches Motorrad fährst du?",
  "Be-part-of-the-RISER-community": "Werde Teil der RISER Community",
  "Enter-name": "Name eingeben",
  "Could-not-load-weather": "Wetter konnte nicht geladen werden",
  "Billed-annually": "Jährliche Zahlung",
  "Starting at (price)": "Beginnend ab",
  "Free": "Kostenlos",
  "Forever": "Für immer",
  "Free_description": "Hol' dir jetzt Zugang zu den Funktionen von Basic in der RISER App und Plattform.",
  "Open-the-app-to-purchase-RISER-PRO": "Öffne die App, um auf RISER PRO upzugraden. Falls du die App nicht installiert hast, kannst du sie gratis im App Store herunterladen:",
  "registering-is-free": "die Registrierung ist 100% kostenlos",
  "Pick-a-date": "Wähle ein Datum",
  "settings-account-deletion-disclaimer": "Wenn du deinen Account löscht, werden alle deine Daten von unseren Servern gelöscht. Diese Aktion kann nicht rückgängig gemacht werden. Du kannst natürlich jederzeit einen neuen Account anlegen.",
  "Sometimes it’s not about where you go, but how you get there.": "Manchmal geht es nicht darum, wohin du unterwegs bist, sondern wie du dorthin kommst.",
  "Remember-me": "Eingeloggt bleiben",
  "Thank you for your trust!": "Vielen Dank für dein Vertrauen!",
  "You will now continue receiving our newsletter": "Du erhältst auch weiterhin unseren Newsletter mit aktuellen Informationen, News, Updates und mehr.",
  "You can always use your right to withdraw this permission": "Du kannst jederzeit und ohne Angabe von Gründen von deinem Widerrufsrecht Gebrauch machen und die erteilte Einwilligungserklärung mit Wirkung für die Zukunft abändern oder gänzlich widerrufen.",
  "Signup-&-Subscription": "Mitgliedsstatus",
  "Get-Pro": "Hol' dir PRO",
  "Are-you-sure-you-want-to-delete-your-comment?": "Bist du sicher, dass du deinen Kommentar löschen möchtest?",
  "Comments": "Kommentare",
  "Start-(footer)": "Start",
  "Because we’re all driven by adventure.": "Driven by adventure.",
  "RISER is the all-in-one-tool for motorcyclists focused on the adventure in motorcycle.": "RISER ist ein all-in-one-tool für Motorradfahrer mit einem Fokus auf das Abenteuer beim Motorradfahren. Entdecke dank des intelligenten RISER Adventure Routings spektakuläre neue Strecken in deiner Nähe, angepasst an deine persönlichen Vorlieben. Zeichne deine Fahrten auf, teile sie mit Freunden, organisiere Getaways oder verwalte Gruppen.",
  "RISER is a worldwide, daily growing community, united by the desire to be free": "RISER ist eine täglich wachsende, globale Community. Vereint durch die Sehnsucht nach Freiheit, dem Wunsch danach, neugierig zu bleiben und die tägliche Routine hinter sich zu lassen um gemeinsam einzigartige Erfahrungen zu machen.",
  "<h2>DISCOVER<\/h2> <p>your ideal trip with RISER Navigator<\/p>": "<h2>ENTDECKE<\/h2> <p>deine ideale Route<\/p>",
  "Find routes tailored to your personal preferences using RISER Adventure Routing": "Finde mit dem RISER Adventure Routing Strecken, die zu deinen persönlichen Vorlieben passend. Lass' dich von der RISER Turn-by-Turn-Navigation ans Ziel bringen und behalte dank der Live Wetteranzeige stets das Wetter in deiner Umgebung im Blick.",
  "<h2>ADVENTURES<\/h2> <p>to track and share<\/p>": "<h2>ABENTEUER<\/h2> <p>erleben und teilen<\/p>",
  "Track your motorcycle trip with GPS data, save and organize them in your personal roadbook.": "Zeichne mit GPS deine Motorradausfahrten auf und speichere  sie in deinem persönlichen Fahrtenbuch. Sieh' dir auf deinem Profil deine individuellen Statistiken an, und füge Freunde, die dich begleitet haben, deinem Trip hinzu. Über einen Link kannst du deine Abenteuer teilen.",
  "<h2>INSPIRATION<\/h2> <p>by and for you<\/p>": "<h2>INSPIRATION<\/h2> <p>von und für dich<\/p>",
  "Add your bikes to your personal garage and capture your impressions on the road": "Stelle deine Bikes in deine persönliche Garage und mache auf deinen Ausfahrten Fotos, die du in deinem Fahrtenbuch speichern kannst. Lass' dich im Newsfeed von Blogposts mit spannenden News oder Abenteuern deiner Freunde inspirieren. Im Kommentarbereich kannst du dich über Trips, Segmente und Bikes austauschen.",
  "<h2>UNIQUE MOMENTS<\/h2> <p>with others<\/p>": "<h2>EINZIGARTIGE MOMENTE<\/h2> <p>mit anderen erleben<\/p>",
  "Connect with your friends and like-minded people": "Vernetze dich mit Freunden oder Gleichgesinnten, erstelle \"Getaways\", um Ausfahrten mit deinen Freunden zu planen oder schließe dich in Gruppen zusammen.",
  "CONNECT WITH FRIENDS": "VERNETZE DICH MIT FREUNDEN",
  "CREATE OR JOIN GROUPS": "TRETE GRUPPEN BEI ODER ERSTELLE EINE EIGENE",
  "SHARE YOUR EXPERIENCE": "TEILE DEINE ERLEBNISSE",
  "<h2>GO PRO!<\/h2> <p>Individual adventures with RISER PRO<\/p>": "<h2>GO PRO!<\/h2> <p>Erlebe mit RISER PRO dein persönliches Abenteuer<\/p>",
  "RISER PRO provides new sophisticated features for you": "RISER PRO bietet dir neue, ausgeklügelte Funktionen. Finde die perfekte Reiseroute von A nach B oder eine Rundstrecke mit dem RISER Adventure Navigator PRO und den Einstellungen kurvige Route, superkurvige Route oder schnellster Weg. Teile mit LiveTracking deine Position in Echtzeit. Verliere dank offline Kartenmaterial nie die Orientierung. Willst du deine Abenteuer Revue passieren lassen? ReWind zeigt dir deine vergangenen Fahrten animiert auf einer interaktiven 3D Karte, die über einen Link mit anderen teilen kannst.",
  "Treat yourself with a 14 days free trial now!": "Gönn' dir RISER PRO jetzt 14 Tage lang kostenlos!",
  "Empower yourself to experience new adventures": "Trau' dich, neue Abenteuer zu erleben",
  "FIND YOUR IDEAL ROUTE": "FINDE DEINE IDEALE ROUTE",
  "Curvy? Super curvy? Yeah, we know you don’t like straight roads.": "Kurvig oder doch lieber superkurvig? Wir wissen, niemand mag gerade Straßen. Mit dem RISER Adventure Routing findest du deswegen immer die coolsten Strecken mit so vielen Kurven, wie du willst. Egal ob du von A nach B willst oder eine Rundreise machen möchtest: lass' dich von den kurvenreichsten Straßen der Welt inspirieren und genieße deine Fahrt mit der Turn-by-Turn-Navigation!",
  "NEVER GET LOST": "VERLIERE NIE DIE ORIENTIERUNG",
  "Be on the move and do not lose your orientation": "Verliere dank offline Kartenmaterial niemals die Orientierung, selbst wenn deine mobile Internetverbindung mal nicht verfügbar sein sollte. Dein gesamtes Kartenmaterial (dein ausgewähltes Segment) ist auch offline verfügbar, sodass du jederzeit darauf zugreifen kannst.",
  "GO BACK IN TIME": "SIEH' DIR EINEN RÜCKBLICK AN",
  "Each driven trip is unique and each route special in their own way.": "Jede Ausfahrt ist einzigartig und jede Strecke ist auf ihre Art und Weise besonders. Um Fahrten später noch einmal ansehen zu können, bietet RISER die Möglichkeit, eine interaktive 3D-Karte jedes gefahrenen Trips via Link zu teilen. Die Perspektive der interaktiven Karte kann jederzeit verändert werden, ebenfalls kann rein- oder rausgezoomt werden. Fotos und Statistiken zur Fahrt werden ebenfalls in der Karte angezeigt.",
  "SHARE YOUR POSITION": "TEILE DEINE POSITION",
  "On a longer motorcycle ride, as a RISER PRO user you can send your position": "Bei längeren Motorradausfahrten können User ihre genaue Position mit Familie und Freunden teilen. Mit der LiveTracking Funktion bietet RISER die Möglichkeit, einen Link zur exakten Position zu teilen, sodass Empfänger einen Überblick über die Reise haben oder sehen können, wo der nächste Treffpunkt sein könnte, um sich der Ausfahrt anzuschließen.",
  "Newsletter": "Newsletter",
  "The road is never straight for us": "Für uns ist immer der Weg das Ziel. Wenn du über neue Funktionen, coole Trips und Geschichten informiert bleiben willst, kannst du dich hier für unseren Newsletter anmelden.",
  "The RISER community explores beautiful routes every day": "<p>Die RISER Community erkundet jeden Tag wunderschöne Strecken und bereichert damit App und Plattform mit Inspiration und jeder Menge Routen, die wiederum zur Inspiration für andere werden.<\/p>\n\n<p>Warum gibt es also für das Entdecken toller Routen keine Auszeichnung? Richtig, wissen wir auch nicht! Deshalb haben wir den RISER Adventure Award ins Leben gerufen – eine Auszeichnung, für die ihr nur das tun müsst, was ihr am Liebsten macht: Motorradfahren!<\/p>",
  "What is the RISER Adventure Award?": "Was ist der RISER Adventure Award?",
  "From now on, RISER will award the prize annually": "<p>Ab sofort vergibt RISER die Auszeichnung jährlich – wer gewinnt, entscheidet allerdings ihr, die Community: zehn User gehen mit jeweils einem Trip um den Award ins Rennen.<\/p>\n\n<p>Die RISER Community vergibt einen Monat lang Likes an ihre Favoriten – es gilt: Wer mehr Likes hat, der gewinnt!<\/p>",
  "How do you become a nominee?": "Wie wird man Nominee?",
  "Every RISER user has the chance to be nominated through the recorded trips": "<p>Jeder RISER User hat durch die aufgezeichneten Trips Chancen darauf, nominiert zu werden. Nicht die Dauer oder Fahrgeschwindigkeit sind dafür ausschlaggebend, sondern die Frage danach, ob die Strecke auf dem Motorrad auch richtig Spaß macht. Je mehr gefahrene interessante Trips, desto höher die Chance, einen eigenen Trip unter den nominierten Routen um den Adventure Award zu finden. <\/p>\n\n<p>Die Nominees werden rechtzeitig vor Voting-Beginn vom RISER Team per E-mail (Registrierungsadresse in der APP) benachrichtigt und um ihre Zustimmung zur Teilnahme gebeten.<\/p>",
  "What's there to win?": "Was gibt es zu gewinnen?",
  "Each year different prizes are awarded for the Adventure Award winner": "<p>Jedes Jahr werden unterschiedliche Gewinne für den Adventure Award vergeben. Für den Award 2018 waren es z.B.:<\/p>\n\n<ul>\n<li>1x Lifetime RISER-Pro Mitgliedschaft<\/li>\n<li>2x RISER-Pro Mitgliedschaft für 1 Jahr für seine Freunde<\/li>\n<li>RISER Adventure Award Pokal<\/li>\n<li>Winners-Box<\/li>\n<\/ul>\n\n<p>Unter allen am Voting teilnehmenden Usern wurden außerdem 5 RISER PRO Jahresmitgliedschaften verlost.<\/p>",
  "Winner": "Gewinner",
  "Kameri Labinot on his Harley Davidson": "Kameri Labinot auf seiner Harley Davidson - Electra Glide Ultra Limited FLHTK und mit seinem Trip -HARLEY IS HOME-.",
  "Starting in Kosovo, across Albania, Montenegro, Bosnia-Herzegovina and Croatia": "Beginnend im Kosovo, quer durch Albanien, Montenegro, Bosnien-Herzegowina und Kroatien, über wunderschöne Küstenabschnitte und mit Zwischenstopps wie in der bezaubernden Altstadt von Budva (MNE) fuhr Kameri auf seiner Harley die Gewinner-Route 2018. Mit der außergewöhnlich schönen Route konnte er die meisten Likes sammeln und hat damit einen heißen Trip-Tipp für alle Biker der RISER Community geteilt. Danke dafür!",
  "Why is there no award for discovering great routes?": "Warum gibt es für das Entdecken toller Routen keine Auszeichnung?<br>\n<br>\nDachten wir uns auch.",
  "Find out more": "Erfahre mehr",
  "Become part of the community": "Werde Teil der Community",
  "Join now for free!": "Los geht's!",
  "Join the global RISER Community, get expert tips": "Werde Teil der globalen RISER Community. Lass’ dir von Experten die schönsten Strecken vorschlagen und teile deine Erlebnisse mit Gleichgesinnten.",
  "Make the most of your time and let RISER turn every motorcycle ride into an unforgettable adventure": " Nutze deine Freizeit und mach’ jede Motorradausfahrt zu einem unvergesslichen Abenteuer. Finde die perfekten Routen für deinen Fahrstil. Spare Zeit beim Planen und hab mehr Zeit zu fahren!",
  "Empowering riders": "Der Motor sind wir alle",
  "The adventure starts long before we get on our bikes": "Das Abenteuer beginnt schon bevor wir aufs Bike steigen – und endet auch lange nach dem Abstellen des Motors noch nicht. Deshalb wollen wir dir viel mehr bieten als nur die kurvigste Straße (aber natürlich auch die):",
  "Get inspired": "Lass' dich inspirieren",
  "Before the ride": "Davor",
  "Find the best routes that fit your riding style and plan your next Getaway with friends.": "Finde die besten Strecken für deinen Fahrstil und plane die nächste Ausfahrt mit Freunden.",
  "Record & explore": "Aufzeichnen & entdecken",
  "During the ride": "Während",
  "Track your rides and experience unique moments.": "Zeichne deine Fahrten auf und erlebe einzigartige Momente.",
  "Share & contribute": "Teilen & mitwirken",
  "After the ride": "Danach",
  "Share your adventures and connect with the Community.": "Teile deine Abenteuer und vernetze dich mit der Community.",
  "Sharing the same desire": "Wir teilen dieselbe Leidenschaft",
  "To make the best of the time we have on our bikes.": "Das Beste aus der Zeit zu machen, die wir am Bike verbringen.",
  "Yeah, let's get started!": "Yeah, los geht's!",
  "This keeps us going": "Was uns antreibt",
  "RISER is not just a set of functionality packed into an app": "RISER ist nicht nur eine Palette an Funktionen die in eine App gepackt wurden, sondern ein Tool durch das du und deine Freunde gemeinsam die einzigartigsten Momente erleben könnt.",
  "Before": "Davor",
  "Discover spectacular routes": "Spektakuläre Routen entdecken",
  "Plan your Getaways with friends": "Ausfahrten mit Freunden planen",
  "Connect with the community": "Mit der Community vernetzen",
  "During": "Während",
  "Discover together": "Gemeinsam entdecken",
  "Track your adventures": "Abenteuer aufzeichnen",
  "Navigate to your destination": "Ans Ziel navigieren",
  "After": "Danach",
  "Save your memories": "Erinnerungen speichern",
  "Share unique moments": "Einzigartige Momente teilen",
  "Inspire other riders": "Andere inspirieren",
  "Riders about RISER": "Andere Rider über RISER",
  "RISER is designed logically and easy to use": "RISER ist einfach und logisch zu bedienen und bietet alles was ich als sportlicher Fahrer brauche.",
  "The app is clean and super-easy to use": "Die App ist aufgeräumt und easy zu bedienen. Die funktionen klar und strukturiert. Empfehle die App jedem meiner Freunde.",
  "This app is awesome": "Ich finde RISER einfach super. Habe damit auch Strecken in meiner Nähe gefunden die ich noch gar nicht kannte.",
  "Find your personal adventure": "Finde dein persönliches Abenteuer",
  "The best routes are waiting out there and RISER will find them for you.": "Die besten Routen warten dort draußen und RISER findet sie für dich.",
  "Let's go!": "Los geht's!",
  "Become a %@": "Werde {0}",
  "Discover even curvier routes with the Adventure Navigator PRO": "Entdecke mit dem RISER Adventure Navigator PRO noch kurvigere Strecken. Verliere dank Offline Karten auch in den entlegensten Gebieten dein Ziel nicht aus den Augen. Zeichne deine Fahrten auf und lass’ sie mit reWind auf einer 3D Karte revue passieren. Teile mit LiveTracking deinen Standort mit deiner Familie oder deinen Freunden, damit sie wissen wo du bist.",
  "Safety": "Sicherheit",
  "Let others know where you are by sending them a secret link with your real time location": "Lass andere über einen geheimen Link wissen wo du gerade unterwegs bist und teile deine Position in Echtzeit.",
  "Navigation": "Navigation",
  "Extended Routing": "Erweiterte Tourenplanung",
  "Let RISER find the curviest routes for you and use the Tour Planner to plan longer adventures": "Lass' RISER für dich die kurvigsten Routen finden damit der Spaß beim Fahren noch größer ist.",
  "Relive": "Noch einmal erleben",
  "Watch your trip once again on an interactive 3D-map and share it with your friends": "Schau dir deine Ausfahrt nochmals auf einer interaktiven 3D-Karte an und teile sie mit Freunden",
  "Comfort": "Komfort",
  "Never lose your way when there’s no connection, even in the most remote areas": "Verliere nie die Orientierung, auch wenn du in den entlegensten Gegenden keinen Empfang hast",
  "Find out more about RISER PRO": "Erfahre mehr über RISER PRO",
  "RISER ambassador adventures": "RISER Ambassador Abenteuer",
  "Get inspired for your next trip!": "Hol’ dir Inspiration für deinen nächsten Trip!",
  "The country-, blues & whiskey motorcycle tour": "Die Country-, Blues & Whiskey Motorradtour. Girls just wanna have fun pt.2",
  "I don't listen to country, rarely blues - and whiskey is not necessarily the drink of my choice": "Ich höre keinen Country, selten Blues - und Whiskey ist nun auch nicht unbedingt das Getränk meiner Wahl. Aber wie hätte ich Nein zu einer Route sagen können, die genau so betitelt ist und durch das Geburtsland meiner Harley führt! Obwohl ich bis dato noch nicht den Drang hatte, Amerika zu erkunden, weckte diese Motorradroute durch die Südstaaten meine Neugier.",
  "Read more": "Weiterlesen",
  "Choose new at each crossing": "Entscheide dich an jeder Kreuzung neu",
  "It was the beginning of June 2016 when I sat on a motorcycle for the first time": "Es war Anfang Juni 2016, als ich das erste Mal auf einem Motorrad gesessen bin. Damals wusste ich nicht einmal genau wie die Gangschaltung funktioniert. Ich stand vor der schwierigen Entscheidung, ob ich mit dem Golf spielen oder lieber mit dem Motorradfahren anfangen soll. Heute bin ich überzeugt, dass ich die richtige Entscheidung getroffen habe. Mittlerweile ist es meine dritte Saison und bereue keine einzige Sekunde.",
  "Around the 'Ortler' group - seven at a stroke": "Rund um die Ortler Gruppe - sieben auf einen Streich",
  "The weather was wonderful on this Saturday": "Das Wetter war an diesem Samstag herrlich, also entschied ich mich noch am frühen Morgen spontan zu einer Ausfahrt. Zum Herbstbeginn eine „Iron Man“-Etappe – also eine anspruchsvolle und anstrengende Tagesetappe.",
  "This 'classic' round is impressive and spectacular again and again.": "(Stubai) - Brennerpass - Jaufenpass - (Passeiertal - Vinschgau) - Stilfser Joch - Gaviapass (welch rauer wunderschöner Pass !)- Tonale Pass - (Val du Nons) Mendelpass - (Bozen - Sarntal) - Penserjoch: Diese ‚Klassiker‘ Runde ist immer und immer wieder imposant und beeindruckend.",
  "Alone from Lucerne to the North Cape - 1st stage": "Alleine von Luzern ans Nordkapp - 1. Etappe",
  "Since I had obtained my motorcycle driving licence four years ago, I have been on the road a lot": "Seit ich vor vier Jahren die Motorradprüfung abgelegt hatte, war ich viel unterwegs. Hauptsächlich in den Schweizer Alpen und in Südtirol. Doch irgendwann kam dann der Wunsch nach einer größeren Tour. Doch wohin sollte es gehen? Mit längeren selbstorganisierten Touren hatte ich absolut keine Erfahrung und noch weniger Ahnung, was es dabei zu beachten gilt. Mit der Zeit reifte dann die Idee mit dem Nordkap als mein erstes großes Ziel langsam heran.",
  "Australia's Great Ocean Road off the beaten track": "Australiens Great Ocean Road abseits der Massen",
  "In the past, I've had the opportunity to be part of the guide team on a trip several times": "In der Vergangenheit hatte ich schon mehrmals die Gelegenheit, Teil des Guide Teams bei einer Ausfahrt zu sein. Diesmal hat es sich aber eher zufällig ergeben: Damien, der Organisator hatte sich kurz davor sein Handgelenk gebrochen, und mich gefragt, ob ich für ihn einspringen könnte.",
  "Highlights of our 8500 km summer tour: Italy, France, Spain, Portugal": "Highlights unserer 8500 km Sommertour: Italien, Frankreich, Spanien, Portugal",
  "As always in recent years, we have chosen a destination": "Wie immer in den letzten Jahren haben wir uns ein Ziel (Cabo de Sao Vicente in Portugal) ausgesucht und sind dann ohne genauere Planung in diese Richtung losgefahren.",
  "Be part of this adventure": "Werde Teil dieses Abenteuers",
  "Sign up now!": "Jetzt loslegen!",
  "pack ride": "PackRide",
  "Pack Ride Unlimited": "PackRide unlimitiert",
  "Upgrade-(pro)": "Upgrade",
  "Subscribed-until": "Abonniert bis",
  "Your-RISER-Team": "Dein RISER Team",
  "Something-went-wrong-with-your-payment": "Bei der Zahlung ist etwas schiefgelaufen...",
  "We-sent-a-verification-mail-to-%@": "Wir haben eine Verifizierungs-E-mail an {0} geschickt.",
  "Before we can upgrade you to RISER PRO, please make sure the e-mail address you provided is correct so we can send you receipts.": "Damit wir dein Konto auf RISER PRO umstellen und dir Rechnungen zuschicken können, überprüfe bitte noch die E-mail Adresse.",
  "Verify your email address": "Überprüfe deine E-Mail-Adresse",
  "An email has been sent to %@ before you can continue please click": "Wir haben eine E-mail an {0} gesendet. Bitte klicke zur Verifizierung auf den Link in der E-mail.",
  "Email sent": "E-mail gesendet",
  "Email verified": "E-Mail verifiziert",
  "Your email address has been verified": "Deine E-Mail-Adresse wurde verifiziert",
  "Securely pay %@": "Gesichert {0} bezahlen",
  "Continue with payment": "Mit der Bezahlung fortfahren",
  "Subtotal": "Zwischensumme",
  "Total": "Gesamt",
  "Discount": "Rabatt",
  "Support": "Support",
  "Never miss out on the best routes and news with our Newsletter": "Verpasse nie wieder die besten Routen und News mit unserem Newsletter",
  "Congrats!": "Herzlichen Glückwunsch!",
  "If you need support please get in touch with %@": "Falls du Unterstützung brauchst, melde dich unter {0}",
  "Code expired": "Code abgelaufen",
  "Sorry, too late! This voucher is no longer active.": "Sorry, zu spät! Dieser Voucher ist leider nicht mehr aktiv.",
  "Already used": "Schon verwendet",
  "You already used this voucher.": "Dieser Voucher wurde schon verwendet.",
  "Ride together, stay together": "Zusammen fahren, zusammen bleiben",
  "Enjoy the full Pack Ride experience with RISER PRO": "Genieße mit RISER PRO unlimitierte PackRide Experience: Verliere niemanden aus dem Blickfeld und erhalte oder schicke Benachrichtigungen.",
  "Get an even more sophisticated experience": "Hol dir jetzt die noch bessere Experience",
  "Get PRO now": "Jetzt PRO werden",
  "What are you waiting for?": "Worauf wartest du?",
  "come": "Los",
  "Ride With US": "Fahr' mit uns!",
  "Become part of the RISER pack. We will empower you to make more memorable riding experiences worth sharing.": "Werde Teil der Community. Mit RISER wird jeder Motorrad-Moment zu einem einzigartigen Erlebnis, das du mit anderen teilen kannst.",
  "Join the pack!": "Sei mit dabei!",
  "Never loose your group and send or receive notifications.": "Verliere deine Mitfahrerinnen und -fahrer nie aus dem Blickfeld und erhalte oder schicke Benachrichtigungen.\n(Für unlimitierte PackRide Experience - mehr als 30 Minuten am Stück - muss der\/die Organisator\/in eures PackRides RISER PRO Mitglied sein)",
  "voucher_success_message": "Du hast jetzt Zugang zu den Funktionen von RISER PRO! \n\nDein Konto wird nach dem Ablauf des angegebenen Zeitraums automatisch wieder auf Basic gesetzt.",
  "Already Pro": "Du hast schon RISER PRO",
  "voucher_error_already_pro_message": "Du hast bereits ein PRO Abo. Dieser Gutschein kann nur von RISER Basic Nutzern angewendet werden. \n\nDu kannst diesen Gutschein aber mit deinen Freunden teilen, um Ihnen PRO zu geben.",
  "current period until": "aktueller Zeitraum bis",
  "Your email address could not be verified": "Deine E-Mail-Adresse konnte nicht verifiziert werden",
  "Find out how": "Finde heraus wie",
  "Show your support for female riders!": "Zeig' deine Loyalität für Frauen am Motorrad!",
  "Show your support for female riders by doing a pack ride!": "ZEIGE DEINE UNTERSTÜTZUNG FÜR MOTORRADFAHRERINNEN, INDEM DU AN EINEM PACK RIDE TEILNIMMST! RISER schenkt Dir 2 Monate RISER PRO, um teilzunehmen.",
  "We use technical and analytics cookies to ensure that we give you the best experience on our website": "Wir verwenden technische und Analyse-Cookies, um dir die beste Erfahrung auf unserer Website zu ermöglichen.",
  "This website uses cookies": "Diese Website verwendet Cookies",
  "Continue to profile": "Weiter zum Profil",
  "Group": "Gruppe",
  "Never miss out on the hottest news & tours from your motorcycle community on RISER!": "Verpasse nie wieder die besten Routen und News mit unserem Newsletter!",
  "Email Format": "E-Mail Format",
  "Marketing Permissions": "Zustimmung zur Datenverwendung",
  "Please select all the ways you would like to hear from RISER:": "Wähle bitte alle Kanäle aus, über die RISER dich kontaktieren darf:",
  "Email - get the best stories and news": "E-Mail – wir schicken dir die besten Geschichten und spannende Neuigkeiten",
  "Customized Online Advertising - get offers tailored to your preferences": "Maßgeschneiderte Online-Werbung – erhalte Angebote, die auf deine Präferenzen zugeschnitten sind",
  "You can unsubscribe at any time by clicking the link in the footer of our emails.": "Du kannst dich jederzeit wieder abmelden, in dem du auf den Link in der Fußzeile der E-Mail klickst.",
  "We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing.": "Wir nutzen Mailchimp als unsere Marketingplattform. Mit der Anmeldung bestätigst du, dass wir deine Daten zur Weiterverarbeitung an Mailchimp weitergeben dürfen.",
  "Learn more about Mailchimp's privacy practices here.": "Hier erfährst du mehr über die Datenschutzrichtlinien von Mailchimp.",
  "Biannually": "Halbjährlich",
  "6_months_PRO_title": "6 Monate PRO",
  "Currently not on your phone? Open your RISER App now and get started!": "Nicht am Handy? Öffne jetzt deine RISER-App und leg los!",
  "Are you sure you want to leave this getaway?": "Willst du dieses Getaway wirklich verlassen?",
  "Invited": "Eingeladen",
  "The App for Motorcyclists": "Die App für Motorradfahrer",
  "Available for free": "Hol dir die App kostenlos",
  "Login with Email": "Mit E-mail einloggen",
  "Register with Email": "Mit E-mail registrieren",
  "What are you looking for?": "Wonach suchst du?",
  "We are sorry but we could not find any results for your search…": "Es tut uns leid, wir konnten keine Ergebnisse für deine Suchanfrage finden …",
  "Users": "Benutzer",
  "Enter start": "Startpunkt eingeben",
  "Enter waypoint": "Wegpunkt eingeben",
  "Enter Destination": "Ziel eingeben",
  "Sign Up or Login to join the conversation": "Registriere dich oder log dich ein um mitzureden",
  "Created": "Erstellt",
  "6_months_PRO_description": "Hol dir jetzt 6 Monate PRO und genieße alle PRO Features für ein halbes Jahr!",
  "One time payment": "Einmalige Zahlung",
  "1_year_PRO_description": "Hol dir alle PRO Features und eine noch bessere Experience!",
  "We have updated our Terms of Service": "Wir haben unsere Nutzungsbedingungen aktualisiert. Bitte lies dir die neueste Version durch.",
  "1_year_PRO_title": "1 Jahr PRO",
  "Free_title": "Basic",
  "Already verified": "Bereits verifiziert",
  "Resend verification link": "Bestätigungslink erneut senden",
  "Cancel subscription": "Abonnement beenden",
  "Subscription Period": "Abonnementzeitraum",
  "Product": "Produkt",
  "Invoice Status": "Rechnungsstatus",
  "invoice_draft": "Entwurf",
  "invoice_open": "Offen",
  "invoice_paid": "Bezahlt",
  "invoice_void": "Annulliert",
  "invoice_uncollectible": "Uneinbringlich",
  "No open payments and already PRO": "Es gibt keine offenen Zahlungen und du hast bereits alle Vorteile von RISER PRO! Vielen Dank für deine Unterstützung.",
  "Country": "Land",
  "Payment details": "Zahlungsdetails",
  "Card holder": "Karteninhaber",
  "Street address": "Straßenadresse",
  "State (address)": "Bundesland",
  "ZIP Code": "Postleitzahl",
  "Card number": "Kartennummer",
  "Secure customer authentication required before payment can be processed.": "Sichere Kunden-Authentifizierung erforderlich, bevor die Zahlung verarbeitet werden kann.",
  "Card invalid.": "Karte ungültig.",
  "Period": "Zeitraum",
  "Amount due": "Offener Betrag",
  "Remember that you have to manually cancel any mobile subscriptions.": "Denke daran, dass du Mobilabonnements im jeweiligen App Store manuell kündigen musst.",
  "To delete your account you first need to cancel your active subscription.": "Um dein Konto zu löschen musst du zuerst dein Abonnement kündigen.",
  "Invalid password reset token.": "Ungültiges Token zum Zurücksetzen des Kennworts.",
  "Send password reset email": "E-Mail zum Passwortreset anfordern",
  "No user with this email address was found.": "Es wurde kein Benutzer mit dieser E-Mail-Adresse gefunden.",
  "Type in your e-mail and we will send you a link to reset your password.": "Gib einfach deine E-Mail-Adresse ein und wir senden dir einen Link mit dem du das Passwort zurücksetzen kannst.",
  "An email has been sent to %@ please follow the instructions inside to reset your password.": "Wir haben eine E-Mail an {0} gesendet. Folge einfach den Anweisungen darin um dein Passwort zurückzusetzen.",
  "Upgrade now and become a PRO!": "Jetzt upgraden und PRO werden!",
  "Discover PRO plans": "Entdecke PRO-Pläne",
  "and more to come...": "und bald noch mehr...",
  "Subscribe now!": "Jetzt abonnieren!",
  "Austria": "Österreich",
  "Belgium": "Belgien",
  "Bulgaria": "Bulgarien",
  "Croatia": "Kroatien",
  "Cyprus": "Zypern",
  "Czech Republic": "Tschechische Republik",
  "Denmark": "Dänemark",
  "Estonia": "Estland",
  "Finland": "Finnland",
  "France": "Frankreich",
  "Germany": "Deutschland",
  "Greece": "Griechenland",
  "Hungary": "Ungarn",
  "Ireland": "Irland",
  "Italy": "Italien",
  "Latvia": "Lettland",
  "Lithuania": "Litauen",
  "Luxembourg": "Luxemburg",
  "Malta": "Malta",
  "Netherlands": "Niederlande",
  "Poland": "Polen",
  "Portugal": "Portugal",
  "Azores": "Azoren",
  "Madeira": "Madeira",
  "Mainland (Continental Portugal)": "Festland (Kontinentales Portugal)",
  "Romania": "Rumänien",
  "Slovakia": "Slowakei",
  "Slovenia": "Slowenien",
  "Spain": "Spanien",
  "Sweden": "Schweden",
  "United Kingdom": "Vereinigtes Königreich",
  "You are almost there!": "Du bist fast da!",
  "Street and number": "Straße und Hausnummer",
  "(optional) Apartment, building, floor": "(optional) Stiege, Stock, Tür",
  "Tax region": "Region",
  "Pay invoice": "Rechnung bezahlen",
  "If you have any questions, please let us know: %@": "Falls du Fragen hast, lass es uns wissen: {0}",
  "now -%@%": "jetzt -{0}%",
  "14 day free trial": "14 Tage gratis testen",
  "only for the annual plan": "nur für das Jahresabo",
  "%@-per-6-months": "{0} \/ 6 Monate",
  "Copied to Clipboard": "In die Zwischenablage kopiert",
  "Copy": "Kopieren",
  "or share on": "oder teile auf",
  "Challenges": "Challenges",
  "Your Rank": "Deine Position",
  "Your Distance": "Deine Distanz",
  "Accept the Challenge": "Challenge annehmen",
  "Leave Challenge": "Challenge verlassen",
  "Leaderboard": "Rangliste",
  "No-challenges-to-show": "Aktuell gibt es keine Challenges.",
  "We couldn't find any of your Facebook": "Finde heraus, welche Facebook-Freunde auch mit RISER unterwegs sind.",
  "Your Duration": "Deine Fahrzeit",
  "Upcoming": "Zukünftige",
  "Past": "Beendet",
  "Current": "Aktuell",
  "This-is-a-mystery-user": "Mysteriös - dieser Fahrer\/diese Fahrerin hat noch keine Beschreibung hinzugefügt.",
  "Follow": "Folgen",
  "About": "Über",
  "Planned": "Geplant",
  "Ride this now": "Jetzt fahren",
  "General": "Allgemein",
  "Short Description": "Kurzbeschreibung",
  "Social Media": "Social Media",
  "Likes": "Likes",
  "Add Posting": "Posting erstellen",
  "Post": "Post",
  "Add Photo": "Foto hinzufügen",
  "ktm-challenge-text": "Hast du deinen Kalender bei der Hand? In wenigen Tagen startet die WORLD ADVENTURE WEEK 2021. Vom 5. bis zum 11. Juli 2021 hast du Zeit mit deinem Bike in sieben Tagen 1.000 Kilometer oder mehr zu aufzuzeichnen.\n\nPowered by KTM & RISER, ist die WORLD ADVENTURE WEEK die perfekte Ausrede um deinem Motorrad ein paar extra Kilometer zu spendieren. Egal welche Marke oder welches Modell du fährst - es zählt einzig und allein das Abenteuer.\n\nDie abenteuerlichsten Rider bekommen täglich die Chance spannende Preise zu gewinnen und auf theworldadventureweek.com and KTMs Social Media Kanälen gefeatured zu werden.\n\nWährend der sieben Tage gibt es täglich eine unterschiedliche Aufgabe zu lösen. Alle Rider mit mindestens 1.000 aufgezeichneten Kilometern bekommen eine personalisierte Auszeichnung.\n\nMehr Infos in Kürze. Stay tuned!",
  "ktm-challenge-title": "7 Tage, 1000 Kilometer, 5. - 11. Juli 2021",
  "ktm-challenge-terms": "Ich stimme den <a target=\"_blank\" href=\"https:\/\/riserapp.com\/de\/waw-nutzungsbedingungen\"> Nutzungsbedingungen von RISER <\/a> und den<a target=\"_blank\" href=\"https:\/\/www.ktm.com\/en-int\/world-adventure-week\/terms---conditions.html\"> KTM Nutzungsbedingungen <\/a> der World Adventure Week zu.",
  "State\/Province": "Bundesland\/Provinz",
  "Your Score": "Deine Punkte",
  "Total Points": "Gesamte Punkte",
  "Challenge": "Challenge",
  "Your Challenge Bike": "Dein Challenge-Bike",
  "You don’t have any planned routes so far": "Du hast bisher noch keine Routen geplant. Benutze die App oder öffne routeplanner.pro auf deinem Computer, um dein nächstes Abenteuer zu planen!",
  "Are you sure you want to delete this planned route": "Möchtest du diese geplante Tour wirklich löschen?",
  "No planned Routes": "Keine Tour geplant",
  "save_your_route_to_your_roadbook": "Speichere deine Route in deinem Roadbook",
  "Name your planned route": "Wie willst du deine geplante Route nennen",
  "save_planned_route_successful": "Route erfolgreich in deinem Roadbook gespeichert.",
  "join for free": "Kostenlos anmelden",
  "Minimum 8 characters": "Mindestens 8 Zeichen",
  "Continue with Google": "Mit Google verbinden",
  "Log in to RISER": "Bei RISER einloggen",
  "Start": "Beginn",
  "Skip": "Überspringen",
  "Add your first bike to your garage": "Fügen dein erstes Motorrad zu deiner Garage hinzu",
  "Add your bike to get detailed statistics about your rides and more.": "Füge dein Motorrad hinzu, um ausführliche Statistiken über deine Fahrten und vieles mehr zu bekommen.",
  "Set your season goal": "Lege dein Saisonziel fest",
  "How far do you want to ride this season?": "Wie weit möchtest du diese Saison fahren?",
  "What are you most interested in?": "Was interessiert dich am meisten?",
  "Connect with friends": "Mit Freunden verknüpfen",
  "Find_exciting_routes": "Geniale Routen finden",
  "Share_my_rides_with_others": "Meine Ausfahrten mit anderen teilen",
  "Organize common rides": "Gemeinsame Ausfahrten organisieren",
  "Tell us something about you": "Erzähl etwas über dich",
  "Homebase": "Homebase",
  "Continue": "Fortfahren",
  "Stay_up_to_date_and_get_the_latest_news": "Bleibe auf dem Laufenden und erhalte aktuelle Nachrichten",
  "Choose Allow receive notifications and emails": "Wähle  „Zulassen“, um Benachrichtigungen über deine Fahrten und Geschichten von Freunden und der Gemeinschaft zu erhalten.",
  "Allow": "Erlauben",
  "Later": "Später",
  "number_month": "%d Monat|%d Monate",
  "Welcome to \u2028RISER,!": "Willkommen bei RISER {0}!",
  "You are now all set for your first ride": "Du bist jetzt bereit für deine erste Ausfahrt, neue Strecken zu entdecken und dich mit anderen in Challenges zu matchen. Wir wünschen dir viel Spaß!",
  "Today": "Heute",
  "Unlock and test all PRO features like": "Schalte alle PRO Funktionen wie LiveTracking, PackRide, superkurvige Strecken frei und teste sie {0} Tage kostenlos.",
  "You will receive a reminder about when your trial ends.": "Du bekommst eine Benachrichtigung wenn deine Testphase endet.",
  "You will be charged for the subscription. Cancel anytime before.": "Die Kosten für das Abonnement werden in Rechnung gestellt. Du kannst jederzeit kündigen.",
  "price year month after trial": "{0} \/ Jahr ({1} \/ Monat) nach der Testzeit",
  "Start Free Trial": "Kostenlose Testphase starten",
  "You won’t be charged until": "Kostenlos bis {0}. Jederzeit kündbar bis zu 24 Stunde vor Ende der Testphase.",
  "Unfollow": "Nicht mehr folgen",
  "Snap": "Fahrtrichtung",
  "Fullscreen": "Vollbild",
  "Trip": "Trip",
  "Rewind Speed": "reWind Geschwindigkeit",
  "Max Altitude": "Max. Höhe",
  "Open Roadbook": "Roadbook öffnen",
  "Clear all Waypoints": "Alle Wegpunkte löschen",
  "Add as start point": "Als Start hinzufügen",
  "Add as intermediate point": "Als Zwischenziel hinzufügen",
  "Add as end point": "Als Ziel hinzufügen",
  "Saving": "Speichern",
  "Direction": "Richtung",
  "North": "Norden",
  "NorthEast": "Nordost",
  "East": "Osten",
  "SouthEast": "Südost",
  "South": "Süden",
  "SouthWest": "Südwest",
  "West": "Westen",
  "NorthWest": "Nordwest",
  "Direction_Duration": "Richtung & Dauer",
  "Random": "Zufällig",
  "Done": "Fertig",
  "bio": "Bio",
  "show": "Anzeigen",
  "choose_map_type": "Kartentyp wählen",
  "Statistics": "Statistik",
  "Save Route to Your Roadbook": "Im Roadbook speichern",
  "Season goal": "Saisonziel",
  "Current period": "Aktueller Zeitraum:",
  "Edit profile": "Profil bearbeiten",
  "Download the RISER App to discover this route": "Lade dir die RISER App herunter um diese Route zu entdecken",
  "Check out the brand new": "🔥 Schau dir das brandneue Update an und hebe deine Reisen auf die nächste Stufe.",
  "Connect with friends & riders across the globe": "Verbinde dich mit Freunden & Bikern weltweit",
  "Plan your next \u2028adventure": "Plane dein nächstes Abenteuer",
  "Record your rides & share them with the community": "Zeichne deine Fahrten auf und teile sie mit der Community",
  "continue_with_apple": "Mit Apple verbinden",
  "by_adding_your_name_and_homebase_you_make_it_easier_for_your_friends_to_find_you": "Füge deine Homebase hinzu um es deinen Freunden leichter zu machen, dich zu finden.",
  "not_now": "Nicht jetzt",
  "Create posting": "Posting erstellen",
  "Post now": "Jetzt posten",
  "What do you want to share?": "Was möchtest du teilen?",
  "Distance-this-season": "Distanz deiner Saison",
  "Report posting": "Posting melden",
  "We need your help here": "Wir benötigen deine Hilfe",
  "In order to evaluate your report": "Um deine Meldung zu überprüfen, benötigen wir ein paar Informationen von dir. Bitte wähle eine Kategorie und beschreibe kurz den Inhlat. Damit können wir deine Anfrage schneller bearbeiten.",
  "Inappropriate Language": "Unangemessene Sprache",
  "Spam or Scam": "Spam oder Betrug",
  "Sexual harassment": "Sexuelle Belästigung",
  "Other": "Anderer",
  "Almost there": "Fast fertig...",
  "Please provide us with a short": "Bitte beschreibe kurz ein deinen Worten den Inhalt. Damit hilfst du uns diesen Inhalt schneller zu untersuchen.",
  "Why do you want to report this comment posting": "Warum möchtest du dieses Posting \/ diesen Kommentar melden?",
  "Thank you for reporting": "Danke für dein Feedback.",
  "Our team will look into the matter": "Unser Team wird sich so schnell wie möglich um die Angelegenheit kümmern.",
  "Elevation": "Anstieg",
  "Thank you for your feedback": "Danke für dein Feedback. Wir werden dies so schnell wie möglich bearbeiten.",
  "Report this": "Inhalt melden",
  "Delete this postings": "Posting löschen",
  "Delete this comment": "Kommentar löschen",
  "Press control to change angle": "Drücke die <strong>Steuerung-Taste & ziehe mit der Maus<\/strong> um die Ansicht zu ändern",
  "You": "Du",
  "Show more": "Alle anzeigen",
  "load more comments": "Alle Kommentare laden",
  "new bike": "Neues Bike",
  "x_comments": "{0} Kommentar|{0} Kommentare",
  "x_likes": "{0} Like|{0} Likes",
  "posting": "Posting",
  "Unlock all PRO features": "Schalte alle PRO Features frei und bringe deine nächste Tour auf ein neues Level.",
  "your special offer": "Dein spezielles Angebot {0}",
  "percent OFF forever": "Minus {0}",
  "Get PRO for __ now": "Hol dir jetzt PRO um {0}",
  "only": "nur",
  "4 Days left": "noch {0} Tage",
  "PRO membership": "PRO Mitgliedschaft",
  "Whats_included": "Was du bekommst:\nUnlimitiert PackRide, superkurvige Routen, Offline Karten, reWind, LiveTracking, GPX Export und mehr...",
  "Achievements": "Erfolge",
  "completed": "erreicht",
  "__completed": "{0} erreicht",
  "Share this": "Teilen",
  "profile": "Profil",
  "Please use the app to redeem this voucher": "Bitte verwende die App um diesen Gutschein einzulösen",
  "Title is not unique": "Titel existiert bereits",
  "error": "Fehler",
  "Scan the code to download the app": "Scanne den Code, um die App herunterzuladen.",
  "Become PRO to get the best out of RISER": "Werde PRO, um das Beste aus RISER herauszuholen.",
  "action_load": "Laden",
  "Route": "Route",
  "Plan now": "Jetzt planen",
  "Order by": "Sortieren nach",
  "Creation Date": "Erstellungsdatum",
  "Start your own group ride or join an existing one": "Starte Deine eigene Gruppenfahrt oder trete einer bestehenden bei und sehe in Echtzeit, wo sich Ihre Freunde befinden.",
  "Stop": "Stoppen",
  "Choose options": "Optionen auswählen",
  "Join": "Beitreten",
  "packrides_nearby": "Packrides in der Nähe",
  "Double click to add new destination": "<strong>Doppelklick<\/strong> zum Hinzufügen eines neuen Ziels.",
  "A simple double click adds a new destination to your route": "Ein einfacher Doppelklick fügt deiner Route ein neues Ziel hinzu.",
  "Right Click to get additional options": "<strong>Rechtsklick<\/strong> für zusätzliche Optionen.",
  "With a right click you get the option to add a new start point, destination or waypoint in between": "Mit einem Rechtsklick hast du die Möglichkeit, einen neuen Startpunkt, ein Ziel oder einen Zwischenpunkt hinzuzufügen.",
  "Drag & drop waypoints to change the order": "Die Reihenfolge der Wegpunkte kannst du mit <strong>drag & drop<\/strong> ändern.",
  "Simply drag a waypoint and drop it to easily arrange the order of waypoints to get exactly the route you desire": "Ziehe einfach einen Zwischenpunkt und lasse ihn fallen, um die Reihenfolge der Zwischenziele zu arrangieren und genau die gewünschte Route zu erhalten.",
  "Without a waypoint in between, this is too far. Please plan this route with waypoints to this destination": "Ohne einen Zwischenpunkt ist das zu weit. Bitte plane die Route mit Zwischenzielen bis zu diesem Ziel.",
  "Congratulations, %@": "Glückwunsch,\n{0} 🎉",
  "You unlocked a new achievement": "Du hast einen neuen Erfolg freigeschalten.|Du hast {0} neue Erfolge freigeschalten.",
  "achievement unlocked": "Erfolg \nfreigeschalten 🎉",
  "Get your code": "Hol deinen Code",
  "on your next cardo device": "auf dein\nnächstes\nCardo\nGerät",
  "PRO only": "PRO",
  "your_special_cardo_offer": "Dein Spezialangebot",
  "cardo_offer_code_text": "Verwende den unten stehenden Code um deinen {0}%-Rabatt auf dein nächstes Cardo Geräte einzulösen (Packtalk Pro ausgeschlossen).",
  "visit_cardo_shop": "Zum Cardo Shop",
  "Route Planner": "Routenplaner",
  "start_action": "Start",
  "update_soft_title": "Update verfügbar",
  "update_hard_title": "Du musst diese App aktualisieren",
  "update_message": "Wir haben viele Verbesserungen an unserer App vorgenommen. Bitte aktualisiere auf die neueste Version.",
  "update_now": "Jetzt aktualisieren",
  "Direct competition": "Direkte Konkurrenz",
  "Enjoying Riser": "Gefällt dir RISER, {0}?",
  "Your rating helps us grow": "Deine Bewertung hilft uns zu wachsen und unsere App zu verbessern. Zeige deine Unterstützung mit einem schnellen Tippen!",
  "Thanks for the high-five": "Danke für das High-Five, {0}! 🌟",
  "Would you mind sharing your rating": "Magst du deine Bewertung im {0} teilen? Es hilft uns wirklich weiter und ist in einem Moment erledigt!",
  "Maybe next time": "Vielleicht beim nächsten Mal",
  "Submit": "Senden",
  "Rate us in the": "Bewerte uns im {0}",
  "%s Followers": "{0} Followers",
  "%s Following": "{0} Gefolgt",
  "Followers": "Followers",
  "Following": "Gefolgt",
  "Blocked Users": "Blockierte User",
  "Follow back": "Zurückfolgen",
  "Block": "Blockieren",
  "Unblock": "Nicht mehr blockieren",
  "Answer": "Antworten",
  "XY would like to follow you": "{0} möchte dir folgen.",
  "Respond to request": "Auf Anfrage antworten.",
  "Delete follower request": "Follower-Anfrage löschen?",
  "Are you sure you want to unfollow": "Bist du sicher, dass du {0} nicht mehr folgen möchtest?",
  "Are you sure you want to block": "Bist du sicher, dass du {0} blockieren möchtest?",
  "Your follow request inbox is empty.": "Dein Posteingang für Follow-Anfragen ist leer. Beginne, dich mit anderen zu verbinden, indem du ihnen Anfragen sendest!",
  "You are not following another rider": "Du folgst noch niemanden. Tauche in die Community ein, entdecke Gleichgesinnte und verfolge ihre Reisen, um dein eigenes Abenteuer anzufeuern!",
  "It's quiet on the road right now with no followers in your rearview": "Es ist gerade ruhig auf der Straße, ohne Follower im Rückspiegel. Teile deine Motorradabenteuer und sei aktiv in der Community, um deine Reise ins Rollen zu bringen!",
  "Remove follower": "Follower entfernen",
  "Are you sure you want to remove XY": "Bist du sicher, dass du {0} als deinen Follower entfernen möchtest?",
  "We couldn't find any riders with this name": "Wir konnten keinen User mit diesem Namen finden. Vielleicht ist er noch nicht bei RISER? Nutze die Einladungsfunktion, um deine Mit-Enthusiasten in unsere Gemeinschaft zu bringen.",
  "XY sent you a follow request": "{0} hat dir eine Followeranfrage geschickt.",
  "XY accepted your follow request": "{0} hat deine Folgeanfrage angenommen.",
  "You got X new follower request": "{0} und eine weitere Person hat dir eine Follower-Anfrage geschickt.|{0} und {0} weitere Personen haben dir eine Follower-Anfrage geschickt.",
  "XY started following you": "{0} hat gerade angefangen, dir zu folgen!",
  "XY and others started following you": "{0} und jemand anderes hat gerade angefangen, dir zu folgen! 🎉|{0} und {0} andere haben gerade angefangen, dir zu folgen! 🎉",
  "Auto-Accept Followers": "Automatische Annehmen von Followern",
  "Accept new followers automatically": "Neue Follower automatisch akzeptieren. Optimiere deine Reise und erweitere deine Community mühelos!",
  "empty_text_blocked_users": "Du hast noch keine User blockiert. Deine Reise ist frei von Hindernissen! \n\nFahre fort und verbinde dich mit einer großartigen Gemeinschaft.",
  "Answer follow request": "Followeranfrage beantworten",
  "XY sent a request": "{0} hat eine Anfrage gesendet",
  "Introducing our new Follower Logic": "Wir stellen unser neues Follower-System vor",
  "Discover and connect effortlessly with our new follower system": "Entdecken und verbinde dich mühelos mit unserem neuen Follower-System. Folge deiner Leidenschaft und inspiriere andere bei jeder Fahrt.",
  "Connections preserved": "Verbunden bleiben",
  "Your existing friendships are automatically carried over": "Deine bestehenden Freundschaften werden automatisch übertragen. Keine Aktion erforderlich — nur kontinuierliche, nahtlose Verbindungen.",
  "Enable Auto-Accept": "Automatisches Annehmen aktivieren",
  "Open your journeys to more eyes": "Machen Sie Ihre Reisen für andere Fahrer zugänglich! Wählen Sie die automatische Annahme und lassen Sie Ihre Touren, Ausflüge und Fahrten ein stetig wachsendes Publikum inspirieren.",
  "Auto-accept requests": "Anfragen automatisch annehmen",
  "Manual review requests": "Anfragen überprüfen",
  "Save setting": "Einstellungen speichern",
  "Mark all read": "Alle als gelesen markieren",
  "Users on RISER can automatically follow you": "Nutzer auf RISER können dir automatisch folgen und mit dir interagieren.",
  "Only users that you confirm can follow your rides": "Nur Nutzer, die du bestätigst, können deine Fahrten verfolgen.",
  "Copy link": "Link kopieren",
  "Search results for query": "Suchergebnisse für \"{0}\"",
  "Invite Followers": "Follower einladen",
  "Report": "Melden",
  "Confirm": "Annehmen",
  "Reject": "Ablehnen",
  "block the user": "Benutzer blockieren",
  "Overwrite": "Überschreiben",
  "Set Goal": "Ziel festlegen",
  "Tell us about yourself": "Erzähl etwas über dich",
  "Instagram": "Instagram",
  "Url": "Url",
  "Youtube": "Youtube",
  "Website": "Webseite",
  "Your Climb": "Dein Anstieg",
  "Register": "Registrieren",
  "Enter your details": "Gib deine Daten ein",
  "Name": "Name",
  "Redeem this code using the app": "Lösen den Code mit der App ein",
  "Invalid Voucher": "Ungültiger Gutschein",
  "Try again": "Versuche es nochmal",
  "Voucher": "Gutschein",
  "user_state_blocked": "Blockiert",
  "Show this trip": "Diesen Trip anzeigen",
  "___Participants": "{0} Teilnehmer|{0} Teilnehmer",
  "Show all": "Alle anzeigen",
  "Joined": "Beigetreten",
  "Suggested": "Empfohlen",
  "trending-photo": "Empfohlenes Photo",
  "Bike": "Bike",
  "Share and reWind": "Teilen und reWind",
  "No comments for this trip": "Keine Kommentare für diesen Trip. Deine Chance, der Erste zu sein...",
  "No bike assigned": "Kein Bike zugewiesen",
  "ride-this-trip": "Diesen Trip fahren",
  "special_offer": "Angebot",
  "Share your profile": "Teile dein Profil",
  "pack-ride": "PackRide",
  "rewind-2": "reWind 2.0",
  "supercurvy-roads": "Superkurvige Straßen",
  "export-tours": "Touren exportieren",
  "upgrade-pro-monthly-title": "PRO monatlich",
  "upgrade-pro-yearly-title": "PRO jährlich",
  "upgrade-pro-monthly-description": "Zahle monatlich. Jederzeit kündbar",
  "upgrade-pro-yearly-description": "Abonniere jährlich und teste PRO kostenlos.",
  "upgrade-pro-yearly-discount-text": "Am beliebtesten",
  "sign-up-to-riser-pro": "Hol dir RISER PRO!",
  "redeem-promo-code": "Promo Code eingeben",
  "explore-riser-pro": "Entdecke RISER PRO",
  "Welcome to RISER Pro": " 🎉 Willkommen bei RISER PRO {0}!",
  "You have now access to all RISER features": "Du hast jetzt vollen Zugriff auf alle RISER-Funktionen ohne Einschränkungen. Und nicht nur das, mit deinem Kauf unterstützt du unsere Arbeit und die Entwicklung zukünftiger Funktionen.\n\nDanke!",
  "Latest added": "Zuletzt hinzugefügt",
  "Select Order": "Reihenfolge wählen",
  "Actions": "Aktionen",
  "Open in route planner": "Im Routenplaner öffnen",
  "Satellite": "Satellit",
  "cardo_offer_valid_until": "Der Code ist gültig für Bestellungen bis {0}.",
  "new_model": "Neues Model",
  "action_suggest_new": "Neues hinzufügen...",
  "finish-and-continue": "Klicke auf den Button unten, um dein Account-Upgrade abzuschließen, und dort weiterzumachen, wo du aufgehört hast.",
  "finish-upgrade": "Upgrade abschließen",
  "Avoid tolls": "Mautstraßen vermeiden",
  "You can edit the default name of the GPX file": "Du kannst den Namen der GPX-Datei im Textfeld vor dem Export bearbeiten.",
  "Export": "Exportieren",
  "redeem-voucher-heading": "Löse deinen Gutschein ein",
  "redeem-voucher-download-step": "Beginne damit, unsere kostenlose App herunterzuladen und ein Konto zu erstellen.",
  "redeem-voucher-action": "Gutschein einlösen",
  "redeem-voucher-redeem-step": "Klicke dann auf den Link, um deinen Gutschein in der App einzulösen.",
  "enjoy-riser-pro": "Genieße RISER Pro!",
  "voucher-invalid-get-pro-button": "Hol dir trotzdem PRO",
  "Become a RISER PRO": "Werde\nRISER\nPRO",
  "And be part of the exclusive PRO rider community.": "Und sei Teil\nder exklusiven \nPRO Community.",
  "audio_settings": "Audio Einstellungen",
  "pitch": "Tonlage",
  "voice": "Stimme",
  "reset": "Zurücksetzen",
  "test_voice": "Stimme testen",
  "voice_test_text": "Auf gehts!",
  "Request": "Anfragen",
  "no-trips-yet-text": "Es sieht so aus, als hättest du noch keine Fahrten.",
  "show-older-comments": "Ältere Kommentare anzeigen",
  "profile-image": "Profilbild",
  "cover-image": "Titelbild",
  "invited-to-join-group": "Du wurdest eingeladen, {0} beizutreten.",
  "quick-invite": "Schnelleinladung",
  "this-month": "Dieser Monat",
  "this-year": "Dieses Jahr",
  "alltime": "Insgesamt",
  "group-leaderboard-empty-text": "Es sieht so aus, als ob das Leaderboard für diesen Zeitraum leer ist. Das ist deine Chance!",
  "x-members": "{0} Mitglied|{0} Mitglieder",
  "group-join-requested": "{0} möchte der Gruppe beitreten",
  "group-description-empty-placeholder": "Diese Gruppe hat noch keine Beschreibung hinzugefügt.",
  "total-distance": "Gesamtdistanz",
  "total-duration": "Gesamtdauer",
  "total-climb": "Gesamter Anstieg",
  "create-posting-group-input-placeholder": "Was möchtest du mit deiner Gruppe teilen?",
  "posts": "Beiträge",
  "create-post": "Beitrag erstellen",
  "group-posting-empty-text": "Es sieht so aus, als gäbe es noch keine Beiträge in dieser Gruppe. Sei der Erste, der etwas postet!",
  "join-my-group-on-riser": "Tritt meiner Gruppe in RISER bei",
  "share-and-invite": "Teilen & Einladen",
  "scan-qr-to-invite": "QR scannen, um Leute einzuladen",
  "check-riser-installed": "Stelle sicher, dass RISER-App installiert ist.",
  "share-link": "Link teilen",
  "you-have-no-groups-empty-text": "Es sieht so aus, als wärst du in keiner Gruppe. Entdecke neue Gruppen oder erstelle deine eigene!",
  "create-your-own-group": "Erstelle deine eigene Gruppe",
  "join-group-to-see-more": "Tritt der Gruppe bei, um mehr zu sehen",
  "stats": "Statistiken",
  "no-results-found": "Keine Ergebnisse gefunden",
  "something-went-wrong": "Etwas ist schiefgelaufen",
  "Week": "Woche",
  "Month": "Monat",
  "Year": "Jahr",
  "All time": "Alles",
  "file-to-large-message": "Datei zu groß. Die maximale Dateigröße beträgt {0} MB.",
  "Altitude": "Seehöhe",
  "highest": "max",
  "lowest": "min",
  "above sea level": "{0} Seehöhe",
  "Week vs week before": "Wochendistanz",
  "Bikes": "Bikes",
  "Countries": "Länder",
  "Longest Ride": "Längster Trip",
  "Riders in": "Biker in {0}",
  "Month vs month before": "Monatsdistanz",
  "Trip distances": "Trip Distanzen",
  "Trips per week day": "Trips pro Tag",
  "Calendar view": "Trips pro Tag",
  "Year vs year before": "Jahresdistanz",
  "Distance per year": "Distanz pro Jahr",
  "Pack Rides": "PackRides",
  "New": "Neu",
  "received": "erhalten",
  "given": "gegeben",
  "Show all statistics": "Alle Statistiken",
  "Tools": "Tools",
  "Previous": "Vorherig",
  "auto-accept-follow-requests": "Folgeanfragen automatisch akzeptieren",
  "manage-subscription-and-billing": "Abonnement und Abrechnung verwalten",
  "new": "neu",
  "Trips per month": "Trips pro Monat",
  "Average:": "Durchschnitt:",
  "Start week on": "Wochenstart",
  "Fall": "Abstieg",
  "Yearly Statistics": "Jahresstatistik",
  "Monthly Statistics": "Monatsstatistik",
  "Strongest Month": "Stärkster Monat",
  "Strongest Day": "Stärkster Tag",
  "Edit profile photo": "Profilfoto bearbeiten",
  "Edit cover photo": "Titelbild bearbeiten",
  "People you follow": "Leuten, denen du folgst",
  "Riders in your country": "Biker in deinem Land",
  "Set homebase for leaderboard": "Homebase für Bestenliste festlegen",
  "Journal": "Journal",
  "Postings": "Postings",
  "current-week": "Aktuelle Woche",
  "week-before": "Woche davor",
  "average": "Durchschnitt",
  "All": "Alle",
  "promo-code-only-for-yearly-warning": "Der Gutscheincode wurde nicht angewendet, da er nur für das Jahresabonnement von RISER PRO gültig ist.",
  "end_navigation": "Navigation beenden",
  "Export as GPX Track": "Als GPX-Track exportieren",
  "Helmets": "Helme",
  "Intercoms": "Intercoms",
  "Intercom": "Intercom",
  "Helmet": "Helm",
  "Add to garage": "Zur Garage hinzufügen",
  "Service": "Service",
  "Next service in": "Nächster Service in…",
  "Tap to add photo": "Tippe, um Foto hinzuzufügen",
  "Helmet type": "Helmtyp",
  "Helmet details": "Helm-Details",
  "Year of purchase": "Kaufjahr",
  "Add helmet": "Helm hinzufügen",
  "Add intercom": "Intercom hinzufügen",
  "Intercom details": "Intercom-Details",
  "Show service history": "Serviceverlauf anzeigen",
  "Service history": "Serviceverlauf",
  "Show garage": "Garage anzeigen",
  "Service details": "Servicedetails",
  "Full-face": "Integralhelm",
  "Flip-up": "Klapphelm",
  "Open-face": "Jethelm",
  "Half-helmet": "Halbschalenhelm",
  "Off-road-helmet": "Offroad",
  "Adventure": "Adventure",
  "Are you sure you want to delete your helmet": "Bist du sicher, dass du deinen Helm löschen möchtest?",
  "Are you sure you want to delete your intercom": "Bist du sicher, dass du dein Intercom löschen möchtest?",
  "sold": "Verkauft",
  "Mark as sold": "Als verkauft markieren",
  "Revoke sold status": "Verkauf zurücksetzen",
  "No intercom added to your garage": "Kein Intercom zu deiner Garage hinzugefügt…",
  "No helmet added to your garage": "Kein Helm zu deiner Garage hinzugefügt…",
  "Now": "Jetzt",
  "Add some notes like \"Oil change\"": "Füge Notizen hinzu, wie \"Ölwechsel\"…",
  "Add next service": "Nächsten Service hinzufügen",
  "Add service interval": "Serviceintervall hinzufügen",
  "Remark": "Notizen",
  "No helmet added": "Kein Helm hinzugefügt",
  "No bike added": "Kein Bike hinzugefügt",
  "No intercom added": "Kein Intercom hinzugefügt",
  "Bike details": "Bike Details",
  "Enter manufacturer information": "Herstellerinformationen eingeben",
  "Enter code": "Code einlösen",
  "Enter code here": "Code hier eingeben",
  "Unlock Your Benefits": "Schalte deine Vorteile frei",
  "Enter your voucher code below to access exclusive features or discounts!": "Gib deinen Gutscheincode ein, um exklusive Funktionen oder Rabatte freizuschalten!",
  "Redeem": "Einlösen",
  "new intercom": "Neues Intercom",
  "new helmet": "Neuer Helm",
  "Log service": "Service eintragen",
  "%@ liked your helmet": "{0} gefällt dein Helm",
  "commented on your helmet": "{0} hat deinen Helm kommentiert",
  "commented on your intercom": "{0} hat dein Intercom kommentiert",
  "liked your intercom": "{0} hat dein Intercom geliked",
  "edit-helmet": "Helm bearbeiten",
  "edit-intercom": "Intercom bearbeiten",
  "service-due": "Service fällig",
  "no-services-recorded": "Noch keine Wartungen aufgezeichnet",
  "show-less": "Weniger anzeigen",
  "No bike has been added yet.": "Noch kein Bike hinzugefügt.",
  "No helmet added yet.": "Noch kein Helm hinzugefügt.",
  "No intercom added yet.": "Noch kein Intercom hinzugefügt.",
  "no-likes-message": "Noch keine Likes",
  "newsletter": "Newsletter",
  "For riders who want more": "Für Biker, die mehr wollen",
  "Get the latest motorcycle news, epic ride inspirations": "Erhalte die neuesten Motorrad-News, epische Fahrinspirationen und exklusive App-Updates – plus Sonderangebote und Deals direkt in dein Postfach!",
  "Sign me up for the news, updates, tips and special offers.": "Melde mich für Neuigkeiten, Updates, Tipps und Angebote an.",
  "Skip this step": "Überspringen",
  "Enter your email": "Gib deine E-Mail ein"
}