<template>
  <NavigationTopBar />

  <Head>
    <Title>RISER - {{ title }}</Title>
  </Head>
  <div class="wrapper">
    <IconsBattery class="error-pic inline-item" />

    <div class="explanation grey large">
      <h1 class="huge">{{ $t('Oops!') }}</h1>
      <p>
        {{ error.statusCode }}:
        <span v-if="error.explanation">{{ $t(error.explanation) }}</span>
        <span v-else v-html="$t('Broken-URL')" />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error',
  props: {
    error: {
      type: Object,
      default: () => ({
        statusCode: null,
        message: null,
        explanation: null
      })
    }
  },
  computed: {
    title() {
      return (
        this.error.statusCode +
        ': ' +
        (this.error.explanation ? this.error.explanation : 'Stuff went bananas')
      )
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/vars.sass"

.wrapper
  display: flex
  min-height: inherit
  padding: 2*$defaultMargin
  justify-content: center
  align-items: center
.explanation
  flex: 0 1 300px
  margin: 0 0 0 $defaultMargin
.error-pic
  max-width: 300px
  flex: 1 1 300px
  :deep(svg)
    height: auto
    width: auto

@media (max-width: 500px)
  .wrapper
    flex-direction: column
    text-align: center
  .explanation
    flex: 0 0 0
    margin: 0
  .error-pic
    width: 200px
    flex: 0 0 0
    align-items: stretch

.huge
  font-size: 3em
  line-height: 1

.large
  font-size: 1.2em
  font-weight: bolder

.grey
  color: $gray

.no-margin
  margin: 0
</style>
