export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"apple-mobile-web-app-title","content":"Riser"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon.ico"},{"rel":"shortcut icon","href":"/favicon.ico"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/apple-touch-icon.png"},{"rel":"manifest","href":"/manifest.json"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css?family=Heebo:400,500,700,800,900"}],"style":[],"script":[{"src":"https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"},{"src":"//connect.facebook.net/en_US/sdk.js","async":true,"defer":true,"crossorigin":"anonymous"},{"src":"//downloads.mailchimp.com/js/signup-forms/popup/unique-methods/embed.js","data-dojo-config":"usePlainJson: true, isDebug: false"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null