<script setup lang="ts">
import { AccountType } from '~/types/user'

const props = defineProps<{
  user: {
    id: number
    firstname: string
    lastname: string
    profile_photo_thumb_url?: string
    profile_photo_large_url?: string
    account_type?: AccountType
  }
  showActionButton?: boolean
}>()

const mainStore = useMainStore()
</script>

<template>
  <NuxtLink
    :to="{
      name: 'user-id',
      params: {
        id: props.user.id
      }
    }"
    :external="mainStore.isLoadedFromApp"
    class="tw-flex tw-w-full tw-items-center tw-gap-4"
  >
    <div class="tw-relative tw-flex-shrink-0">
      <RelationshipUserImage
        :user="props.user"
        class="tw-h-12 tw-w-12 tw-rounded-full tw-object-cover"
      />

      <UserTypeItem
        v-if="props.user.account_type"
        class="tw-absolute -tw-right-1 -tw-top-1 tw-h-5 tw-w-5"
        :account-type="props.user.account_type"
      />
    </div>

    <div class="tw-flex tw-w-full tw-grow tw-items-start tw-gap-4 tw-overflow-hidden">
      <span class="tw-w-full tw-truncate">
        {{ props.user.firstname }} {{ props.user.lastname }}
      </span>

      <div @click.stop.prevent class="tw-flex tw-items-center tw-gap-2 tw-whitespace-nowrap">
        <slot name="actions"></slot>
      </div>
    </div>
  </NuxtLink>
</template>
