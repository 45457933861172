import { useGarageStore } from '~/stores/garage'
type GarageStore = ReturnType<typeof useGarageStore>

export default (currentUserId: string, garageStore: GarageStore) =>
  computed(() => [
    {
      translationKey: 'My Profile',
      fontAwesomeIcon: 'user',
      route: `/user/${currentUserId}/timeline`
    },
    {
      translationKey: 'Feed',
      fontAwesomeIcon: 'fa-newspaper',
      route: `/feed`
    },
    {
      translationKey: 'Getaways',
      fontAwesomeIcon: 'fa-calendar fa-regular',
      route: `/user/${currentUserId}/getaways`
    },
    {
      translationKey: 'Groups',
      fontAwesomeIcon: 'fa-users',
      route: `/groups`
    },
    {
      translationKey: 'Garage',
      fontAwesomeIcon: 'fa-motorcycle',
      route: `/user/${currentUserId}/garage`,
      count: garageStore.bikesThatNeedService
    },
    {
      translationKey: 'Roadbook',
      fontAwesomeIcon: 'fa-map',
      route: `/user/${currentUserId}/roadbook/trips`
    },
    {
      translationKey: 'Challenges',
      fontAwesomeIcon: 'fa-trophy',
      route: `/challenges/current`
    },
    {
      translationKey: 'Achievements',
      fontAwesomeIcon: 'medal',
      route: `/user/${currentUserId}/achievements`
    },
    {
      translationKey: 'Followers',
      fontAwesomeIcon: 'user-group',
      route: `/user/${currentUserId}/followers`
    },
    {
      translationKey: 'Statistics',
      fontAwesomeIcon: 'chart-simple',
      route: `/statistics`
    }
  ])
