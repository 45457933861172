<script setup lang="ts">
import { useAuthStore } from '~/stores/auth'

const authStore = useAuthStore()

const { $UserService, $AmplitudeTrackingService, $AMPLITUDE_EVENTS } = useNuxtApp()

const state = reactive<{
  autoAcceptRequests: boolean | null
  loading: boolean
}>({
  autoAcceptRequests: true,
  loading: false
})

const emit = defineEmits(['saved'])

const saveSelection = async () => {
  if (state.autoAcceptRequests === null) {
    return
  }

  if (!authStore.currentUser) {
    return
  }

  state.loading = true

  try {
    const updatedUser = await authStore.awaitAndSetLatestUserData(
      $UserService.updateUser(authStore.currentUser.id, {
        auto_accept_follow_requests: state.autoAcceptRequests
      })
    )

    $AmplitudeTrackingService.trackEvent(
      $AMPLITUDE_EVENTS.followIntroFinish(state.autoAcceptRequests)
    )

    if (!updatedUser) {
      return
    }

    $UserService.setUser(updatedUser)

    emit('saved')
  } catch (e) {
    console.error(e)
  } finally {
    state.loading = false
  }
}

onMounted(() => {
  $AmplitudeTrackingService.trackEvent($AMPLITUDE_EVENTS.followIntroShow())
})
</script>

<template>
  <Modal force-answer max-height="700px">
    <template #body>
      <div class="tw-mt-8 tw-text-center">
        <ContentSlider
          :items="[
            { identifier: 'introducing' },
            { identifier: 'existing-connections' },
            { identifier: 'enable-auto-accept' }
          ]"
          class="tw-mb-8"
          slides-list-classes="tw-pb-3 tw-pt-2"
        >
          <template #item="{ identifier }">
            <div class="tw-mx-auto tw-flex tw-w-full tw-max-w-xs tw-flex-col tw-justify-center">
              <template v-if="identifier === 'introducing'">
                <IconsFollowerIntroIntroducing class="tw-mx-auto tw-mb-4 tw-w-24" />

                <h2 class="tw-mb-4 tw-text-xl tw-font-medium tw-leading-tight">
                  {{ $t('Introducing our new Follower Logic') }}
                </h2>

                <p class="tw-text-sm tw-leading-snug">
                  {{ $t('Discover and connect effortlessly with our new follower system') }}
                </p>
              </template>

              <template v-if="identifier === 'existing-connections'">
                <IconsFollowerIntroExistingConnections class="tw-mx-auto tw-mb-4 tw-w-24" />

                <h2 class="tw-mb-4 tw-text-xl tw-font-medium tw-leading-tight">
                  {{ $t('Connections preserved') }}
                </h2>

                <p class="tw-text-sm tw-leading-snug">
                  {{ $t('Your existing friendships are automatically carried over') }}
                </p>
              </template>

              <template v-if="identifier === 'enable-auto-accept'">
                <IconsFollowerIntroEnableAutoAccept class="tw-mx-auto tw-mb-4 tw-w-24" />

                <h2 class="tw-mb-4 tw-text-xl tw-font-medium tw-leading-tight">
                  {{ $t('Enable Auto-Accept') }}
                </h2>

                <p class="tw-text-sm tw-leading-snug">
                  {{ $t('Open your journeys to more eyes') }}
                </p>
              </template>
            </div>
          </template>
        </ContentSlider>
      </div>

      <InputSelectCore
        :model-value="state.autoAcceptRequests ? 'yes' : 'no'"
        :options-keys="['yes', 'no']"
        class="tw-mb-6 tw-flex tw-flex-col tw-gap-2"
        @update:model-value="(e) => (state.autoAcceptRequests = e === 'yes')"
      >
        <template #item="{ currentKey, isSelected, onClick }">
          <div
            class="tw-flex tw-cursor-pointer tw-flex-col tw-gap-2.5 tw-rounded-lg tw-border tw-px-4 tw-py-5 tw-transition-all tw-duration-300"
            :class="[isSelected ? 'tw-border-black' : 'tw-scale-95 tw-opacity-50']"
            @click="onClick"
          >
            <div class="tw-flex tw-items-center tw-gap-3">
              <font-awesome-icon
                :icon="currentKey === 'yes' ? 'globe' : 'lock'"
                class="tw-flex-shrink-0"
              />

              <h2 class="tw-text-lg tw-font-medium">
                {{
                  currentKey === 'yes' ? $t('Auto-accept requests') : $t('Manual review requests')
                }}
              </h2>
            </div>

            <p class="tw-text-sm tw-leading-tight">
              {{
                currentKey === 'yes'
                  ? $t('Users on RISER can automatically follow you')
                  : $t('Only users that you confirm can follow your rides')
              }}
            </p>
          </div>
        </template>
      </InputSelectCore>
    </template>

    <template #footer>
      <div class="tw-w-full tw-px-4 tw-py-2">
        <InputAppButton
          :loading="state.loading"
          :disabled="state.autoAcceptRequests === null"
          class="tw-w-full tw-rounded-xl tw-bg-black tw-py-2 tw-text-center tw-text-xl tw-text-white"
          @click="saveSelection"
        >
          {{ $t('Save setting') }}
        </InputAppButton>
      </div>
    </template>
  </Modal>
</template>
