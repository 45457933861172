declare global {
  interface Window {
    dataLayer: any
  }
}

const checkEnvFullyConfigured = () => {
  const config = useRuntimeConfig()

  return !!config.public.gtmContainerId && !!config.public.gtmAuth && !!config.public.gtmPreviewEnv
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  if (checkEnvFullyConfigured()) {
    setTimeout(() => {
      const scriptTag = document.createElement('script')

      scriptTag.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${config.public.gtmAuth}&gtm_preview=${config.public.gtmPreviewEnv}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${config.public.gtmContainerId}')
      `

      document.head.appendChild(scriptTag)

      console.log('gtm plugin initialised')
    }, 2500)
  } else {
    console.warn('GTM environment not fully set, skipping GTM initialisation')
  }

  return {
    provide: {
      trackGtmEvent: (eventName: string, payload: { [key: string]: any } = {}) => {
        if (!checkEnvFullyConfigured()) {
          console.info('GTM not initialised, skipping event ' + eventName)

          return
        }

        if (process.env.NODE_ENV !== 'production') {
          console.info('Tracking event ' + eventName, payload)
        }

        window['dataLayer'] = window['dataLayer'] ?? []

        window.dataLayer.push({
          event: eventName,
          ...payload
        })
      }
    }
  }
})
