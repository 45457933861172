import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_t2GMTTFnMT from "/opt/build/repo/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_5drvKYmdqw from "/opt/build/repo/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_store_Ty5mDaSBxR from "/opt/build/repo/plugins/01.store.js";
import _02_services_WsdXF6BMH9 from "/opt/build/repo/plugins/02.services.ts";
import _03_nativeBindings_client_noMtR8MG78 from "/opt/build/repo/plugins/03.nativeBindings.client.ts";
import appleLogin_SF51IaAvIi from "/opt/build/repo/plugins/appleLogin.js";
import carousel_client_qGB0nHi2Nh from "/opt/build/repo/plugins/carousel.client.js";
import clickOutside_C8jIyDRU4O from "/opt/build/repo/plugins/clickOutside.js";
import clipboard_un1pNMmI0D from "/opt/build/repo/plugins/clipboard.js";
import eventBus_P6CbRuQa3W from "/opt/build/repo/plugins/eventBus.ts";
import fontAwesome_ZfwYhjRo2I from "/opt/build/repo/plugins/fontAwesome.js";
import googleLogin_4zdhI5yR8F from "/opt/build/repo/plugins/googleLogin.js";
import gtm_client_OzmBuHLRIb from "/opt/build/repo/plugins/gtm.client.ts";
import i18n_sVHQBgnb3t from "/opt/build/repo/plugins/i18n.js";
import linkify_RkDyDsDIHZ from "/opt/build/repo/plugins/linkify.js";
import proFeature_xLUsJf05Gc from "/opt/build/repo/plugins/proFeature.ts";
import sentry_client_GoGQuZo4Et from "/opt/build/repo/plugins/sentry.client.js";
import sharing_ImSZkseOd9 from "/opt/build/repo/plugins/sharing.js";
import vSelect_PFhLq7v7o0 from "/opt/build/repo/plugins/vSelect.js";
import viewer_LsQIIrQcWH from "/opt/build/repo/plugins/viewer.js";
import vueLazy_OGBHnreTe5 from "/opt/build/repo/plugins/vueLazy.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_t2GMTTFnMT,
  plugin_5drvKYmdqw,
  chunk_reload_client_UciE0i6zes,
  _01_store_Ty5mDaSBxR,
  _02_services_WsdXF6BMH9,
  _03_nativeBindings_client_noMtR8MG78,
  appleLogin_SF51IaAvIi,
  carousel_client_qGB0nHi2Nh,
  clickOutside_C8jIyDRU4O,
  clipboard_un1pNMmI0D,
  eventBus_P6CbRuQa3W,
  fontAwesome_ZfwYhjRo2I,
  googleLogin_4zdhI5yR8F,
  gtm_client_OzmBuHLRIb,
  i18n_sVHQBgnb3t,
  linkify_RkDyDsDIHZ,
  proFeature_xLUsJf05Gc,
  sentry_client_GoGQuZo4Et,
  sharing_ImSZkseOd9,
  vSelect_PFhLq7v7o0,
  viewer_LsQIIrQcWH,
  vueLazy_OGBHnreTe5
]