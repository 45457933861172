<template>
  <Head>
    <Meta v-if="dev" name="robots" content="noindex" />
  </Head>

  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>

  <HideShow v-model="showErrorDialog" :timeout="3000">
    <template #content>
      <div class="error">
        {{ $t('error') }}
        :
        {{ $t(errorMessage) }}
      </div>
    </template>
  </HideShow>

  <HideShow v-model="showWarningDialog" :timeout="10000">
    <template #content>
      <div class="warning">
        {{ $t('error') }}
        :
        {{ $t(warningMessage) }}
      </div>
    </template>
  </HideShow>

  <RelationshipFollowerIntroDialog
    v-if="showFollowerIntroDialog"
    @saved="showFollowerIntroDialog = false"
  />

  <InteractionsLikeListModal
    v-if="
      mainStore.likeListModal.isVisible &&
      mainStore.likeListModal.objectId &&
      mainStore.likeListModal.objectType
    "
    :object-id="mainStore.likeListModal.objectId"
    :object-type="mainStore.likeListModal.objectType"
    @close="mainStore.hideLikeListModal()"
  />

  <CookieControl v-if="!isLoadedFromApp && !rewind" :locale="locale" />
</template>

<script setup>
import { retryRequest } from '~/data/api'
import { setErrorHandler } from '~/services/responseHandler'

const authStore = useAuthStore()
const mainStore = useMainStore()
const garageStore = useGarageStore()

const route = useRoute()

const { $globalListen, $i18n, $UserService, $AmplitudeTrackingService } = useNuxtApp()
const config = useRuntimeConfig()

const appStoreMetaTagContent = computed(() => {
  return `app-id=${config.public.appleStoreAppId}, app-argument=${route.fullPath}`
})

useHead({
  title: 'RISER',

  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    {
      name: 'description',
      content:
        'RISER is a motorcycle platform built by motorcycle enthusiasts. It lets you track your motorcycle trips and share them with your friends and the community.'
    },
    { name: 'apple-itunes-app', content: appStoreMetaTagContent }
  ]
})

const showLikeListModal = ref(false)
const showFollowerIntroDialog = ref(false)
const showErrorDialog = ref(false)
const showWarningDialog = ref(false)
const warningMessage = ref('false')
const errorMessage = ref('')
const dev = config.public.dev
const isLoadedFromApp = mainStore.isLoadedFromApp
const locale = $i18n?.global?.locale?.value?.substring(0, 2) ?? 'en'

$globalListen('pro-feature', (feature) => {
  let url = '/upgrade'

  if (feature) {
    url += '?feature=' + feature
  }

  window.open(url, '_blank')
})

$globalListen('error', (message) => {
  errorMessage.value = message
  showErrorDialog.value = true
})

$globalListen('error', (message) => {
  errorMessage.value = message
  showErrorDialog.value = true
})

$globalListen('no-route-found', () => {
  warningMessage.value = $i18n.global.t(
    'Without a waypoint in between, this is too far. Please plan this route with waypoints to this destination.'
  )
  showWarningDialog.value = true
})

const rewind = computed(() => {
  const { path } = useRoute()
  return path.includes('rewind')
})

// refreshToken Logic
setErrorHandler(401, async (error) => {
  const originalRequest = error.config
  const { $AuthenticationService } = useNuxtApp()
  originalRequest._retry = true
  try {
    const userData = await $AuthenticationService.refreshSession()

    await authStore.loginSuccess({ userData })
  } catch (err) {
    await navigateTo('/logout')
  }
  return await retryRequest(originalRequest)
})

// 500 and 409 global
setErrorHandler(409, async (error) => {
  errorMessage.value = $i18n.global.t('Title is not unique')
  showErrorDialog.value = true
  return await Promise.reject(error)
})

setErrorHandler(500, async (error) => {
  errorMessage.value = $i18n.global.t(
    'An unknown error occured. We are sorry for the inconvenience.'
  )
  showErrorDialog.value = true
  return await Promise.reject(error)
})

// Set the units from either localstorage or from query parameters
// TODO move this into user service?
if (typeof window !== 'undefined') {
  const settings = authStore.currentUser?.id
    ? $UserService.getUserSettingsFromCache(authStore.currentUser.id)
    : {}

  mainStore.updateSettings(settings)

  const distance = route.query.unit ? route.query.unit : 'metric'

  if (route.query.unit) {
    mainStore.updateSettings({ distance })
  }
}

useLatestUserDataOnce(async (userData) => {
  if (userData.auto_accept_follow_requests === null) {
    setTimeout(() => {
      showFollowerIntroDialog.value = true
    }, 5000)
  }

  // Get all bikes of the user so we can display whether one of them needs servicing
  const bikes = await $UserService.getUserBikes(userData.id)

  if (bikes?.length > 0) {
    bikes.forEach((bike) => {
      garageStore.currentUserBikes.push(bike)
    })
  }
})

watchEffect(
  () => authStore.latestUserData,
  (userData) => {
    if (userData) {
      $AmplitudeTrackingService.setUser(userData)
    } else {
      $AmplitudeTrackingService.unsetUser()
    }
  }
)

if (authStore?.session_data?.user?.id) {
  authStore.awaitAndSetLatestUserData($UserService.getUser(authStore.session_data.user.id))
}

watchEffect(
  () => authStore?.session_data?.user?.id,
  () => {
    if (!authStore?.session_data?.user?.id) return

    authStore.awaitAndSetLatestUserData($UserService.getUser(authStore.session_data.user.id))
  }
)

/* 
  Be aware that this event handler is not removed when the component is destroyed
  But since this is the main component it should never be unmounted
*/
onMounted(() => {
  window.addEventListener('message', (event) => {
    if (event.data === 'subscribed') {
      // Child payment tab sends a message when the user has subscribed

      window.location.reload()
    }
  })
})
</script>

<style lang="sass" scoped>
@import "@/assets/vars.sass"

.error
  position: fixed
  top: 100px
  left: 50%
  transform: translateX(-50%)
  background: $red
  padding: 10px
  border-radius: 15px
  color: white
  font-weight: bold
  z-index: 99999

.warning
  position: fixed
  top: 100px
  left: 50%
  transform: translateX(-50%)
  background: $orange
  padding: 10px
  border-radius: 15px
  color: white
  font-weight: bold
  z-index: 99999
</style>
