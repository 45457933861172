<template>
  <img :src="imageUrl" loading="lazy" />
</template>

<script setup lang="ts">
const props = defineProps<{
  user?: {
    profile_photo_thumb_url?: string
    profile_photo_large_url?: string
  }
  thumb?: boolean
}>()

const imageUrl = computed(() => {
  if (!props.user) {
    return '/placeholder/profile_photo.svg'
  }

  if (props.thumb) {
    return (
      props.user.profile_photo_thumb_url ??
      props.user.profile_photo_large_url ??
      '/placeholder/profile_photo.svg'
    )
  } else {
    return (
      props.user.profile_photo_large_url ??
      props.user.profile_photo_thumb_url ??
      '/placeholder/profile_photo.svg'
    )
  }
})
</script>
