const notificationString: { [key: string]: string | undefined } = {
  FOLLOW_ACCEPTED: 'XY accepted your follow request',
  FOLLOW_REQUESTED: 'XY sent you a follow request',
  NEW_FOLLOWER: 'XY started following you',

  FRIENDSHIP_ACCEPTED: '%@ and you are now friends on RISER',
  FRIENDSHIP_REQUESTED: '%@ sent you a friend request',

  NEW_SECTION_LIKE: '%@ liked your section',
  NEW_SECTION_COMMENT: '%@ commented on your section',
  NEW_SECTION_COMMENT_REPLY: '%1@-also-commented-on-section-%2@',

  NEW_BIKE: '%@ added a new bike',
  NEW_BIKE_LIKE: '%@ liked your bike',
  NEW_BIKE_COMMENT: '%@ commented on your bike',
  NEW_BIKE_COMMENT_REPLY: '%1@-also-commented-on-bike-%2@',

  NEW_HELMET_LIKE: '%@ liked your helmet',
  NEW_HELMET_COMMENT: 'commented on your helmet',

  NEW_INTERCOM_LIKE: 'liked your intercom',
  NEW_INTERCOM_COMMENT: 'commented on your intercom',

  NEW_TRIP: '%@ finished a trip',
  NEW_TRIP_LIKE: '%@ liked your trip',
  NEW_TRIP_COMMENT: '%@ commented on your trip',
  NEW_TRIP_COMMENT_REPLY: '%1@-also-commented-on-trip-%2@',

  NEW_GETAWAY_COMMENT: '%1@-commented-on-getaway-%2@',
  NEW_GETAWAY_COMMENT_REPLY: '%1@-also-commented-on-getaway-%2@',
  NEW_GETAWAY: '%@-created-a-getaway',
  GETAWAY_INVITATION_INVITED: '%@-invited-you-to-a-getaway',
  GETAWAY_INVITATION_ACCEPTED: '%1@-will-attend-your-getaway-%2@',
  GETAWAY_REMINDER: 'You-have-an-upcoming-getaway-with-%@',
  MOD_GETAWAY_MEETING_POINT: '%1@-changed-the-meeting-point-of-getaway-%2@',
  MOD_GETAWAY_START_DATE: '%1@-changed-the-start-time-of-getaway-%2@',
  MOD_GETAWAY_MEETING_POINT_START_DATE: '%1@-changed-the-meeting-point-and-start-time-of-ge',

  GROUP_REQUEST: '%1@-wants-to-join-the-group-%2@',
  GROUP_INVITE: '%1@-invited-you-to-join-the-group-%2@',
  MOD_GROUP: '%1@-modified-the-group-%2@',
  GROUP_INVITE_ACCEPTED: '%1@-accepted-the-invitation-to-the-group-%2@',
  GROUP_REQUEST_ACCEPTED: '%@ accepted your request to the group %@',
  GROUP_YOU_JOINED: 'You-joined-the-group-%@', // Special: Doesn't need a username
  GROUP_USER_JOINED: '%1@-joined-the-group-%2@'
}

export default notificationString
