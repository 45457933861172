<template>
  <div class="modal-mask" @click.self="close(true)">
    <div
      data-testid="modal"
      class="modal-container card"
      :class="[didAppear ? 'visible' : '']"
      :style="{
        maxWidth: maxWidth,
        marginTop: '4rem',
        maxHeight: `min(${maxHeight}, calc(100vh - 8rem))`
      }"
    >
      <!-- hide header completely if nothing is supplied to the header slot and the close button is hidden -->
      <div
        v-if="(!forceAnswer || !!$slots['header']) && !hideHeader"
        class="modal-header tw-z-10 tw-px-4 tw-py-4 tw-shadow"
      >
        <div class="modal-title">
          <slot name="header" />
        </div>

        <div
          v-if="!forceAnswer"
          @click="close()"
          class="tw-flex tw-h-5 tw-w-5 tw-cursor-pointer tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-full tw-bg-black/40 tw-text-xs tw-text-white"
          data-testid="modal-close-button"
        >
          <font-awesome-icon icon="xmark" />
        </div>
      </div>

      <div class="modal-body tw-px-4 tw-py-2" :class="[{ 'tw-pt-4': hideHeader }, bodyClasses]">
        <slot name="body" />
      </div>

      <!-- Container for buttons - any number of buttons will spread out in the footer with equal width -->
      <div class="modal-footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    forceAnswer: {
      type: Boolean,
      default: false
    },
    confirmBeforeClose: {
      type: Boolean,
      default: false
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: '450px'
    },
    maxHeight: {
      type: String,
      default: '600px'
    },
    bodyClasses: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      didAppear: ref(false)
    }
  },
  mounted() {
    const mainStore = useMainStore(this.$pinia)

    setTimeout(() => {
      this.didAppear = true
    }, 10)

    mainStore.hideScrollbar()
  },
  beforeUnmount() {
    const mainStore = useMainStore(this.$pinia)

    mainStore.unhideScrollbar()
  },
  methods: {
    close(viaOverlay = false) {
      const mainStore = useMainStore(this.$pinia)

      mainStore.unhideScrollbar()

      if (this.forceAnswer) {
        return
      } else if (viaOverlay && this.confirmBeforeClose) {
        if (confirm(this.$t('Are you sure you want to discard your changes?'))) {
          this.$emit('close')
        }
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/vars.sass"

.modal-mask
  position: fixed
  z-index: 2000
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, .5)
  backdrop-filter: blur(4px)
  transition: opacity .3s ease
  cursor: default // Reset cursor that might be used by parent component

.modal-container
  display: flex
  flex-direction: column
  opacity: 0
  overflow: hidden
  transform: translateY(20%)
  width: 100%
  margin: 0 auto 0 auto
  background-color: rgb(var(--secondary-background-color))
  position: relative
  border-radius: 10px
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33)
  transition: all .5s ease
  text-align: initial // TODO-NEXT: TEXT ALIGN CENTER CAUSED ISSUES WITH MAP AND INPUT FIELDS
  &.visible
    opacity: 1
    transform: translateY(0%)

.modal-header
  display: flex
  align-items: center
  justify-content: space-between
  border-radius: 10px 10px 0 0

.modal-body
  overflow-y: auto
  color: rgb(var(--main-text-color))

.modal-footer
  display: flex

.modal-footer>button
  margin: $padding calc($padding / 2)
  &:first-child
    margin-left: $padding
  &:last-child
    margin-right: $padding

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 *
 * For documentation refer to: https://vuejs.org/v2/guide/transitions.html
 */

// TODO-later: Consider replacing .page selectors with transition: 'modal' on "modal-pages" (see https://nuxtjs.org/api/pages-transition/), or find a better alternative.
.modal, .page
  &-enter
    opacity: 0

.modal, .page
  &-leave-active
    opacity: 0

.modal, .page
  &-enter .modal-container, &-leave-active .modal-container
    transform: scale(1.1)
</style>
