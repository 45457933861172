{
  "%@ finished a trip": "{0} finished a trip! 🏍️ Tap to view and like it!🔥",
  "Starting-Point": "Starting Point",
  "Fastest": "Direct",
  "duration": "duration",
  "Long": "Long",
  "Short": "Short",
  "Medium": "Medium",
  "Export-as-gpx": "Export as GPX",
  "Export-and-save": "Export and Save",
  "destination": "Destination",
  "Hours": "hours",
  "Hold on a second. We are calculating your route": "Hold on a second. We are calculating your route...",
  "Please-type-in-%@-to-confirm": "Please type in {0} to confirm.",
  "My Account": "My Account",
  "Go to Settings": "Go to Settings",
  "End-Date": "End Date",
  "Start-Date": "Start Date",
  "Invite-Users": "Invite Users",
  "HAVE-A-PRO-X-MAS": "HAVE A PRO X-MAS!",
  "Our-way-to-say-thank-you-get-percentage-first-year": "Our way to say thank you for being part of the RISER community.\n\nGet {0} OFF your first year RISER PRO membership now.",
  "First-year": "1st year",
  "No-commitment-cancel-at-any-time": "No commitment, cancel at any time.",
  "then": "then {0}",
  "%@ added a new bike": "{0} added a new bike",
  "%@ and you are now friends on RISER": "{0} and you are now friends on RISER",
  "%@ sent you a friend request": "{0} sent you a friend request",
  "%@ liked your section": "{0} liked your section",
  "%@ commented on your section": "{0} commented on your section",
  "%@ liked your trip": "{0} liked your trip",
  "%@ commented on your trip": "{0} commented on your trip",
  "%@ liked your bike": "{0} liked your bike",
  "%@ commented on your bike": "{0} commented on your bike",
  "%1@ and %2d others like this": "{0} and {1} others like this",
  "%1d bikers drove here %2d times": "{0} bikers rode here {1} times",
  "%@ likes this": "{0} likes this",
  "%d bhp": "{0} bhp",
  "%d friends": "{0} friends",
  "%d hour(s) ago": "{0} hour(s) ago",
  "%d minute(s) ago": "{0} minute(s) ago",
  "%d of %d": "{0} of {1}",
  "%d people like this": "{0} people like this",
  "%d second(s) ago": "{0} second(s) ago",
  "Accept": "Accept",
  "Account & Settings": "Account & Settings",
  "Add Bike": "Add bike",
  "Add comment...": "Add comment...",
  "Add friend": "Add friend",
  "Add to my Roadbook": "Add to my Roadbook",
  "An unknown error occured. We are sorry for the inconvenience.": "An unknown error occurred. We are sorry for the inconvenience.",
  "and": "and",
  "Anyone": "Anyone",
  "Are you sure you want to delete this photo?": "Are you sure you want to delete this photo?",
  "Are you sure you want to discard this trip?": "Are you sure you want to discard this trip?",
  "Are you sure you want to report this user?": "Are you sure you want to report this user?",
  "Are you sure? This action can not be undone.": "Are you sure? This action can not be undone.",
  "avg speed": "Ø Speed",
  "Basic": "Basic",
  "Build year (optional)": "Build year (optional)",
  "Build year": "Build year",
  "By signing up you accept our": "By signing up you accept our",
  "Cancel": "Cancel",
  "Caution!": "Caution!",
  "Change Password": "Change Password",
  "Check out RISER at https:\/\/riserapp.com\/": "Check out RISER at https:\/\/riserapp.com\/",
  "choose bike": "Choose bike",
  "Choose Brand": "Choose Brand",
  "Choose build year": "Choose build year",
  "Choose Model": "Choose Model",
  "City": "City",
  "Climb": "Climb",
  "Climb \/ Fall": "Climb \/ Fall",
  "Coast": "Coast",
  "Complete Signup": "Complete Signup",
  "Confirm new password": "Confirm new password",
  "Connect with Facebook": "Connect with Facebook",
  "Continue with Facebook": "Continue with Facebook",
  "Create Section": "Create Section",
  "Create special sections and share them with the community": "Create special sections and share them with the community",
  "Curves": "Curves",
  "Current Password": "Current Password",
  "Dark": "Dark",
  "Decline": "Decline",
  "Delete Account": "Delete Account",
  "Delete Bike": "Delete Bike",
  "Delete Section": "Delete Section",
  "Delete Trip": "Delete Trip",
  "Description": "Description",
  "Desert": "Desert",
  "Discover popular roads with live weather and forecast": "Discover new roads with live weather and forecast",
  "Discover": "Discover",
  "Display": "Display",
  "Distance": "Distance",
  "edit friends": "edit friends",
  "Edit Bike": "Edit Bike",
  "Edit Photo": "Edit Photo",
  "Edit Section": "Edit Section",
  "Edit Trip": "Edit Trip",
  "Email": "Email",
  "Engine Power (optional)": "Engine Power (optional)",
  "Engine Power": "Engine Power",
  "Enter current password": "Enter current password",
  "Enter new password": "Enter new password",
  "Enter your name in order to make it possible for your friends to find you...": "Enter your name in order to make it possible for your friends to find you...",
  "Error": "Error",
  "Facebook": "Facebook",
  "Feed": "Feed",
  "Finish": "Finish",
  "First Name": "First Name",
  "Forgot password?": "Forgot password?",
  "Friday Ride": "Friday Ride",
  "Friends": "Friends",
  "Garage": "Garage",
  "Go!": "Go!",
  "Heads up!": "Heads up!",
  "How did it go?": "How did it go?",
  "How was the weather?": "How was the weather?",
  "Imperial": "Imperial",
  "Info": "Info",
  "Invalid email \/ password combination": "Invalid email \/ password combination",
  "Invite friends": "Invite friends",
  "Invite your friends to join RISER": "Invite your friends to join RISER",
  "Join us at RISER": "Join us at RISER",
  "Just now": "Just now",
  "kmh": "km\/h",
  "Last Name": "Last Name",
  "Link to trip": "Link to trip",
  "Loading weather...": "Loading weather...",
  "Log out": "Log out",
  "Logging you in...": "Logging you in...",
  "Login": "Login",
  "Looking for matching sections...": "Looking for matching sections...",
  "Manufacturer": "Manufacturer",
  "Map Type": "Map Type",
  "Matching sections": "Matching Sections",
  "Max Speed": "Max Speed",
  "Membership": "Membership",
  "Message": "Message",
  "Messenger": "Messenger",
  "Metric": "Metric",
  "Miscellaneous": "Miscellaneous",
  "Model": "Model",
  "Monday Ride": "Monday Ride",
  "More": "More",
  "Mountain": "Mountain",
  "mph": "mph",
  "My Profile": "My Profile",
  "New Password": "New Password",
  "Next": "Next",
  "Nickname (optional)": "Nickname (optional)",
  "Nickname": "Nickname",
  "No Internet Connection": "No Internet Connection",
  "No matching sections found.": "No matching sections found.",
  "No note added.": "No note added.",
  "Notifications": "Notifications",
  "Offroad": "Offroad",
  "Ok": "Ok",
  "Only me": "Only me",
  "Oops!": "Oops!",
  "Open Settings": "Open Settings",
  "or": "or",
  "Passwords have to consist of min 8 and max 32 characters.": "Passwords have to consist of min 8 and max 32 characters.",
  "Password": "Password",
  "Password reset": "Password reset",
  "Pause": "Pause",
  "Pending": "Pending",
  "Photo Library": "Photo Library",
  "Photo": "Photo",
  "Photos": "Photos",
  "Photos along this section": "Photos along this section",
  "Please choose at least a brand and a model to add a bike to your collection.": "Please choose at least a brand and a model to add a bike to your collection.",
  "Please enter a valid email address": "Please enter a valid email address",
  "Please enter an email and a password": "Please enter an email and a password",
  "Please provide the email address you registered with at RISER: ": "Please provide the email address you registered with at RISER:",
  "Privacy": "Privacy",
  "Privacy Policy": "Privacy Policy",
  "Pro": "Pro",
  "Rank": "Rank",
  "Remove from roadbook": "Remove from roadbook",
  "Report User": "Report User",
  "Requested": "Requested",
  "Resume": "Resume",
  "RISER Feedback": "RISER Feedback",
  "RISER TV": "RISER TV",
  "Roadbook": "Roadbook",
  "Saturday Ride": "Saturday Ride",
  "Save": "Save",
  "Sea level (max\/min)": "Sea level (max\/min)",
  "Secret Link": "Secret Link",
  "Section Title": "Section Title",
  "Successfully added to your roadbook": "Successfully added to your roadbook",
  "Section": "Section",
  "Sections": "Sections",
  "Send Feedback": "Send Feedback",
  "Settings": "Settings",
  "Share Link": "Share Link",
  "Show Photo": "Show Photo",
  "Signing you up...": "Signing you up...",
  "Sorry, we couldn't establish a connection to the server.": "Sorry, we couldn't establish a connection to the server.",
  "Sponsored Post": "Sponsored Post",
  "Start Trip": "Start Trip",
  "Step 1\/2": "Step 1\/2",
  "Step 2\/2": "Step 2\/2",
  "Streets": "Streets",
  "Success": "Success",
  "Summary": "Summary",
  "Sunday Ride": "Sunday Ride",
  "Tell us what you think about RISER. What do you like? What can we do better? What features are you missing?": "Tell us what you think about RISER. What do you like? What can we do better? What features are you missing?",
  "Terms of Service": "Terms of Service",
  "Terrain": "Terrain",
  "The email address you entered already exists": "The email address you entered already exists",
  "The password you entered doesn't match your current password.": "The password you entered doesn't match your current password.",
  "The passwords you entered don't match.": "The passwords you entered don't match.",
  "Thursday Ride": "Thursday Ride",
  "Time": "Time",
  "Top speed": "Top speed",
  "Track your trips and organize them in your personal roadbook": "Track your trips and organize them in your personal roadbook",
  "Trips": "Trips",
  "Tuesday Ride": "Tuesday Ride",
  "Turn on to generate share link": "Turn on to generate share link",
  "Unexpected Error": "Unexpected Error",
  "Unfriend": "Unfriend",
  "Units": "Units",
  "Update": "Update",
  "Update failed": "Update failed",
  "Updating bike...": "Updating bike...",
  "Updating password...": "Updating password...",
  "Updating section...": "Updating section...",
  "Updating trip...": "Updating trip...",
  "Updating user credentials...": "Updating user credentials...",
  "Uploading bike...": "Uploading bike...",
  "Uploading section...": "Uploading section...",
  "Uploading trip...": "Uploading trip...",
  "Version": "Version",
  "Scenery": "Scenery",
  "Warning": "Warning",
  "Water": "Water",
  "Wednesday Ride": "Wednesday Ride",
  "Welcome back!": "Welcome back!",
  "Welcome to the family!": "Welcome to the family!",
  "Who can see your trip?": "Who can see your trip?",
  "Who was with you on the road?": "Who was with you on the road?",
  "Yes": "Yes",
  "You and %d others like this": "You and {0} others like this",
  "You have to grant RISER access to Facebook in order to login.": "You have to grant RISER access to Facebook in order to login.",
  "You like this": "You like this",
  "Your password was changed successfully.": "Your password was changed successfully.",
  "hp": "hp",
  "Camera": "Camera",
  "Gallery": "Gallery",
  "Nothing-to-show-in-your-feed-yet": "Nothing to show in your feed yet…",
  "Connect-with-your-friends-on-RISER-in-order-to-see": "Connect with your friends on RISER in order to see what they are up to",
  "Open-one-of-your-trips-and-create-interesting-sect": "Open one of your trips and create interesting sections for others to explore.",
  "Send": "Send",
  "Are-you-sure-you-want-to-delete-your-bike": "Are you sure you want to delete your bike?",
  "Are-you-sure-you-want-to-delete-this-section": "Are you sure you want to delete this section?",
  "Do-you-want-to-log-out": "Do you want to log out?",
  "Select": "Select",
  "No-title": "No title",
  "%@-ride": "{0} ride",
  "Monday": "Monday",
  "Tuesday": "Tuesday",
  "Wednesday": "Wednesday",
  "Thursday": "Thursday",
  "Friday": "Friday",
  "Saturday": "Saturday",
  "Sunday": "Sunday",
  "Share-using": "Share using...",
  "Would-you-like-to-unfriend": "Would you like to unfriend?",
  "Accept-Friendship": "Accept Friendship?",
  "Offline": "Offline",
  "Sign-Up": "Sign Up",
  "You-can-add-photos-to-your-profile-and-your-trips": "You can add photos to your profile and your trips.",
  "No-bikes-to-show": "No bikes to show",
  "Add-your-bikes-and-they-will-appear-here-in-the-li": "Add your bikes and they will appear here in the list",
  "No-friends-to-show": "No friends to show",
  "No-sections-to-show": "No sections to show",
  "Create-sections-from-within-your-trips-or-add-sect": "Create sections from within your trips or add sections of other users to your roadbook",
  "No-trips-to-show": "No trips to show",
  "No-photos-to-show": "No photos to show",
  "When-you-go-for-a-ride-an-take-photos-along-the-wa": "When you go for a ride an take photos along the way they will appear here",
  "People-you-might-now": "People you might know",
  "%d-trips": "{0} trips",
  "Please-enter-a-Section-Title": "Please enter a title for the section",
  "This-special-part-of-your-trip": "This special part of your trip...",
  "Use-the-slider-to-define-the-part-of-your-trip-tha": "Use the slider to define the part of your trip that made you feel \"WOW!\"",
  "Enter-a-descriptive-name-for-your-section": "Enter a descriptive name for your section",
  "Section-Tags": "Section Tags",
  "Assign-up-to-5-tags-to-your-section-which-helps-to": "Assign up to 5 tags to your section which helps you to categorize them",
  "Select-the-photos-you-want-to-share-alongside-this": "Select the photos you want to share alongside this section",
  "If-you-liked-a-part-of-your-trip-in-particular-you": "If you liked a part of your trip in particular, you can create a section from it. Your sections will be shared with the community and help other bikers to discover new roads. All sections are public.",
  "Delete-friend-request": "Delete friend request?",
  "loading": "loading",
  "Section-Tags-min-1-max-5": "Section Tags (min 1, max 5)",
  "Please-assign-at-least-one-tag-to-your-new-section": "Please assign at least one tag to your new section",
  "Go-for-a-ride-and-your-trips-will-appear-here": "Go for a ride and your trips will appear here",
  "A-part-of-your-trip-made-you-feel-%@": "A part of your trip made you feel \"{0}\"?",
  "WOW": "WOW",
  "Got-it": "Got it!",
  "Describe-your-section-a-little-bit": "Describe your section a little bit...",
  "Subscribe-to-newsletter": "Subscribe to newsletter",
  "Delete": "Delete",
  "Register-now": "Register now!",
  "Want-to-connect": "Want to connect?",
  "Signing-up-is-free-easy-and-very-fast-Two-simple-s": "Signing up is free, easy and very fast. Two simple steps and you can enjoy the full RISER experience.",
  "Nice-bike": "Nice bike!",
  "Time-to-create-your-profile": "Time to create your profile!",
  "Add-your-profile-and-cover-photo-to-give-your-acco": "Add your profile and cover photo to give your account an individual touch. Register so that other people can find you!",
  "Awesome": "Awesome!",
  "Time-to-register": "Time to register...",
  "Share-your-trip-with-friends": "Share your trip with friends!",
  "Sign-up-and-create-special-sections-organize-them": "Sign up and create special sections, organize them in your roadbook and share them with others!",
  "Nice-track": "Nice track!",
  "Sign-up-in-order-to-load-sections-into-your-roadbo": "Sign up in order to load sections into your roadbook and organize them from there!",
  "You-finished-a-trip": "You finished a trip",
  "Start-testing": "Start testing!",
  "Hide-top-speed": "Hide top speed",
  "Hide-start-and-end": "Hide start and end",
  "Driving": "Driving",
  "Auto-Pause": "Auto Pause",
  "RISER-uses-acceleration-data-to-provide-you-with-d": "RISER uses acceleration data to provide you with detailed statistics of your trips.",
  "Create-getaway": "Create Getaway",
  "Journey": "Journey",
  "Cruising": "Cruising",
  "Dynamic": "Dynamic",
  "create": "create",
  "Visibility": "Visibility",
  "Details": "Details",
  "Conversation": "Conversation",
  "Title": "Title",
  "Start-Time": "Start Time",
  "End-Time": "End Time",
  "Meeting-Point": "Meeting Point",
  "Getaway-Duration": "Getaway Duration",
  "Driving-Style": "Driving Style",
  "Short-Spin": "Short Spin",
  "Day-Trip": "Day Trip",
  "Sporty": "Sporty",
  "Give-others-an-idea-of-how-long-your-getaway-shoul": "Give others an idea of how long your getaway should approximately last.",
  "Select-a-riding-style-so-others-know-what-to-expec": "Select a riding style so others know what to expect from this getaway.",
  "Comfy-riding-speed-with-several-breaks": "Comfy riding speed with several breaks",
  "More-swifty-driving-style-than-cruising-with-the-o": "More swiftly driving style than cruising with the occasional break",
  "Sporty-driving-style-and-not-too-many-breaks": "Sporty driving style and not too many breaks",
  "Duration": "Duration",
  "see-all-%d-bikers": "see all {0} bikers",
  "Invited-%d": "Invited ({0})",
  "Pick-a-date-optional": "Pick a date (optional)",
  "Enter-a-title-optional": "Enter a title (optional)",
  "Enter-a-detailed-description-optional": "Enter a detailed description (optional)",
  "a-few-hours": "1-2 hours",
  "several-hours": "several hours",
  "several-days": "several days",
  "Search": "Search",
  "Add": "Add",
  "Close": "Close",
  "Search-address": "Search address...",
  "Getaway": "Getaway",
  "AnyoneGetawayExplanation": "Your getaway will be publicly visible to everyone in the discover area and possibly the feed of other people. Anyone can join.",
  "OnlyMeGetawayExplanation": "Your getaway will only be visible to you and the people you invited. It will not appear to your friends in the discover area or anywhere else.",
  "FriendsGetawayExplanation": "Your getaway will only appear in the discover area of your friends and in your friends' feed. Only your friends can join.",
  "AnyoneExplanation": "Everyone can see this element. It will be visible to people who visit your profile and might appear in search results.",
  "FriendsExplanation": "Only friends on RISER can see this element. It will only be visible to your friends in your profile and will not be shown in search results for other people.",
  "OnlyMeExplanation": "Only you can see this element. It will not appear on your profile and will not be shown in any search results.",
  "Private": "Private",
  "My-getaways": "My getaways",
  "Find-getaways": "Find getaways",
  "Getaways": "Getaways",
  "My-Roadbook": "My Roadbook",
  "Creating-getaway": "Creating getaway...",
  "Invitations-%d": "Invitations ({0})",
  "Upcoming-%d": "Upcoming ({0})",
  "Past-%d": "Past ({0})",
  "TBA": "TBA",
  "Want-to-go-for-a-ride-with-others": "Want to go for a ride with others?",
  "Discussion": "Discussion",
  "Share": "Share",
  "Attending": "Attending",
  "Edit": "Edit",
  "Participants": "Participants",
  "Remove": "Remove",
  "Remove-user": "Remove user",
  "Do-you-want-to-remove-%@-from-the-getaway": "Do you want to remove {0} from the getaway?",
  "Processing": "Processing...",
  "Featured-%d": "Featured ({0})",
  "Browse": "Browse",
  "Getaways-nearby-%d": "Getaways nearby ({0})",
  "Me": "Me",
  "Participants-%d": "Participants ({0})",
  "Featured": "Featured",
  "Are-you-sure-you-want-to-delete-this-getaway": "Are you sure you want to delete this getaway?",
  "Delete-Getaway": "Delete getaway",
  "Edit-Getaway": "Edit getaway",
  "Organizer": "Organizer",
  "Participating": "Participating",
  "You-have-an-upcoming-getaway-with-%@": "You have an upcoming getaway with {0}",
  "%@-created-a-getaway": "{0} created a getaway",
  "No-messages-to-show-Be-the-first-to-start-this-con": "No messages to show. Be the first to start this conversation.",
  "Leave-Getaway": "Leave Getaway",
  "Leave": "Leave",
  "You-created-a-getaway": "You created a getaway",
  "Want-to-invite-people-to-a-trip": "Want to invite people to a trip?",
  "Create-a-getaway-set-a-date-and-meeting-point-and": "Create a getaway, set a date and meeting point and invite your friends. Organizing a group ride has never been easier!",
  "Participate": "Participate",
  "%@-invited-you-to-a-getaway": "{0} invited you to a getaway",
  "You-are-the-organizer": "You are the organizer",
  "%1@-changed-the-details-of-getaway-%2@": "{0} changed the details of getaway {1}",
  "%1@-commented-on-getaway-%2@": "{0} commented on getaway {1}",
  "%1@-changed-the-meeting-point-and-start-time-of-ge": "{0} changed the meeting point and start time of getaway {1}",
  "%1@-will-attend-your-getaway-%2@": "{0} will attend your getaway {1}",
  "%1@-changed-the-meeting-point-of-getaway-%2@": "{0} changed the meeting point of getaway {1}",
  "%1@-changed-the-start-time-of-getaway-%2@": "{0} changed the start time of getaway {1}",
  "You-are-not-participating-in-any-getaways-yet-Crea": "You are not participating in any getaways yet. Create your own getaway and invite your friends to a motorcycle ride!",
  "There-are-no-getaways-around-your-current-location": "There are no getaways around your current location. Create a public getaway and it will show up for bikers nearby you.",
  "No-getaways-to-display": "No getaways to display",
  "Silent-here-so-far": "Silent here, so far",
  "You-have-to-accept-our-Terms-of-Service-in-order-t": "You have to accept our Terms of Service in order to continue using the app.",
  "SMS-or-Email": "SMS or Email",
  "Groups": "Groups",
  "My-Groups": "My Groups",
  "Getaways-enable-you-to-organize-joint-trips-with-y": "Getaways enable you to organize joint trips with your friends. Specify the driving style of a getaway to give others an idea of what they can expect, set start time and meeting point and hit the road!",
  "Plan-joint-trips-with-your-friends": "Plan joint trips with your friends",
  "Worldwide": "Worldwide",
  "Closed": "Closed",
  "Secret": "Secret",
  "Public": "Public",
  "Request-pending": "Request pending",
  "Respond-to-invitation": "Respond to invitation",
  "Group-Privacy": "Group Privacy",
  "Tap-to-set-home-base": "Tap to set home base",
  "Request-access": "Request access",
  "You-are-a-member": "You are a member",
  "Group-home-base": "Group home base",
  "Enter-a-short-description-for-your-group": "Enter a short description for your group",
  "Group-name": "Group name",
  "Create-group": "Create group",
  "Group-members-%d": "Group members ({0})",
  "You-are-the-owner": "You are the owner",
  "%d-members": "{0} members",
  "Join-group": "Join group",
  "Admin": "Admin",
  "Enter-group-name": "Enter group name",
  "%1@-accepted-the-invitation-to-the-group-%2@": "{0} accepted the invitation to the group {1}",
  "%1@-wants-to-join-the-group-%2@": "{0} wants to join the group {1}",
  "%1@-invited-you-to-join-the-group-%2@": "{0} invited you to join the group {1}",
  "%1@-modified-the-group-%2@": "{0} modified the group {1}",
  "Do-you-want-to-remove-%@-from-the-group": "Do you want to remove {0} from the group?",
  "PublicGroupExplanation": "A public group can be found and joined by any registered RISER user. No request needs to be sent to the admin to join a public group.",
  "ClosedGroupExplanation": "Every RISER user can find this group. Requesting access and approval from the admin of a closed group is necessary to join.",
  "SecretGroupExplanation": "Only members and invited users can find or see the secret group and its details. Users can only join if they were invited by the admin. The group is invisible to non-group members.",
  "Member-%d": "Member ({0})",
  "Owner-%d": "Owner ({0})",
  "Edit-group": "Edit group",
  "Delete-group": "Delete group",
  "No-groups-to-display": "No groups to display",
  "Are-you-sure-you-want-to-delete-this-group?": "Are you sure you want to delete this group?",
  "Are-you-sure-you-want-to-leave-this-group?": "Are you sure you want to leave this group?",
  "Manage-requests": "Manage requests",
  "Hey-%@!": "Hey {0}!",
  "Do-you-have-a-bike-in-your-garage?-You-can-add-it-here-to-get-detailed-statistics-on-how-much-distance-and-time-you-spend-on-it.-Other-bikers-will-be-able-to-check-it-out-when-they-visit-your-profile.": "Do you have a bike in your garage? You can add it here to get detailed statistics on how much distance and time you spend on it. Other bikers will be able to check it out when they visit your profile.",
  "Thank-you!": "Thank you!",
  "Oh-no!": "Oh no!",
  "Please-tell-us-what-we-can-do-better-or-what-you-are-still-missing.-Your-feedback-helps-us-to-improve-the-app-constantly.": "Please tell us what we can do better or what you are still missing. Your feedback helps us to improve the app constantly.",
  "Pending-Invitations": "Pending Invitations",
  "Membership-Requests": "Membership Requests",
  "Admin-area": "Admin area",
  "Invite": "Invite",
  "Accept-all": "Accept all",
  "Manage-members": "Manage members",
  "You-want-to-be-part-of-a-group?": "You want to be part of a group?",
  "Offline-maps": "Offline maps",
  "Please-enter-a-group-name": "Please enter a group name",
  "Creating-group": "Creating group...",
  "This-group-name-is-already-taken,-please-choose-another-one": "This group name is already taken, please choose another one",
  "Requests": "Requests",
  "Members": "Members",
  "Groups-nearby-(%d)": "Groups nearby ({0})",
  "%1@-joined-the-group-%2@": "{0} joined the group {1}",
  "You-joined-the-group-%@": "You joined the group {0}",
  "Request-pending-%d": "Request pending ({0})",
  "Cancel-request": "Cancel request",
  "No": "No",
  "Share-trip": "Share trip",
  "Brand": "Brand",
  "Enter-missing-brand-name": "Enter missing brand name",
  "Enter-missing-model-name": "Enter missing model name",
  "Can't-find-your-brand?": "Can't find your brand?",
  "Can't-find-your-model?": "Can't find your model?",
  "Current-Location": "Current Location",
  "Route-%d": "Route {0}",
  "Enable-live-tracking-and-send-a-link": "Enable LiveTracking and send a link to your family and friends so that they can follow you on the map.",
  "reWind": "reWind",
  "Adventure-Routing": "Adventure Routing",
  "Find-the-curviest-roads-with-the-most-beautiful-scenery": "Find the curviest roads with the most beautiful scenery and transform your journey into an adventure.",
  "We-get-you-from-A-to-B": "We get you from A to B. Not as fast, but as exciting as possible.",
  "Tracking": "Tracking",
  "Navigating": "Navigating",
  "Adventure-Navigator": "Adventure Navigator",
  "Curvy": "Curvy",
  "Supercurvy": "Supercurvy",
  "Max-Duration": "Max. Duration",
  "Dont-have-time?": "Don't have time?",
  "Live-Tracking": "LiveTracking",
  "Fastest-way": "Fastest Way",
  "Apply": "Apply",
  "Share-rewind-link": "Share reWind Link",
  "Download": "Download",
  "Show-rewind": "Show reWind",
  "Caution-when-activating-fastest-way": "Caution! Activating fastest way navigation will disable Adventure Routing and you might end up on the most boring roads and highways.",
  "Where-do-you-want-to-go": "Where do you want to go {0}?",
  "Group-names-must-be-between": "Group names must be between 2 and 500 letters",
  "Invalid-file-type-only-jpg-png-allowed": "Invalid file type. Only JPG and PNG allowed",
  "Select-area": "Select area",
  "Roundtrip": "Roundtrip",
  "We-are-sorry-we-couldnt-find-a-route-with-the-specified-settings": "We are sorry, we couldn't find a route with the specified settings.",
  "Route-options": "Route Options",
  "Avoid-motorways": "Avoid motorways",
  "Try-14-days": "Try 14 days",
  "then-%@-per-year": "then {0} \/ year",
  "Yearly": "Yearly",
  "%@-per-month": "{0} \/ month",
  "Monthly": "Monthly",
  "%@-per-year": "{0} \/ year",
  "save-money": "save",
  "Get-riser": "Get RISER",
  "go-back-in-time": "Go back in time? On your adventure with RISER you can! Watch your reWind again on an interactive 3D map and share with your friends.",
  "No-connection-no-problem": "No connection - no problem! On your adventure with RISER, Offline Maps will keep you on track even in the most secluded areas.",
  "curves-are-all-you-need": "Curves are all you need? Discover your perfect route with RISER Adventure Routing and use exclusive modes to create it!",
  "Adventure-navigator-pro": "Adventure Navigator PRO",
  "stay-connected-with-live-tracking": "Stay connected with friends and family and keep them updated on your adventure! Send them your location with LiveTracking!",
  "sometimes-its-not-about-where-you-go": "Sometimes it's not about where you go. But how you get there. Enhance your personal adventure with the smart RISER PRO features.",
  "Subscription-terms": "Subscription terms",
  "Something-went-wrong-when-verifying-your-purchase": "Something went wrong when verifying your purchase. Please contact our customer support.",
  "Before-you-become-a-pro": "Before you become a Pro...",
  "Contact": "Contact",
  "Feedback": "Feedback",
  "Agree-and-sign-up": "Agree and Sign Up",
  "Would-you-like-to-receive-updates-via-email": "Would you like to receive updates from RISER via email?",
  "Reason": "Reason",
  "Ambassador": "Ambassador",
  "%1@-also-commented-on-trip-%2@": "{0} also commented on trip {1}",
  "%1@-also-commented-on-bike-%2@": "{0} also commented on bike {1}",
  "%1@-also-commented-on-section-%2@": "{0} also commented on section {1}",
  "average per ride": "average per ride",
  "since last ride": "since last ride",
  "Broken-URL": "Broken URLs are similar to flat batteries - really annoying and time consuming to make them work again.<br><br>But our best developers are already trying to fix this",
  "and-become-part-of-the-community": "and become part of the community",
  "Already-have-an-account": "Already have an account?",
  "Sorry-for-the-inconvenience": "Sorry for the inconvenience. We could not proceed your payment. Your card has not been charged.",
  "Your-purchase-has-been-successfully-applied": "Your purchase has been successfully applied. <br>You can now benefit from all the PRO features. <br>If you have any questions - don’t hesitate to contact us! Have a safe ride.<br>Your RISER Team",
  "thank-you-name": "Thank you {0} & Welcome to RISER PRO!",
  "Please-contact-our-service-team-at": "Please contact our service team at {0}",
  "Oops-seems-like-you-tried-to-become-more-than": "Oops - seems like you tried to become more than PRO…",
  "Your-device-did-send-a-second-request": "Your device did send a second request. But don’t worry - we have only charged you once.",
  "Follow-Us": "Follow Us",
  "Get-the-app-for-free": "Get the app for free",
  "Vote-for-your-preferred-route-until-february": "Vote for your preferred route until february 18 and get the chance to win 1 of 5 RISER PRO memberships for one year!",
  "Vote-now": "Vote Now",
  "Show-all": "Show all",
  "Live-weather": "Live Weather",
  "days": "days",
  "Imprint": "Imprint",
  "Menu": "Menu",
  "Show-all-comments": "Show all comments",
  "Suggestions": "Suggestions",
  "Sign-up-date": "Sign up date",
  "Temperature": "Temperature",
  "Account": "Account",
  "Celsius": "Celsius",
  "Fahrenheit": "Fahrenheit",
  "%1@-also-commented-on-getaway-%2@": "{0} also commented on getaway {1}",
  "%@-friends": "{0}'s friends",
  "Discover-even-curvier-routes-with-the": "Discover even curvier routes with the Adventure Navigator PRO. Never lose sight of your destination with Offline Maps. Track your trips and reWind them on a 3D map. Let your loved ones know where you are with LiveTracking.",
  "Extended-route-planning": "Extended Route Planning",
  "Watch-your-trip-once-again-on-an-interactive-3D-map-and-share-it-with-your-friends": "Watch your trip once again on an interactive 3D-map and share it with your friends.",
  "Never-lose-your-way-when-theres-no-connection-even-in-the-most-remote-areas": "Never lose your way when there’s no connection, even in the most remote areas.",
  "The-exciting-thing-about-motorcycling-is-not": "The exciting thing about motorcycling is not (only) the winding road. Actually it is the thrill, the unknown, the adventure, the freedom and the experience of unexpected things that make a ride special. <br>As motorcyclists, we enjoy riding together - because unique moments only become really valuable when we can share them with others. <br>The community is what counts most for all of us! <br><br>That's why the idea for the RISER ADVENTURE AWARD came up: <br>For the first time this year we present the annual award which will go to an adventure of a user from the RISER Community, for the most exciting, most beautiful, most adventurous motorcycle trip  2018.<br><br>YOU DECIDE WHO WINS! <br>Your ratings will give one of the nominees the chance to win the RISER ADVENTURE AWARD 2018*. <br><br>The voting for the ADVENTURE AWARD runs from now until 18.02.2019 at 24:00 CET: Log in and give a Like for the trip you like best - and with a little luck you might win too! <br>Among all RISER users who participate in the voting, we raffle 5 x 1 RISER PRO memberships for 1 year!",
  "Conditions-of-participation": "*Conditions of participation: Each RISER user has as many Likes available as he\/she wants to assign. The user is free to like all nominated trips, or just one. The allocation of the 5 x 1 year RISER PRO as a prize among users participating in the voting takes place by raffle. The winner(s) will be notified by e-mail at the end of the award and after publication of the award winner. Double winnings are excluded, no cash redemption possible. For questions please get in touch with support{'@'}riserapp.com",
  "What-bike-were-you-riding": "What bike were you riding?",
  "Be-part-of-the-RISER-community": "Be part of the RISER community",
  "Enter-name": "Enter name",
  "Could-not-load-weather": "Could not load weather",
  "Billed-annually": "Billed annually",
  "Starting at (price)": "Starting at",
  "Free": "Free",
  "Forever": "Forever",
  "Free_description": "Get the RISER app and platform with its basic features.",
  "Open-the-app-to-purchase-RISER-PRO": "Open the app to purchase RISER PRO. If you don`t have the app, you can download it for free via your app store:",
  "registering-is-free": "registering is 100% free",
  "Pick-a-date": "Pick a date",
  "settings-account-deletion-disclaimer": "If you delete your account, all data and activities will be deleted permanently. The data cannot be recovered afterwards. You can, however, create a new account any time.",
  "Sometimes it’s not about where you go, but how you get there.": "Sometimes it’s not about where you go, but how you get there.",
  "Remember-me": "Remember me",
  "Thank you for your trust!": "Thank you for your trust!",
  "You will now continue receiving our newsletter": "You will now continue receiving our newsletter, containing up to date information, news, and more from RISER.",
  "You can always use your right to withdraw this permission": "You can always use your right to withdraw this permission, without giving any reason.",
  "Signup-&-Subscription": "Signup & Subscription",
  "Get-Pro": "Get PRO",
  "Are-you-sure-you-want-to-delete-your-comment?": "Are you sure you want to delete your comment?",
  "Comments": "Comments",
  "Start-(footer)": "Start",
  "Because we’re all driven by adventure.": "Because we’re all driven by adventure.",
  "RISER is the all-in-one-tool for motorcyclists focused on the adventure in motorcycle.": "RISER is the all-in-one-tool for motorcyclists focused on the adventure in motorcycle. Discover exciting new routes tailored to your personal preferences using the intelligence of RISER Adventure Routing. Track and analyze your trips, share and discuss your adventures with friends and organize Getaways or Groups.",
  "RISER is a worldwide, daily growing community, united by the desire to be free": "RISER is a worldwide, daily growing community, united by the desire to be free, stay curious and make unique experiences together, leaving the everyday routine behind.",
  "<h2>DISCOVER<\/h2> <p>your ideal trip with RISER Navigator<\/p>": "<h2>DISCOVER<\/h2> <p>your ideal trip with RISER Navigator<\/p>",
  "Find routes tailored to your personal preferences using RISER Adventure Routing": "Find routes tailored to your personal preferences using RISER Adventure Routing with the settings curvy route or fastest way. Let RISER navigate you turn by turn and use live weather forecast for your area.",
  "<h2>ADVENTURES<\/h2> <p>to track and share<\/p>": "<h2>ADVENTURES<\/h2> <p>to track and share<\/p>",
  "Track your motorcycle trip with GPS data, save and organize them in your personal roadbook.": "Track your motorcycle trip with GPS data, save and organize them in your personal roadbook. See statistics on your personal profile and add people to your ride after your rideout. Share your adventures via a link.",
  "<h2>INSPIRATION<\/h2> <p>by and for you<\/p>": "<h2>INSPIRATION<\/h2> <p>by and for you<\/p>",
  "Add your bikes to your personal garage and capture your impressions on the road": "Add your bikes to your personal garage and capture your impressions on the road in photos you can save with your trip. Get inspired by blogposts with interesting news and your friend's adventures in the news feed. Discuss trips, sections and bikes in the comment section.",
  "<h2>UNIQUE MOMENTS<\/h2> <p>with others<\/p>": "<h2>UNIQUE MOMENTS<\/h2> <p>with others<\/p>",
  "Connect with your friends and like-minded people": "Connect with like-minded people, create getaways to plan trips with your friends, or join groups to get inspired.",
  "CONNECT WITH FRIENDS": "CONNECT WITH FRIENDS",
  "CREATE OR JOIN GROUPS": "CREATE OR JOIN GROUPS",
  "SHARE YOUR EXPERIENCE": "SHARE YOUR EXPERIENCE",
  "<h2>GO PRO!<\/h2> <p>Individual adventures with RISER PRO<\/p>": "<h2>GO PRO!<\/h2> <p>Individual adventures with RISER PRO<\/p>",
  "RISER PRO provides new sophisticated features for you": "RISER PRO provides new sophisticated features for you: Find your perfect itineraries using RISER Adventure Navigator PRO with the settings: curvy route, supercurvy route, fastest way from A to B or as a  roundtrip. Share your location in real time with LiveTracking. Never get lost with Offline Maps. Go back in time? Watch an animated depiction of the route you drove with reWind on an interactive 3D map you can share with others via a Link.",
  "Treat yourself with a 14 days free trial now!": "Treat yourself with a 14 days free trial now!",
  "Empower yourself to experience new adventures": "Empower yourself to experience new adventures",
  "FIND YOUR IDEAL ROUTE": "FIND YOUR IDEAL ROUTE",
  "Curvy? Super curvy? Yeah, we know you don’t like straight roads.": "Curvy? Super curvy? Yeah, we know you don’t like straight roads. That’s why RISER Adventure Routing detects the coolest routes with the most curves for you if you use these preference when planning your next trip using RISER Adventure Navigator PRO. Either from A to B or a roundtrip, get inspired by the best curves in the world and enjoy the ride with Turn by Turn Navigation!",
  "NEVER GET LOST": "NEVER GET LOST",
  "Be on the move and do not lose your orientation": "Be on the move and do not lose your orientation, even if the connection to the mobile network is no longer available - thanks to Offline Maps. Offline Maps makes the entire map material (your respective selection) available offline so that you can always access it.",
  "GO BACK IN TIME": "GO BACK IN TIME",
  "Each driven trip is unique and each route special in their own way.": "Each driven trip is unique and each route special in their own way. In order to be able to review your ride afterwards, RISER provides the possibility to share a interactive map made with 3D map material for each saved trip. You can zoom in or out on the dynamic map at any time or change the perspective. Photos & statistic information will be shown as well and you can share the map via link with your friends.",
  "SHARE YOUR POSITION": "SHARE YOUR POSITION",
  "On a longer motorcycle ride, as a RISER PRO user you can send your position": "On a longer motorcycle ride, as a RISER PRO user you can send your position on the route to family or friends: With the LiveTracking function, RISER provides the possibility to send a link with your position to give the viewers an overview of the journey, or where the next meeting point could be, to join the ride.",
  "Newsletter": "Newsletter",
  "The road is never straight for us": "The road is never straight for us - if you wanna stay up to date about new features, cool trips and stories by others, here’s your chance to sign up for the Newsletter:",
  "The RISER community explores beautiful routes every day": "<p>The RISER community explores beautiful routes every day, enriching the app and platform with inspiration and lots of routes that become an inspiration for others.<\/p>\n\n<p>So why isn't there an award for discovering great routes? Right, we don't know either! That's why we created the RISER Adventure Award - an award for which you only have to do what you like best: riding your motorcycle!<\/p>",
  "What is the RISER Adventure Award?": "What is the RISER Adventure Award?",
  "From now on, RISER will award the prize annually": "<p>From now on, RISER will award the prize annually - but whoever wins will be decided by the community: ten users will compete for the award with one trip each.<\/p>\n\n<p>The RISER Community gives Likes to their favorite routes of the nominees for one month. Who has more Likes at the end, wins!<\/p>",
  "How do you become a nominee?": "How do you become a nominee?",
  "Every RISER user has the chance to be nominated through the recorded trips": "<p>Every RISER user has the chance to be nominated through the recorded trips. It's not the duration or speed of the trip that counts, but the question of whether the route is really fun on the bike. The more interesting trips you track, the higher the chance of finding your own trip among the nominated routes for the Adventure Award.<\/p>\n\n<p>The nominees will be notified by the RISER team by e-mail (registration address in the APP) and asked for their consent to participate before the start of the voting.<\/p>",
  "What's there to win?": "What's there to win?",
  "Each year different prizes are awarded for the Adventure Award winner": "<p>Each year different prizes are awarded for the Adventure Award winner. For the Award 2018 there were e.g:<\/p>\n\n<ul>\n<li>1x Lifetime RISER-Pro Membership<\/li>\n<li>2x RISER-Pro membership for 1 year for his friends<\/li>\n<li>RISER Adventure Award trophy<\/li>\n<li>winners box<\/li>\n<\/ul>\n\n<p>In addition, 5 RISER PRO memberships for 1 year, were raffled among all users participating in the voting.<\/p>",
  "Winner": "Winner",
  "Kameri Labinot on his Harley Davidson": "Kameri Labinot on his Harley Davidson - Electra Glide Ultra Limited FLHTK and with his trip -HARLEY IS HOME-.",
  "Starting in Kosovo, across Albania, Montenegro, Bosnia-Herzegovina and Croatia": "Starting in Kosovo, across Albania, Montenegro, Bosnia-Herzegovina and Croatia, through beautiful coastlines and stops like in the charming old town of Budva (MNE), Kameri drove his Harley on the winning route 2018, collecting the most Likes with the exceptionally beautiful route and sharing a special trip tip for all bikers of the RISER community. Thanks for that!",
  "Why is there no award for discovering great routes?": "Why is there no award for discovering great routes?<br>\n<br>\nWe thought so too.",
  "Find out more": "Find out more",
  "Become part of the community": "Become part of the community",
  "Join now for free!": "Join now for free!",
  "Join the global RISER Community, get expert tips": "Join the global RISER Community, get expert tips for remote places and share your experiences with likeminded people.",
  "Make the most of your time and let RISER turn every motorcycle ride into an unforgettable adventure": "Make the most of your time and let RISER turn every motorcycle ride into an unforgettable adventure. Find the perfect route fitting your riding style. Save time in planning and spend more time on the bike!",
  "Empowering riders": "Empowering riders",
  "The adventure starts long before we get on our bikes": "The adventure starts long before we get on our bikes, and ends only way after we have turned off the engine. That’s why we want to give you much more than a curvy route (of course we’ll show you that, too):",
  "Get inspired": "Get inspired",
  "Before the ride": "Before the ride",
  "Find the best routes that fit your riding style and plan your next Getaway with friends.": "Find the best routes that fit your riding style and plan your next Getaway with friends.",
  "Record & explore": "Record & explore",
  "During the ride": "During the ride",
  "Track your rides and experience unique moments.": "Track your rides and experience unique moments.",
  "Share & contribute": "Share & contribute",
  "After the ride": "After the ride",
  "Share your adventures and connect with the Community.": "Share your adventures and connect with the Community.",
  "Sharing the same desire": "Sharing the same desire",
  "To make the best of the time we have on our bikes.": "To make the best of the time we have on our bikes.",
  "Yeah, let's get started!": "Yeah, let's get started!",
  "This keeps us going": "This keeps us going",
  "RISER is not just a set of functionality packed into an app": "RISER is not just a set of functionality packed into an app, but a tool to empower you and your friends to have the most unique moments.",
  "Before": "Before",
  "Discover spectacular routes": "Discover spectacular routes",
  "Plan your Getaways with friends": "Plan your Getaways with friends",
  "Connect with the community": "Connect with the community",
  "During": "During",
  "Discover together": "Discover together",
  "Track your adventures": "Track your adventures",
  "Navigate to your destination": "Navigate to your destination",
  "After": "After",
  "Save your memories": "Save your memories",
  "Share unique moments": "Share unique moments",
  "Inspire other riders": "Inspire other riders",
  "Riders about RISER": "Riders about RISER",
  "RISER is designed logically and easy to use": "RISER is designed logically and easy to use. It offers everything i need for motorcycling. Keep up the good work!",
  "The app is clean and super-easy to use": "The app is clean and super-easy to use. Trip recording works smoothly and i can easily document my rides with photos etc. Recommended the app to all of my friends.",
  "This app is awesome": "This app is awesome. I recently found nice roads in my area that i didn't even know about.",
  "Find your personal adventure": "Find your personal adventure",
  "The best routes are waiting out there and RISER will find them for you.": "The best routes are waiting out there and RISER will find them for you.",
  "Let's go!": "Let's go!",
  "Become a %@": "Become a {0}",
  "Discover even curvier routes with the Adventure Navigator PRO": "Discover even curvier routes with the Adventure Navigator PRO. Never lose sight of your destination with Offline Maps. Track your trips and reWind them on a 3D map. Let your loved ones know where you are with LiveTracking.",
  "Safety": "Safety",
  "Let others know where you are by sending them a secret link with your real time location": "Let others know where you are by sending them a secret link with your real time location.",
  "Navigation": "Navigation",
  "Extended Routing": "Extended Routing",
  "Let RISER find the curviest routes for you and use the Tour Planner to plan longer adventures": "Let RISER find supercurvy routes for you so you'll have even more fun on the road.",
  "Relive": "Relive",
  "Watch your trip once again on an interactive 3D-map and share it with your friends": "Watch your trip once again on an interactive 3D-map and share it with your friends",
  "Comfort": "Comfort",
  "Never lose your way when there’s no connection, even in the most remote areas": "Never lose your way when there’s no connection, even in the most remote areas",
  "Find out more about RISER PRO": "Find out more about RISER PRO",
  "RISER ambassador adventures": "RISER ambassador adventures",
  "Get inspired for your next trip!": "Get inspired for your next trip!",
  "The country-, blues & whiskey motorcycle tour": "The country-, blues & whiskey motorcycle tour. Girls just wanna have fun pt.2",
  "I don't listen to country, rarely blues - and whiskey is not necessarily the drink of my choice": "I don't listen to country, rarely blues - and whiskey is not necessarily the drink of my choice. But how could I have said no to a route that has the same title and leads through the country where my Harley was born! Although I had not yet had the urge to explore America, this motorcycle route through the southern states aroused my curiosity.",
  "Read more": "Read more",
  "Choose new at each crossing": "Choose new at each crossing",
  "It was the beginning of June 2016 when I sat on a motorcycle for the first time": "It was the beginning of June 2016 when I sat on a motorcycle for the first time. Back then I didn't even know exactly how the gearshift worked. I was faced with the difficult decision of whether to start playing golf or start motorcycling. Today I am convinced that I made the right decision. It's my third season and I don't regret a single second.",
  "Around the 'Ortler' group - seven at a stroke": "Around the 'Ortler' group - seven at a stroke",
  "The weather was wonderful on this Saturday": "The weather was wonderful on this Saturday, so I spontaneously decided to go for a ride early in the morning. An \"Iron Man\" stage at the beginning of autumn - a demanding and strenuous one-day trip.",
  "This 'classic' round is impressive and spectacular again and again.": "(Stubai) - Brenner Pass - Jaufen Pass - ( Val Passiria - Vinschgau) - Stilfser Joch - Gaviapass (what a rough, beautiful pass!) - Tonale Pass - (Val du Nons) Mendel Pass - (Bolzano - Sarntal) - Penserjoch: This 'classic' round is impressive and spectacular again and again.",
  "Alone from Lucerne to the North Cape - 1st stage": "Alone from Lucerne to the North Cape - 1st stage",
  "Since I had obtained my motorcycle driving licence four years ago, I have been on the road a lot": "Since I had obtained my motorcycle driving licence four years ago, I have been on the road a lot. Mainly in the Swiss Alps and in South Tyrol. But eventually I wanted to do a bigger tour. But where should I go? I had absolutely no experience with longer self-organized tours. Over time, the idea of the North Cape as my first big destination gradually developed.",
  "Australia's Great Ocean Road off the beaten track": "Australia's Great Ocean Road off the beaten track",
  "In the past, I've had the opportunity to be part of the guide team on a trip several times": "In the past, I've had the opportunity to be part of the guide team on a trip several times. But this time it happened rather by accident: Damien, the ride host had broken his wrist the week before on the track, and asked me if I could cover for him.",
  "Highlights of our 8500 km summer tour: Italy, France, Spain, Portugal": "Highlights of our 8500 km summer tour: Italy, France, Spain, Portugal",
  "As always in recent years, we have chosen a destination": "As always in recent years, we have chosen a destination (Cabo de Sao Vicente in Portugal) and then drove in this direction without more precise planning.",
  "Be part of this adventure": "Be part of this adventure",
  "Sign up now!": "Sign up now!",
  "pack ride": "PackRide",
  "Pack Ride Unlimited": "PackRide Unlimited",
  "Upgrade-(pro)": "Upgrade",
  "Subscribed-until": "Subscribed until",
  "Your-RISER-Team": "Your RISER Team",
  "Something-went-wrong-with-your-payment": "Something went wrong with your payment...",
  "We-sent-a-verification-mail-to-%@": "We sent a verification mail to {0}",
  "Before we can upgrade you to RISER PRO, please make sure the e-mail address you provided is correct so we can send you receipts.": "Before we can upgrade you to RISER PRO, please make sure the e-mail address you provided is correct so we can send you receipts.",
  "Verify your email address": "Verify your email address",
  "An email has been sent to %@ before you can continue please click": "An email has been sent to {0} before you continue please click the link we sent you to verify yourself.",
  "Email sent": "Email sent",
  "Email verified": "Email verified",
  "Your email address has been verified": "Your email address has been verified",
  "Securely pay %@": "Securely pay {0}",
  "Continue with payment": "Continue with payment",
  "Subtotal": "Subtotal",
  "Total": "total",
  "Discount": "Discount",
  "Support": "Support",
  "Never miss out on the best routes and news with our Newsletter": "Never miss out on the best routes and news with our Newsletter",
  "Congrats!": "Congrats!",
  "If you need support please get in touch with %@": "We are sorry for the inconvenience. If you need support please get in touch with {0}",
  "Code expired": "Code expired",
  "Sorry, too late! This voucher is no longer active.": "Sorry, too late! This voucher is no longer active.",
  "Already used": "Already used",
  "You already used this voucher.": "You already used this voucher.",
  "Ride together, stay together": "Ride together, stay together",
  "Enjoy the full Pack Ride experience with RISER PRO": "Enjoy the full PackRide experience with RISER PRO: Never lose your group and send or receive notifications.",
  "Get an even more sophisticated experience": "Get an even more sophisticated experience",
  "Get PRO now": "Get PRO now",
  "What are you waiting for?": "What are you waiting for?",
  "come": "Come",
  "Ride With US": "Ride With Us",
  "Become part of the RISER pack. We will empower you to make more memorable riding experiences worth sharing.": "Become part of the RISER pack. We will empower you to make more memorable riding experiences worth sharing.",
  "Join the pack!": "Join the pack!",
  "Never loose your group and send or receive notifications.": "Never loose your group and send or receive notifications. (For unlimited PackRide experience - more than 30 minutes - the pack leader needs to have a RISER PRO membership)",
  "voucher_success_message": "You now have access to RISER PRO!\n\nYour account will be automatically set to Basic after the given period.",
  "Already Pro": "Already PRO",
  "voucher_error_already_pro_message": "You already have an active PRO subscription. This voucher can only be applied to \nBasic users.\n\nBut you can share this voucher with a friend to give him\/her PRO.",
  "current period until": "current period until",
  "Your email address could not be verified": "Your email address could not be verified",
  "Find out how": "Find out how",
  "Show your support for female riders!": "Show your support for female riders!",
  "Show your support for female riders by doing a pack ride!": "SHOW YOUR SUPPORT FOR FEMALE RIDERS BY DOING A PACK RIDE! RISER gives you 2 months RISER PRO for free to participate.",
  "We use technical and analytics cookies to ensure that we give you the best experience on our website": "We use technical and analytics cookies to ensure that we give you the best experience on our website.",
  "This website uses cookies": "This website uses cookies",
  "Continue to profile": "Continue to profile",
  "Group": "Group",
  "Never miss out on the hottest news & tours from your motorcycle community on RISER!": "Never miss out on the hottest news & tours from your motorcycle community on RISER!",
  "Email Format": "Email Format",
  "Marketing Permissions": "Marketing Permissions",
  "Please select all the ways you would like to hear from RISER:": "Please select all the ways you would like to hear from RISER:",
  "Email - get the best stories and news": "Email - get the best stories and news",
  "Customized Online Advertising - get offers tailored to your preferences": "Customized Online Advertising - get offers tailored to your preferences",
  "You can unsubscribe at any time by clicking the link in the footer of our emails.": "You can unsubscribe at any time by clicking the link in the footer of our emails.",
  "We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing.": "We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing.",
  "Learn more about Mailchimp's privacy practices here.": "Learn more about Mailchimp's privacy practices here.",
  "Biannually": "Biannually",
  "6_months_PRO_title": "6 months PRO",
  "Currently not on your phone? Open your RISER App now and get started!": "Currently not on your phone? Open your RISER App now and get started!",
  "Are you sure you want to leave this getaway?": "Are you sure you want to leave this getaway?",
  "Invited": "Invited",
  "The App for Motorcyclists": "The App for Motorcyclists",
  "Available for free": "Available for free",
  "Login with Email": "Login with Email",
  "Register with Email": "Register with Email",
  "What are you looking for?": "What are you looking for?",
  "We are sorry but we could not find any results for your search…": "We are sorry but we could not find any results for your search…",
  "Users": "Users",
  "Enter start": "Enter start",
  "Enter waypoint": "Enter waypoint",
  "Enter Destination": "Enter Destination",
  "Sign Up or Login to join the conversation": "Sign Up or Login to join the conversation",
  "Created": "Created",
  "6_months_PRO_description": "Get your 6 months PRO and enjoy all PRO features for half a year!",
  "One time payment": "One time payment",
  "1_year_PRO_description": "Get all PRO features and enhance your experience!",
  "We have updated our Terms of Service": "We have updated our Terms of Service. Please review the latest version below.",
  "1_year_PRO_title": "1 year PRO",
  "Free_title": "Basic",
  "Already verified": "Already verified",
  "Resend verification link": "Resend verification link",
  "Cancel subscription": "Cancel subscription",
  "Subscription Period": "Subscription Period",
  "Product": "Product",
  "Invoice Status": "Invoice Status",
  "invoice_draft": "Draft",
  "invoice_open": "Open",
  "invoice_paid": "Paid",
  "invoice_void": "Void",
  "invoice_uncollectible": "Uncollectible",
  "No open payments and already PRO": "There are no open payments and you already have all the benefits of RISER PRO! Thank you for your continued support.",
  "Country": "Country",
  "Payment details": "Payment details",
  "Card holder": "Card holder",
  "Street address": "Street address",
  "State (address)": "State",
  "ZIP Code": "ZIP Code",
  "Card number": "Card number",
  "Secure customer authentication required before payment can be processed.": "Secure customer authentication required before payment can be processed.",
  "Card invalid.": "Card invalid.",
  "Period": "Period",
  "Amount due": "Amount due",
  "Remember that you have to manually cancel any mobile subscriptions.": "Remember that you have to manually cancel any mobile subscriptions in the respective app Store.",
  "To delete your account you first need to cancel your active subscription.": "To delete your account you first need to cancel your subscription.",
  "Invalid password reset token.": "Invalid password reset token.",
  "Send password reset email": "Request password reset email",
  "No user with this email address was found.": "No user with this email address was found.",
  "Type in your e-mail and we will send you a link to reset your password.": "Type in your e-mail and we will send you a link to reset your password.",
  "An email has been sent to %@ please follow the instructions inside to reset your password.": "An email has been sent to {0} please follow the instructions inside to reset your password.",
  "Upgrade now and become a PRO!": "Upgrade now and become a PRO!",
  "Discover PRO plans": "Discover PRO plans",
  "and more to come...": "and more to come...",
  "Subscribe now!": "Subscribe now!",
  "Austria": "Austria",
  "Belgium": "Belgium",
  "Bulgaria": "Bulgaria",
  "Croatia": "Croatia",
  "Cyprus": "Cyprus",
  "Czech Republic": "Czech Republic",
  "Denmark": "Denmark",
  "Estonia": "Estonia",
  "Finland": "Finland",
  "France": "France",
  "Germany": "Germany",
  "Greece": "Greece",
  "Hungary": "Hungary",
  "Ireland": "Ireland",
  "Italy": "Italy",
  "Latvia": "Latvia",
  "Lithuania": "Lithuania",
  "Luxembourg": "Luxembourg",
  "Malta": "Malta",
  "Netherlands": "Netherlands",
  "Poland": "Poland",
  "Portugal": "Portugal",
  "Azores": "Azores",
  "Madeira": "Madeira",
  "Mainland (Continental Portugal)": "Mainland (Continental Portugal)",
  "Romania": "Romania",
  "Slovakia": "Slovakia",
  "Slovenia": "Slovenia",
  "Spain": "Spain",
  "Sweden": "Sweden",
  "United Kingdom": "United Kingdom",
  "You are almost there!": "You are almost there!",
  "Street and number": "Street and number",
  "(optional) Apartment, building, floor": "(optional) Apartment, building, floor",
  "Tax region": "Region",
  "Pay invoice": "Pay invoice",
  "If you have any questions, please let us know: %@": "If you have any questions, please let us know: {0}",
  "now -%@%": "now -{0}%",
  "14 day free trial": "14 day free trial",
  "only for the annual plan": "only for the annual plan",
  "%@-per-6-months": "{0} \/ 6 months",
  "Copied to Clipboard": "Copied to Clipboard",
  "Copy": "Copy",
  "or share on": "or share on",
  "Challenges": "Challenges",
  "Your Rank": "Your Rank",
  "Your Distance": "Your Distance",
  "Accept the Challenge": "Accept the Challenge",
  "Leave Challenge": "Leave Challenge",
  "Leaderboard": "Leaderboard",
  "No-challenges-to-show": "There are currently no challenges",
  "We couldn't find any of your Facebook": "We couldn't find any of your Facebook friends because you haven't connected Facebook to RISER. Click the button to connect Facebook and RISER.",
  "Your Duration": "Your Duration",
  "Upcoming": "Upcoming",
  "Past": "Past",
  "Current": "Current",
  "This-is-a-mystery-user": "Apparently, this rider prefers to keep an air of mystery about them.",
  "Follow": "Follow",
  "About": "About",
  "Planned": "Planned",
  "Ride this now": "Ride this now",
  "General": "General",
  "Short Description": "Short Description",
  "Social Media": "Social Media",
  "Likes": "Likes",
  "Add Posting": "Add Posting",
  "Post": "Post",
  "Add Photo": "Add Photo",
  "ktm-challenge-text": "Mark your calendars, THE WORLD ADVENTURE WEEK takes place on July 5th to 11th 2021. The main goal is to get on your bike and ride 1,000 km or more in seven days.\n\nPowered by KTM and RISER, THE WORLD ADVENTURE WEEK is the perfect excuse to put some mileage on your motorcycle no matter what brand or model you ride.\n\nThe most adventurous riders will be rewarded with daily and weekly prizes and get the chance to be featured on theworldadventureweek.com and KTM's global social media channels. \n\nThere will be different challenges to complete on each of the seven days, with all riders completing 1,000 km or more in total receiving a personalized award.\n\nMore information coming soon. Stay tuned!",
  "ktm-challenge-title": "7 Days, 1000 Kilometer, July 5 - 11 , 2021",
  "ktm-challenge-terms": "I agree to the <a target=\"_blank\" href=\"https:\/\/riserapp.com\/en\/waw-terms\">RISER's terms and conditions <\/a> and <a target=\"_blank\" href=\"https:\/\/www.ktm.com\/en-int\/world-adventure-week\/terms---conditions.html\">KTM's terms and conditions<\/a> of the World Adventure Week",
  "State\/Province": "State\/Province",
  "Your Score": "Your Score",
  "Total Points": "Total Points",
  "Challenge": "Challenge",
  "Your Challenge Bike": "Your Challenge Bike",
  "You don’t have any planned routes so far": "You don’t have any planned routes so far. Use the app or open routeplanner.pro on your computer to plan your next adventure!",
  "Are you sure you want to delete this planned route": "Are you sure you want to delete this planned route?",
  "No planned Routes": "No planned routes",
  "save_your_route_to_your_roadbook": "Save your route to your roadbook",
  "Name your planned route": "What should be the name of your planned route",
  "save_planned_route_successful": "Route successfully saved in your roadbook.",
  "join for free": "Join for free",
  "Minimum 8 characters": "Minimum 8 characters",
  "Continue with Google": "Continue with Google",
  "Log in to RISER": "Log in to RISER",
  "Start": "Start",
  "Skip": "Skip",
  "Add your first bike to your garage": "Add your first bike to your garage",
  "Add your bike to get detailed statistics about your rides and more.": "Add your bike to get detailed statistics about your rides and more.",
  "Set your season goal": "Set your season goal",
  "How far do you want to ride this season?": "How far do you want to ride this season?",
  "What are you most interested in?": "What are you most interested in?",
  "Connect with friends": "Connect with friends",
  "Find_exciting_routes": "Find exciting routes",
  "Share_my_rides_with_others": "Share my rides with others",
  "Organize common rides": "Organize common rides",
  "Tell us something about you": "Tell us something about you",
  "Homebase": "Homebase",
  "Continue": "Continue",
  "Stay_up_to_date_and_get_the_latest_news": "Stay up to date and get the latest news",
  "Choose Allow receive notifications and emails": "Chose “Allow” receive notifications about your rides and stories from friends & the community.",
  "Allow": "Allow",
  "Later": "Later",
  "number_month": "%d month|%d months",
  "Welcome to \u2028RISER,!": "Welcome to RISER, {0}!",
  "You are now all set for your first ride": "You are now all set for your first ride, to explore new roads and to compete with others in challenges. Have fun!",
  "Today": "Today",
  "Unlock and test all PRO features like": "Unlock and test all PRO features like LiveTracking, PackRide, supercurvy routes and more {0} days free of charge.",
  "You will receive a reminder about when your trial ends.": "You will receive a reminder about when your trial ends.",
  "You will be charged for the subscription. Cancel anytime before.": "You will be charged for the subscription. Cancel anytime before.",
  "price year month after trial": "{0} \/ year ({1} \/ month) after trial",
  "Start Free Trial": "Start Free Trial",
  "You won’t be charged until": "You won’t be charged until {0}. Cancel anytime up to 24hrs before your trial ends",
  "Unfollow": "Unfollow",
  "Snap": "Direction",
  "Fullscreen": "Fullscreen",
  "Trip": "Trip",
  "Rewind Speed": "reWind Speed",
  "Max Altitude": "Max Altitude",
  "Open Roadbook": "Open Roadbook",
  "Clear all Waypoints": "Clear all Waypoints",
  "Add as start point": "Add as start point",
  "Add as intermediate point": "Add as intermediate point",
  "Add as end point": "Add as end point",
  "Saving": "Saving",
  "Direction": "Direction",
  "North": "North",
  "NorthEast": "North-East",
  "East": "East",
  "SouthEast": "South-East",
  "South": "South",
  "SouthWest": "South-West",
  "West": "West",
  "NorthWest": "North-West",
  "Direction_Duration": "Direction & Duration",
  "Random": "Random",
  "Done": "Done",
  "bio": "Bio",
  "show": "Show",
  "choose_map_type": "Choose map type",
  "Statistics": "Statistics",
  "Save Route to Your Roadbook": "Save Route to Your Roadbook",
  "Season goal": "Season goal",
  "Current period": "Current period:",
  "Edit profile": "Edit profile",
  "Download the RISER App to discover this route": "Download the RISER App to discover this route",
  "Check out the brand new": "🔥 Check out the brand new update and elevate your trips onto the next level.",
  "Connect with friends & riders across the globe": "Connect with friends & riders across the globe",
  "Plan your next \u2028adventure": "Plan your next \u2028adventure",
  "Record your rides & share them with the community": "Record your rides & share them with the community",
  "continue_with_apple": "Continue with Apple",
  "by_adding_your_name_and_homebase_you_make_it_easier_for_your_friends_to_find_you": "By adding your name and homebase you make it easier for your friends to find you.",
  "not_now": "Not now",
  "Create posting": "Create posting",
  "Post now": "Post now",
  "What do you want to share?": "What do you want to share?",
  "Distance-this-season": "Distance this season",
  "Report posting": "Report posting",
  "We need your help here": "We need your help here",
  "In order to evaluate your report": "In order to evaluate your report we need some information from you. Please choose from once of the categories and provide us with a short description so that we can proceed your request fast.",
  "Inappropriate Language": "Inappropriate Language",
  "Spam or Scam": "Spam or Scam",
  "Sexual harassment": "Sexual harassment",
  "Other": "Other",
  "Almost there": "Almost there...",
  "Please provide us with a short": "Please provide us with a short description in your own words. This way it is easier for us to understand and to evaluate the respective content.",
  "Why do you want to report this comment posting": "Why do you want to report this comment \/ posting?",
  "Thank you for reporting": "Thank you for reporting",
  "Our team will look into the matter": "Our team will look into the matter as soon as possible",
  "Elevation": "Elevation",
  "Thank you for your feedback": "Thank you for your feedback. We will review it as soon as possible.",
  "Report this": "Report this",
  "Delete this postings": "Delete this postings",
  "Delete this comment": "Delete this comment",
  "Press control to change angle": "Press <strong>Control + Drag<\/strong> with the mouse to change angle",
  "You": "You",
  "Show more": "Show more",
  "load more comments": "load more comments",
  "new bike": "new bike",
  "x_comments": "{0} Comment|{0} Comments",
  "x_likes": "{0} Like|{0} Likes",
  "posting": "Posting",
  "Unlock all PRO features": "Unlock all PRO features and elevate your next ride onto the next level.",
  "your special offer": "your special offer {0}",
  "percent OFF forever": "{0} off",
  "Get PRO for __ now": "Get PRO for {0} now",
  "only": "only",
  "4 Days left": "{0} Days left",
  "PRO membership": "PRO membership",
  "Whats_included": "What’s included:\nUnlimited PackRide, Super curvy routes, Offline Maps, reWind, LiveTracking, GPX Export and more...",
  "Achievements": "Achievements",
  "completed": "completed",
  "__completed": "{0} completed",
  "Share this": "Share this",
  "profile": "Profile",
  "Please use the app to redeem this voucher": "Please use the app to redeem this voucher",
  "Title is not unique": "Title is not unique",
  "error": "Error",
  "Scan the code to download the app": "Scan the code to download the app",
  "Become PRO to get the best out of RISER": "Become PRO to get the best out of RISER.",
  "action_load": "Load",
  "Route": "Route",
  "Plan now": "Plan now",
  "Order by": "Order by",
  "Creation Date": "Creation Date",
  "Start your own group ride or join an existing one": "Start your own group ride or join an existing one and see where your friends are in real time.",
  "Stop": "Stop",
  "Choose options": "Choose options",
  "Join": "Join",
  "packrides_nearby": "Packrides nearby",
  "Double click to add new destination": "<strong>Double click<\/strong> to add new destination",
  "A simple double click adds a new destination to your route": "A simple double click adds a new destination to your route.",
  "Right Click to get additional options": "<strong>Right Click<\/strong> to get additional options.",
  "With a right click you get the option to add a new start point, destination or waypoint in between": "With a right click you get the option to add a new start point, destination or waypoint in between.",
  "Drag & drop waypoints to change the order": "<strong>Drag & drop<\/strong> waypoints to change the order.",
  "Simply drag a waypoint and drop it to easily arrange the order of waypoints to get exactly the route you desire": "Simply drag a waypoint and drop it to easily arrange the order of waypoints to get exactly the route you desire.",
  "Without a waypoint in between, this is too far. Please plan this route with waypoints to this destination": "Without a waypoint in between, this is too far. Please plan this route with waypoints to this destination.",
  "Congratulations, %@": "Congratulations,\n{0} 🎉",
  "You unlocked a new achievement": "You unlocked a new achievement.|You unlocked {0} new achievements.",
  "achievement unlocked": "Achievement \nunlocked 🎉",
  "Get your code": "Get your code",
  "on your next cardo device": "on your\nnext\nCardo\ndevice",
  "PRO only": "PRO only",
  "your_special_cardo_offer": "Your special offer",
  "cardo_offer_code_text": "Use the code below to get your special discount of {0}% OFF on your next Cardo device (Packtalk Pro excluded).",
  "visit_cardo_shop": "Visit Cardo shop",
  "Route Planner": "Route Planner",
  "start_action": "Start",
  "update_soft_title": "Update available",
  "update_hard_title": "You need to update this app",
  "update_message": "We did a lot of improvements to our app. Please update to the latest version.",
  "update_now": "Update now",
  "Direct competition": "Direct competition",
  "Enjoying Riser": "Enjoying RISER, {0}?",
  "Your rating helps us grow": "Your rating helps us grow and improve our app. Show your support with a quick tap!",
  "Thanks for the high-five": "Thanks for the high-five, {0}! 🌟",
  "Would you mind sharing your rating": "Would you mind sharing your rating on the {0}? It really helps us out and only takes a moment!",
  "Maybe next time": "Maybe next time",
  "Submit": "Submit",
  "Rate us in the": "Rate us in the {0}",
  "%s Followers": "{0} Followers",
  "%s Following": "{0} Following",
  "Followers": "Followers",
  "Following": "Following",
  "Blocked Users": "Blocked Users",
  "Follow back": "Follow back",
  "Block": "Block",
  "Unblock": "Unblock",
  "Answer": "Answer",
  "XY would like to follow you": "{0} would like to follow you",
  "Respond to request": "Respond to request",
  "Delete follower request": "Delete follower request?",
  "Are you sure you want to unfollow": "Are you sure you want to unfollow {0}?",
  "Are you sure you want to block": "Are you sure you want to block {0}?",
  "Your follow request inbox is empty.": "Your follow request inbox is empty. Start connecting with others by sending them requests!",
  "You are not following another rider": "You're not following any riders yet. Dive into the community, discover fellow enthusiasts, and follow their journeys to fuel your own adventure!",
  "It's quiet on the road right now with no followers in your rearview": "It's quiet on the road right now with no followers in your rearview. Start sharing your motorcycle adventures and engage with the community to get your journey rolling!",
  "Remove follower": "Remove follower",
  "Are you sure you want to remove XY": "Are you sure you want to remove {0} as your follower?",
  "We couldn't find any riders with this name": "We couldn't find any user with this name. Perhaps they haven't joined RISER yet? Utilize the invitation function to bring your fellow enthusiasts into our community.",
  "XY sent you a follow request": "{0} sent you a follow request",
  "XY accepted your follow request": "{0} accepted your follow request",
  "You got X new follower request": "{0} and one other send you a follower request|{0} and {0} others send you a follower request",
  "XY started following you": "{0} started following you",
  "XY and others started following you": "{0} and one other started following you 🎉|{0} and {0} others started following you 🎉",
  "Auto-Accept Followers": "Auto-Accept Followers",
  "Accept new followers automatically": "Accept new followers automatically. Streamline your journey and expand your community effortlessly!",
  "empty_text_blocked_users": "You haven't blocked any users yet. Your journey is obstacle-free!\n\nContinue to ride and connect in the spirit of a great community.",
  "Answer follow request": "Answer follow request",
  "XY sent a request": "{0} sent a request",
  "Introducing our new Follower Logic": "Introducing our new Follower System",
  "Discover and connect effortlessly with our new follower system": "Discover and connect effortlessly with our new follower system. Follow your passion and inspire others with every ride.",
  "Connections preserved": "Connections preserved",
  "Your existing friendships are automatically carried over": "Your existing friendships are automatically carried over. No action needed — just continuous, seamless connections.",
  "Enable Auto-Accept": "Enable Auto-Accept",
  "Open your journeys to more eyes": "Open your journeys to more eyes! Opt for auto-accept and let your tours, trips, and rides inspire an ever-growing audience.",
  "Auto-accept requests": "Auto-accept requests",
  "Manual review requests": "Review requests",
  "Save setting": "Save setting",
  "Mark all read": "Mark all read",
  "Users on RISER can automatically follow you": "Users on RISER can automatically follow and interact with you.",
  "Only users that you confirm can follow your rides": "Only users that you confirm can follow your rides.",
  "Copy link": "Copy link",
  "Search results for query": "Search results for \"{0}\"",
  "Invite Followers": "Invite Followers",
  "Report": "Report",
  "Confirm": "Confirm",
  "Reject": "Reject",
  "block the user": "block the user",
  "Overwrite": "Overwrite",
  "Set Goal": "Set Goal",
  "Tell us about yourself": "Tell us about yourself",
  "Instagram": "Instagram",
  "Url": "Url",
  "Youtube": "Youtube",
  "Website": "Website",
  "Your Climb": "Your Climb",
  "Register": "Register",
  "Enter your details": "Enter your details",
  "Name": "Name",
  "Redeem this code using the app": "Redeem this code using the app",
  "Invalid Voucher": "Invalid Voucher",
  "Try again": "Try again",
  "Voucher": "Voucher",
  "user_state_blocked": "Blocked",
  "Show this trip": "Show this trip",
  "___Participants": "{0} Participant|{0}  Participants",
  "Show all": "Show all",
  "Joined": "Joined",
  "Suggested": "Suggested",
  "trending-photo": "Trending photo",
  "Bike": "Bike",
  "Share and reWind": "Share and reWind",
  "No comments for this trip": "No comments for this trip. Your chance to be the first...",
  "No bike assigned": "No bike assigned",
  "ride-this-trip": "Ride this trip",
  "special_offer": "Special Offer",
  "Share your profile": "Share your profile",
  "pack-ride": "PackRide",
  "rewind-2": "reWind 2.0",
  "supercurvy-roads": "Supercurvy Roads",
  "export-tours": "Export Tours",
  "upgrade-pro-monthly-title": "Pro monthly",
  "upgrade-pro-yearly-title": "Pro yearly",
  "upgrade-pro-monthly-description": "Pay monthly and cancel anytime",
  "upgrade-pro-yearly-description": "Pay yearly and get a free trial",
  "upgrade-pro-yearly-discount-text": "most popular",
  "sign-up-to-riser-pro": "Sign up to RISER PRO today!",
  "redeem-promo-code": "Redeem promo code",
  "explore-riser-pro": "Explore RISER Pro",
  "Welcome to RISER Pro": "🎉 Welcome to RISER Pro {0}!",
  "You have now access to all RISER features": "You now have access to all RISER features without any limitation and not only that, with your purchase you support our work and the development of future features.\n\nThank you!",
  "Latest added": "Latest added",
  "Select Order": "Select Order",
  "Actions": "Actions",
  "Open in route planner": "Open in route planner",
  "Satellite": "Satellite",
  "cardo_offer_valid_until": "This code is valid until {0}.",
  "new_model": "New model",
  "action_suggest_new": "Suggest new...",
  "finish-and-continue": "Click the button below to finish the process and continue where you left off.",
  "finish-upgrade": "Finish upgrade",
  "Avoid tolls": "Avoid tolls",
  "You can edit the default name of the GPX file": "You can edit the default name of the GPX file in the text field before exporting.",
  "Export": "Export",
  "redeem-voucher-heading": "Redeem your voucher",
  "redeem-voucher-download-step": "Begin by downloading our free app and creating your account.",
  "redeem-voucher-action": "Redeem voucher",
  "redeem-voucher-redeem-step": "Then, click the link below to redeem your voucher within the app.",
  "enjoy-riser-pro": "Enjoy RISER Pro!",
  "voucher-invalid-get-pro-button": "Get PRO anyways",
  "Become a RISER PRO": "Become\na RISER\nPRO",
  "And be part of the exclusive PRO rider community.": "And be part of the\nexclusive PRO\nrider community.",
  "audio_settings": "Audio Settings",
  "pitch": "Pitch",
  "voice": "Voice",
  "reset": "Reset",
  "test_voice": "Test voice",
  "voice_test_text": "Let's hit the road!",
  "Request": "Request",
  "no-trips-yet-text": "It looks like you don't have any trips yet.",
  "show-older-comments": "Show older comments",
  "profile-image": "Profile image",
  "cover-image": "Cover image",
  "invited-to-join-group": "You were invited to join {0}",
  "quick-invite": "Quick invite",
  "this-month": "This month",
  "this-year": "This year",
  "alltime": "Alltime",
  "group-leaderboard-empty-text": "It looks like the leaderboard is empty for this timeframe. That's your chance!",
  "x-members": "{0} member|{0} members",
  "group-join-requested": "{0} requested to join",
  "group-description-empty-placeholder": "Apparently, this group prefers to keep an air of mystery about them.",
  "total-distance": "Total Distance",
  "total-duration": "Total Duration",
  "total-climb": "Total Climb",
  "create-posting-group-input-placeholder": "What do you want to share with your group?",
  "posts": "Posts",
  "create-post": "Create post",
  "group-posting-empty-text": "It looks like there are no posts in this group, yet. Be the first to post something!",
  "join-my-group-on-riser": "Join my group on RISER",
  "share-and-invite": "Share & Invite",
  "scan-qr-to-invite": "Scan QR to invite",
  "check-riser-installed": "Make sure that RISER app is installed.",
  "share-link": "Share link",
  "you-have-no-groups-empty-text": "It looks like you’re not in a group. Discover new groups or create your own!",
  "create-your-own-group": "Create your own group",
  "join-group-to-see-more": "Join the group to see more",
  "stats": "Stats",
  "no-results-found": "No groups found",
  "something-went-wrong": "Something went wrong",
  "Week": "Week",
  "Month": "Month",
  "Year": "Year",
  "All time": "All time",
  "file-to-large-message": "File to large. Max file size is {0} MB.",
  "Altitude": "Altitude",
  "highest": "highest",
  "lowest": "lowest",
  "above sea level": "{0} above sea level",
  "Week vs week before": "Weekly distance",
  "Bikes": "Bikes",
  "Countries": "Countries",
  "Longest Ride": "Longest Trip",
  "Riders in": "Riders in {0}",
  "Month vs month before": "Monthly distance",
  "Trip distances": "Trip distances",
  "Trips per week day": "Trips per day",
  "Calendar view": "Trips per day",
  "Year vs year before": "Yearly distance",
  "Distance per year": "Distance per year",
  "Pack Rides": "PackRides",
  "New": "New",
  "received": "received",
  "given": "given",
  "Show all statistics": "Show all statistics",
  "Tools": "Tools",
  "Previous": "Previous",
  "auto-accept-follow-requests": "Auto accept follow requests",
  "manage-subscription-and-billing": "Manage subscription & billing",
  "new": "new",
  "Trips per month": "Trips per month",
  "Average:": "Average:",
  "Start week on": "Start week on",
  "Fall": "Fall",
  "Yearly Statistics": "Yearly Statistics",
  "Monthly Statistics": "Monthly Statistics",
  "Strongest Month": "Strongest Month",
  "Strongest Day": "Strongest Day",
  "Edit profile photo": "Edit profile photo",
  "Edit cover photo": "Edit cover photo",
  "People you follow": "People you follow",
  "Riders in your country": "Riders in your country",
  "Set homebase for leaderboard": "Set homebase for leaderboard",
  "Journal": "Journal",
  "Postings": "Postings",
  "current-week": "Current week",
  "week-before": "Week before",
  "average": "Average",
  "All": "All",
  "promo-code-only-for-yearly-warning": "The promo code was not applied because it is only valid for the yearly subscription of RISER PRO.",
  "end_navigation": "End Navigation",
  "Export as GPX Track": "Export as GPX Track",
  "Helmets": "Helmets",
  "Intercoms": "Intercoms",
  "Intercom": "Intercom",
  "Helmet": "Helmet",
  "Add to garage": "Add to garage",
  "Service": "Service",
  "Next service in": "Next service in…",
  "Tap to add photo": "Tap to add photo",
  "Helmet type": "Helmet type",
  "Helmet details": "Helmet details",
  "Year of purchase": "Year of purchase",
  "Add helmet": "Add helmet",
  "Add intercom": "Add intercom",
  "Intercom details": "Intercom details",
  "Show service history": "Show service history",
  "Service history": "Service history",
  "Show garage": "Show garage",
  "Service details": "Service details",
  "Full-face": "Full-face",
  "Flip-up": "Flip-up",
  "Open-face": "Open-face",
  "Half-helmet": "Half-helmet",
  "Off-road-helmet": "Off-road",
  "Adventure": "Adventure",
  "Are you sure you want to delete your helmet": "Are you sure you want to delete your helmet?",
  "Are you sure you want to delete your intercom": "Are you sure you want to delete your intercom?",
  "sold": "sold",
  "Mark as sold": "Mark as sold",
  "Revoke sold status": "Revoke sold status",
  "No intercom added to your garage": "No intercom added to your garage...",
  "No helmet added to your garage": "No helmet added to your garage...",
  "Now": "Now",
  "Add some notes like \"Oil change\"": "Add some notes like \"Oil change\"...",
  "Add next service": "Add next service",
  "Add service interval": "Add service interval",
  "Remark": "Remark",
  "No helmet added": "No helmet added",
  "No bike added": "No bike added",
  "No intercom added": "No intercom added",
  "Bike details": "Bike details",
  "Enter manufacturer information": "Enter manufacturer information",
  "Enter code": "Enter code",
  "Enter code here": "Enter code here",
  "Unlock Your Benefits": "Unlock Your Benefits",
  "Enter your voucher code below to access exclusive features or discounts!": "Enter your voucher code below to access exclusive features or discounts!",
  "Redeem": "Redeem",
  "new intercom": "new intercom",
  "new helmet": "new helmet",
  "Log service": "Log service",
  "%@ liked your helmet": "{0} liked your helmet",
  "commented on your helmet": "{0} commented on your helmet",
  "commented on your intercom": "{0} commented on your intercom",
  "liked your intercom": "{0} liked your intercom",
  "edit-helmet": "Edit helmet",
  "edit-intercom": "Edit intercom",
  "service-due": "Service due",
  "no-services-recorded": "No services logged yet",
  "show-less": "Show less",
  "No bike has been added yet.": "No bike has been added yet.",
  "No helmet added yet.": "No helmet added yet.",
  "No intercom added yet.": "No intercom added yet.",
  "no-likes-message": "No likes yet",
  "newsletter": "newsletter",
  "For riders who want more": "For riders who want more",
  "Get the latest motorcycle news, epic ride inspirations": "Get the latest motorcycle news, epic ride inspirations, and insider app updates—plus special offers and deals straight to your inbox!",
  "Sign me up for the news, updates, tips and special offers.": "Sign me up for the news, updates, tips and special offers.",
  "Skip this step": "Skip this step",
  "Enter your email": "Enter your email"
}