<script setup lang="ts">
import useMainNavigation from '~/data/mainNavigation'

const authStore = useAuthStore()
const garageStore = useGarageStore()

const userId = computed(() => authStore.currentUser?.id)

const mainNavigation = userId.value
  ? useMainNavigation(userId.value.toString(), garageStore)
  : ref([])
</script>

<template>
  <NavigationTopBarItemBase mouse-over data-testid="top-bar-user-item">
    <template #button>
      <div class="tw-hidden tw-items-center tw-gap-2 md:tw-inline-flex">
        <UserpicItem class="tw-h-9 tw-w-9" />

        <FontAwesomeIcon icon="chevron-down" class="tw-text-sm" />
      </div>

      <FontAwesomeIcon icon="bars" class="md:tw-hidden" />
    </template>

    <template #default="{ triggerClose }">
      <div class="tw-flex tw-flex-col md:tw-w-max">
        <div class="md:tw-hidden">
          <NuxtLink
            v-for="navItem in mainNavigation"
            :key="navItem.translationKey"
            :to="navItem.route"
            class="menu-item"
            @click="triggerClose"
          >
            <FontAwesomeIcon :icon="navItem.fontAwesomeIcon" class="icon" />

            <div class="tw-ml-3 tw-text-sm">{{ $t(navItem.translationKey) }}</div>
          </NuxtLink>
        </div>

        <div class="tw-mt-6 md:tw-mt-0">
          <NuxtLink
            :to="`/user/${authStore.currentUser?.id}`"
            class="menu-item tw-hidden md:tw-flex"
            @click="triggerClose"
          >
            <FontAwesomeIcon icon="user" class="icon" />

            <div class="tw-ml-3 tw-text-sm">{{ $t('My Profile') }}</div>
          </NuxtLink>

          <NuxtLink to="/settings" class="menu-item" @click="triggerClose">
            <FontAwesomeIcon icon="gear" class="icon" />

            <div class="tw-ml-3 tw-text-sm">{{ $t('Settings') }}</div>
          </NuxtLink>

          <a
            href="https://riserapp.com/support"
            target="_blank"
            class="menu-item"
            @click="triggerClose"
          >
            <FontAwesomeIcon icon="envelope" class="icon" />

            <div class="tw-ml-3 tw-text-sm">{{ $t('Support') }}</div>
          </a>

          <NuxtLink to="/logout" class="menu-item" @click="triggerClose">
            <FontAwesomeIcon icon="sign-out-alt" class="icon" />

            <div class="tw-ml-3 tw-text-sm">{{ $t('Log out') }}</div>
          </NuxtLink>

          <NuxtLink
            v-if="!authStore.isUserPro"
            to="/upgrade"
            class="menu-item tw-mt-2"
            @click="triggerClose"
          >
            <IconsProBadge class="tw-w-3" />

            {{ $t('Get-Pro') }}
          </NuxtLink>
        </div>
      </div>
    </template>
  </NavigationTopBarItemBase>
</template>

<style scoped>
.menu-item {
  @apply tw-flex tw-items-center tw-border-b tw-border-black/10 tw-bg-white tw-px-8 tw-py-2 tw-text-sm tw-transition-all tw-duration-200 last:tw-border-b-0 hover:tw-bg-gray-100;
}

.menu-item .icon {
  @apply tw-h-3 tw-w-3 tw-text-gray-800;
}
</style>
