<script setup lang="ts">
import { type UserProfile } from '~/types/user'
import { type LikeObjectType } from '~/types/like'

const { $LikeService } = useNuxtApp()

const authStore = useAuthStore()

const props = defineProps<{
  objectId: number
  objectType: LikeObjectType
}>()

const emit = defineEmits(['close'])

const state = reactive<{
  paginationAfterMarker: string | null
  paginationHasMore: boolean
  likes: UserProfile[]
  loading: boolean
}>({
  paginationAfterMarker: null,
  paginationHasMore: true,
  likes: [],
  loading: false
})

const reloadLikes = async () => {
  state.paginationAfterMarker = null
  state.paginationHasMore = true
  state.likes = []

  await loadMoreLikes()
}

const loadMoreLikes = async () => {
  if (!state.paginationHasMore) return

  state.loading = true

  try {
    const response = await $LikeService.getLikes(
      props.objectType,
      props.objectId,
      20,
      state.paginationAfterMarker ?? undefined
    )

    if (!response) return

    state.paginationAfterMarker = response.after
    state.paginationHasMore = response.has_more

    state.likes.push(...response.data)
  } catch (error) {
    console.error(error)
  } finally {
    state.loading = false
  }
}

const close = () => {
  emit('close')
}

onMounted(async () => {
  await reloadLikes()
})
</script>

<template>
  <Modal @close="close">
    <template #header>
      {{ $t('Likes') }}
    </template>

    <template #body>
      <div v-if="state.loading" class="tw-my-8 tw-flex tw-justify-center tw-text-center">
        <LoadingIndicator class="tw-text-2xl tw-text-black/50" />
      </div>

      <div v-else-if="state.likes.length > 0" class="tw-flex tw-flex-col tw-gap-4 tw-py-2">
        <RelationshipUserCard v-for="user in state.likes" :key="user.id" :user="user">
          <template #actions>
            <template v-if="user.id !== authStore.currentUser?.id">
              <RelationshipFollowButtonCore :model-value="user" v-slot="{ buttonLabel, disabled }">
                <InputAppButton
                  :disabled="disabled"
                  class="tw-rounded-full tw-bg-black/10 tw-px-4 tw-py-0.5 tw-text-sm"
                >
                  {{ buttonLabel }}
                </InputAppButton>
              </RelationshipFollowButtonCore>
            </template>
          </template>
        </RelationshipUserCard>
      </div>

      <div v-else class="tw-my-8 tw-flex tw-justify-center tw-text-center">
        <p class="tw-text-sm tw-text-black/50">
          {{ $t('no-likes-message') }}
        </p>
      </div>

      <div
        v-if="state.paginationHasMore && state.paginationAfterMarker"
        class="tw-mt-8 tw-flex tw-flex-col"
      >
        <LoadMoreButton class="tw-mx-auto" @became-visible="loadMoreLikes" @click="loadMoreLikes" />
      </div>
    </template>
  </Modal>
</template>
