<script setup lang="ts">
import useWindowSize from '~/composables/useWindowSize'

import { reactive, computed, onMounted, ref, watch } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export interface ContentSliderItem {
  identifier: number | string
  data?: any
}

const windowSize = useWindowSize()

const emit = defineEmits(['update:modelValue'])

const props = defineProps<{
  modelValue?: ContentSliderItem
  items: ContentSliderItem[]
  hideNavigation?: boolean
  slidesListClasses?: string
  indicatorWrapperClasses?: string
}>()

const state = reactive<{
  selectedItemIndex: number
  itemWidth: number | null
  evaluationTimeoutId: ReturnType<typeof setTimeout> | null
}>({
  selectedItemIndex: 0,
  itemWidth: null,
  evaluationTimeoutId: null
})

const slidesList = ref<HTMLUListElement | null>(null)

const selectedItem = computed(() => {
  return props.items[state.selectedItemIndex]
})

const getIndexByIdentifier = (identifier: number | string) => {
  return props.items.findIndex((item) => item.identifier === identifier)
}

const evaluateSelection = (immediate?: boolean) => {
  if (state.evaluationTimeoutId) clearTimeout(state.evaluationTimeoutId)

  state.evaluationTimeoutId = setTimeout(
    () => {
      if (!slidesList.value || !state.itemWidth) return

      state.selectedItemIndex = Math.round(slidesList.value.scrollLeft / state.itemWidth)

      emit('update:modelValue', selectedItem.value)
    },
    immediate ? 0 : 100
  )
}

const selectIndex = (index: number, skipAnimation?: boolean) => {
  state.selectedItemIndex = index

  scrollToIndex(index, skipAnimation)
}

const goToNext = () => {
  if (state.selectedItemIndex >= props.items.length - 1) return

  selectIndex(state.selectedItemIndex + 1)
}

const goToPrevious = () => {
  if (state.selectedItemIndex <= 0) return

  selectIndex(state.selectedItemIndex - 1)
}

const scrollToIndex = (index: number, skipAnimation?: boolean) => {
  if (!slidesList.value || !state.itemWidth) return

  const newPosition = index * state.itemWidth

  slidesList.value.scrollTo({
    left: newPosition,
    behavior: skipAnimation ? 'instant' : 'smooth'
  })
}

const calculateItemWidth = () => {
  if (!slidesList.value) return

  const firstSlideElement = slidesList.value.getElementsByTagName('li')[0]

  if (!firstSlideElement) return

  state.itemWidth = firstSlideElement.clientWidth
}

watch(windowSize.width, calculateItemWidth)

watch(
  () => props.items,
  (newValue) => {
    if (state.itemWidth != null || newValue.length === 0) return

    nextTick(() => {
      calculateItemWidth()
    })
  }
)

onMounted(() => {
  nextTick(() => {
    calculateItemWidth()

    const selectedIndex = props.modelValue?.identifier
      ? getIndexByIdentifier(props.modelValue.identifier)
      : 0

    selectIndex(selectedIndex, true)
  })
})
</script>

<template>
  <div class="tw-relative tw-flex tw-min-w-0 tw-flex-col">
    <ul
      ref="slidesList"
      class="slides-list"
      :class="props.slidesListClasses"
      @scroll="evaluateSelection()"
    >
      <li v-for="(item, index) in props.items" :key="item.identifier" @click="scrollToIndex(index)">
        <slot name="item" :data="item.data" :identifier="item.identifier" :index="index"></slot>
      </li>
    </ul>

    <slot name="indicator" :currentIndex="state.selectedItemIndex" :totalItems="props.items.length">
      <div
        v-if="props.items.length > 1"
        class="tw-flex tw-flex-wrap tw-justify-center"
        :class="props.indicatorWrapperClasses"
      >
        <!-- Extra padding to increase touch tap area -->
        <div
          v-for="(item, index) in props.items"
          :key="item.identifier"
          class="tw-cursor-pointer tw-px-1 tw-py-3"
          @click="selectIndex(index)"
        >
          <slot name="indicator-item" :isActive="state.selectedItemIndex === index">
            <div
              class="tw-h-1.5 tw-w-1.5 tw-rounded-full lg:tw-h-2 lg:tw-w-2"
              :class="state.selectedItemIndex === index ? 'tw-bg-black/80' : 'tw-bg-black/10'"
            ></div>
          </slot>
        </div>
      </div>
    </slot>

    <div
      v-if="!props.hideNavigation"
      @click="goToPrevious"
      class="tw-absolute tw-left-1 tw-top-1/2 -tw-translate-y-1/2 tw-pl-0.5"
      :class="state.selectedItemIndex <= 0 ? 'tw-opacity-0' : ''"
    >
      <slot name="arrow" direction="left">
        <div
          class="tw-text-gray-dark/20 tw-cursor-pointer tw-rounded tw-bg-white/90 tw-text-xl tw-transition-all tw-duration-200 lg:tw-text-2xl"
        >
          <FontAwesomeIcon icon="chevron-left" />
        </div>
      </slot>
    </div>

    <div
      v-if="!props.hideNavigation"
      @click="goToNext"
      class="tw-absolute tw-right-1 tw-top-1/2 -tw-translate-y-1/2 tw-pr-0.5"
      :class="state.selectedItemIndex >= props.items.length - 1 ? 'tw-opacity-0' : ''"
    >
      <slot name="arrow" direction="right">
        <div
          class="tw-text-gray-dark/20 tw-cursor-pointer tw-rounded tw-bg-white/90 tw-text-xl tw-transition-all tw-duration-200 lg:tw-text-2xl"
        >
          <FontAwesomeIcon icon="fa-chevron-right" />
        </div>
      </slot>
    </div>
  </div>
</template>

<style scoped>
.slides-list {
  /* 
    Also be aware that gap-2 here might lead to issues with position calculation in larger lists.
    The gap would need to be considered to get a 100% accurate currently selected index  
  */
  @apply tw-box-content tw-flex tw-min-h-0 tw-w-full tw-flex-grow tw-snap-x tw-snap-mandatory tw-gap-2 tw-overflow-x-auto tw-whitespace-nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.slides-list::-webkit-scrollbar {
  display: none;
}
.slides-list li {
  @apply tw-inline-flex tw-w-full tw-flex-shrink-0 tw-snap-center tw-whitespace-normal;
}
</style>
