import * as amplitude from '@amplitude/analytics-browser'
import Cookie from 'cookie-universal'
import { type UserProfile } from '~/types/user'

const cookies = Cookie()

let didInitAmplitude = false
let amplitudeAPIKey: string

const identifyEvent = new amplitude.Identify()

interface ITrackingService {
  init: () => void
  setApiKey: (key: string) => void
  setUser: (user: UserProfile) => void
  unsetUser: () => void
  trackEvent: (args: any) => void
  optOut: (args?: any) => void
}

export const AmplitudeTrackingService: ITrackingService = {
  setUser(user: UserProfile) {
    if (!amplitude || !didInitAmplitude) return

    amplitude.setUserId(user.t_id.toString())

    identifyEvent.set('user_type', user.account_type)
    identifyEvent.set('number_follower', user.followers_count)
    identifyEvent.set('number_following', user.follows_count)
    identifyEvent.set('number_trips', user.trips_count)
    identifyEvent.set('auto_follow_enabled', user.auto_accept_follow_requests ?? false)

    amplitude.identify(identifyEvent)
  },

  unsetUser() {
    if (!amplitude || !didInitAmplitude) return

    identifyEvent.clearAll()

    amplitude.setUserId(undefined)
  },

  setApiKey(key: string) {
    amplitudeAPIKey = key
  },

  optOut() {
    amplitude.setOptOut(true)
    // Remove amplitude related cookies
    cookies.remove(`AMP_${amplitudeAPIKey.substring(0, 10)}`)
    cookies.remove(`AMP_MKTG${amplitudeAPIKey.substring(0, 10)}`)
    cookies.remove('AMP_TEST')
    cookies.remove('AMP_TDLTEST')
  },

  init() {
    if (!amplitudeAPIKey) {
      console.warn('Amplitude api key is not set')
      return
    }
    if (!import.meta.env.SSR) {
      amplitude.init(amplitudeAPIKey, { defaultTracking: false })

      didInitAmplitude = true
    }
  },

  trackEvent(args: [eventName: string, eventProperties: Record<string, string>]) {
    if (!amplitudeAPIKey) {
      console.warn('Amplitude api key ist not set')
      return
    }

    if (amplitude && didInitAmplitude) {
      if (!amplitude.getUserId()) {
        console.warn('Amplitude event tracked without user id')
      }

      amplitude.track(...args)
    }
  }
}
