import { type RISERAPI } from '~/data/api'
import {
  BikeUpsertData,
  HelmetUpsertData,
  IntercomUpsertData,
  BikeServiceUpsertData
} from '~/types/bikes'

type InteractionStore = ReturnType<typeof useInteractionStore>
type GarageStore = ReturnType<typeof useGarageStore>

export class GarageService {
  API?: RISERAPI
  interactionStore: InteractionStore
  garageStore: GarageStore

  constructor(API: RISERAPI, interactionStore: InteractionStore, garageStore: GarageStore) {
    this.API = API
    this.interactionStore = interactionStore
    this.garageStore = garageStore
  }

  async getBikeBrands() {
    const response = await this.API?.getBikeBrands()
    return response?.data
  }

  async createHelmet(userId: number, data: HelmetUpsertData) {
    const response = await this.API?.createHelmet(userId, data)

    return response?.data
  }

  async updateHelmet(helmetId: number, data: HelmetUpsertData) {
    const response = await this.API?.updateHelmet(helmetId, data)
    return response?.data
  }

  async createIntercom(userId: number, data: IntercomUpsertData) {
    const response = await this.API?.createIntercom(userId, data)
    return response?.data
  }

  async updateIntercom(intercomId: number, data: IntercomUpsertData) {
    const response = await this.API?.updateIntercom(intercomId, data)
    return response?.data
  }

  async getBike(bikeID: string | number) {
    const response = await this.API?.getBike(bikeID)

    if (response?.data) {
      this.interactionStore.initItem('bikes', response.data.id, {
        likedByMe: response.data.liked_by_me,
        likeCount: response.data.likes_count,
        commentCount: response.data.comments_count
      })
    }

    return response?.data
  }

  async createBike(userId: number, data: BikeUpsertData) {
    const response = await this.API?.createBike(userId, data)

    if (response?.data) {
      this.garageStore.addBike(response.data)
    }

    return response?.data
  }

  async updateBike(bikeId: number, data: BikeUpsertData) {
    const response = await this.API?.updateBike(bikeId, data)

    if (response?.data) {
      this.garageStore.updateBike(response.data)
    }

    return response?.data
  }

  async getHelmetBrands() {
    const response = await this.API?.getHelmetBrands()
    return response?.data
  }

  async getIntercomBrands() {
    const response = await this.API?.getIntercomBrands()
    return response?.data
  }

  async updateHelmetPhoto(helmetId: number, photo: File) {
    const response = await this.API?.updateHelmetPhoto(helmetId, photo)
    return response?.data
  }

  async updateIntercomPhoto(intercomId: number, photo: File) {
    const response = await this.API?.updateIntercomPhoto(intercomId, photo)
    return response?.data
  }

  async updateBikePhoto(bikeId: number, photo: File) {
    const response = await this.API?.updateBikePhoto(bikeId, photo)
    return response?.data
  }

  async addAdditionalBikePhoto(bikeId: number, photo: File) {
    const response = await this.API?.addAdditionalBikePhoto(bikeId, photo)
    return response?.data
  }

  async deleteAdditionalBikePhoto(photoId: number) {
    const response = await this.API?.deletePhoto(photoId)
    return response?.data
  }

  async deleteBike(bikeId: number) {
    const response = await this.API?.deleteBike(bikeId)

    this.garageStore.removeBike(bikeId)

    return response?.data
  }

  async deleteHelmet(helmetId: number) {
    const response = await this.API?.deleteHelmet(helmetId)
    return response?.data
  }

  async deleteIntercom(intercomId: number) {
    const response = await this.API?.deleteIntercom(intercomId)
    return response?.data
  }

  async getHelmet(helmetId: string) {
    const response = await this.API?.getHelmet(helmetId)

    if (response?.data) {
      this.interactionStore.initItem('helmets', response.data.id, {
        likedByMe: response.data.liked_by_me,
        likeCount: response.data.likes_count,
        commentCount: response.data.comments_count
      })
    }

    return response?.data
  }

  async getIntercom(intercomId: string) {
    const response = await this.API?.getIntercom(intercomId)

    if (response?.data) {
      this.interactionStore.initItem('intercoms', response.data.id, {
        likedByMe: response.data.liked_by_me,
        likeCount: response.data.likes_count,
        commentCount: response.data.comments_count
      })
    }

    return response?.data
  }

  async getBikeServices(bikeId: number) {
    const response = await this.API?.getBikeServices(bikeId)
    return response?.data
  }

  async getBikeService(bikeId: number, serviceId: number) {
    const response = await this.API?.getBikeService(bikeId, serviceId)
    return response?.data
  }

  async createBikeService(bikeId: number, data: BikeServiceUpsertData) {
    const response = await this.API?.createBikeService(bikeId, data)

    if (response?.data) {
      this.garageStore.updateBike(response.data.bike)
    }

    return response?.data
  }

  async updateBikeService(bikeId: number, serviceId: number, data: BikeServiceUpsertData) {
    const response = await this.API?.updateBikeService(bikeId, serviceId, data)
    return response?.data
  }

  async deleteBikeService(bikeId: number, serviceId: number) {
    const response = await this.API?.deleteBikeService(bikeId, serviceId)
    return response?.data
  }
}
