export type AvailableInteractionModelTypes =
  | 'bikes'
  | 'helmets'
  | 'intercoms'
  | 'postings'
  | 'groupPostings'
  | 'sections'
  | 'trips'
  | 'tripPhotos'

interface InteractionInfo {
  likedByMe: boolean
  likeCount: number
  commentCount: number
}

interface State {
  interactions: Map<AvailableInteractionModelTypes, Map<number, InteractionInfo>>
}

export const useInteractionStore = defineStore('interaction', {
  state: (): State => ({
    interactions: new Map([
      ['bikes', new Map()],
      ['helmets', new Map()],
      ['intercoms', new Map()],
      ['postings', new Map()],
      ['groupPostings', new Map()],
      ['sections', new Map()],
      ['trips', new Map()],
      ['tripPhotos', new Map()]
    ])
  }),

  actions: {
    initItem(
      modelType: AvailableInteractionModelTypes,
      id: number,
      interactionInfo: InteractionInfo
    ) {
      const collection = this.interactions.get(modelType)

      if (!collection) return

      collection.set(id, interactionInfo)

      const item = collection.get(id)

      return structuredClone(toRaw(item))
    },

    getItem(modelType: AvailableInteractionModelTypes, id: number) {
      const collection = this._getCollection(modelType)

      return structuredClone(toRaw(collection.get(id)))
    },

    hasItem(modelType: AvailableInteractionModelTypes, id: number) {
      const collection = this._getCollection(modelType)

      return collection.has(id)
    },

    _getCollection(modelType: AvailableInteractionModelTypes) {
      const collection = this.interactions.get(modelType)

      if (!collection) {
        throw new Error(`Collection for model type ${modelType} not found`)
      }

      return collection
    },

    likeItem(modelType: AvailableInteractionModelTypes, id: number) {
      const collection = this._getCollection(modelType)

      const item = this.getItem(modelType, id)

      if (!item) {
        return
      }

      collection.set(id, {
        ...item,
        likedByMe: true,
        likeCount: item.likeCount + 1
      })
    },

    unlikeItem(modelType: AvailableInteractionModelTypes, id: number) {
      const collection = this._getCollection(modelType)

      const item = this.getItem(modelType, id)

      if (!item) {
        return
      }

      collection.set(id, {
        ...item,
        likedByMe: false,
        likeCount: item.likeCount - 1
      })
    },

    commentItem(modelType: AvailableInteractionModelTypes, id: number) {
      const collection = this._getCollection(modelType)

      const item = this.getItem(modelType, id)

      if (!item) {
        return
      }

      collection.set(id, {
        ...item,
        commentCount: item.commentCount + 1
      })
    },

    uncommentItem(modelType: AvailableInteractionModelTypes, id: number) {
      const collection = this._getCollection(modelType)

      const item = this.getItem(modelType, id)

      if (!item) {
        return
      }

      collection.set(id, {
        ...item,
        commentCount: item.commentCount - 1
      })
    }
  }
})
