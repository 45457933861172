import { BikeData } from '~/types/bikes'

interface State {
  currentUserBikes: BikeData[]
}

export const useGarageStore = defineStore('garage', {
  state: (): State => ({
    currentUserBikes: []
  }),

  getters: {
    bikesThatNeedService: (state) =>
      state.currentUserBikes.filter(
        (bike) => bike.next_service != null && !bike.sold && bike.next_service <= 0
      ).length
  },

  actions: {
    updateBike(bike: BikeData) {
      const index = this.currentUserBikes.findIndex((b) => b.id === bike.id)

      if (index !== -1) {
        this.currentUserBikes[index] = bike
      }
    },

    removeBike(bikeId: number) {
      const index = this.currentUserBikes.findIndex((b) => b.id === bikeId)

      if (index !== -1) {
        this.currentUserBikes.splice(index, 1)
      }
    },

    addBike(bike: BikeData) {
      this.currentUserBikes.push(bike)
    }
  }
})
